//Libraries
import React, {useEffect, useState} from 'react';
import SignatureCanvas from 'react-signature-canvas';

//Helpers
import {checkScreenSize} from '../../helpers/screenSize';

//Styles
import './signature.css'

const SignaturePad = ({ sigCanvasRef, questionareComponent, topComponent }) => {
    // const [isMobile, setIsMobile] = useState(false);

    // useEffect(() => {
    //     const handleResize = () => {
    //         setIsMobile(checkScreenSize(768));
    //     };
    //     handleResize();
    //     window.addEventListener('resize', handleResize);
    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, []);

    const clearSignature = () => {
        sigCanvasRef.current.clear();
    };

    return (
        <div className="space-y-3">
            {topComponent}
            <div className="space-y-1">
                <div className="flex items-center justify-between">
                    <label className="text-sm font-semibold text-gray-600">Sign Here: </label>
                    <div
                        className='cursor-pointer text-sm text-blue-400 font-medium hover:underline'
                        onClick={clearSignature}
                    >
                        Clear
                    </div>
                </div>
                <SignatureCanvas
                    ref={sigCanvasRef}
                    penColor="black"
                    canvasProps={{className: 'signatureCanvas'}}
                />
            </div>
            {questionareComponent}
            
        </div>
    );
};

export default SignaturePad;
