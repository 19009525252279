import { PDFDownloadLink } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import InvoiceTemplate from "./InvoiceTemplate";
import PdfSvg from '../../../../assets/Pdf.svg';
import formatDateTime, { findHours, formatDateOnly } from "../../../../helpers/utils";


const Invoices = (props) => {

    return (
        <>
            <div className="border rounded-md overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                        <tr className="bg-gray-50">
                            <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                                #
                            </td>
                            <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                Appointment Title
                            </td>
                            <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                Date
                            </td>
                            <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                Class quantity
                            </td>
                            <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                Hours
                            </td>
                            <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                Hr Rate
                            </td>
                            <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                Total
                            </td>
                            <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                Pdf Download
                            </td>
                        </tr>
                    </thead>
                    <tbody id="table-body"
                        className="divide-y divide-gray-200">
                        {
                            props.transactionInstructor && props.transactionInstructor.length === 0 ?
                                <tr>
                                    <td colSpan="8" className="px-6 py-3 text-center text-red-500">No history found.</td>
                                </tr>
                                :
                                props.transactionInstructor && props.transactionInstructor.map((item, key) => (
                                    <tr key={key}>
                                        <td className="px-6 py-4 align-middle whitespace-nowrap text-gray-500 cursor-pointer hover:underline">
                                            {key + 1}
                                        </td>
                                        <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                            {item.name}
                                        </td>
                                        <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                            {formatDateOnly(item.date)}
                                        </td>
                                        <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                            {item.class_qty}
                                        </td>
                                        <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                            {findHours(item.start_time, item.end_time)} Hrs
                                        </td>
                                        <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                            {parseFloat(item?.instructor_data[0]?.hr_rate).toFixed(2)}
                                        </td>
                                        <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                            Kr{parseFloat(item?.total_earned).toFixed(2)}
                                        </td>
                                        <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                            <div className="flex items-center justify-center gap-8">
                                                <PDFDownloadLink
                                                    document={<InvoiceTemplate transactionInstructor={item} />}
                                                    fileName={`${'Invoice'}.pdf`}
                                                >
                                                    {({ blob, url, loading, error }) => (loading ? 'Loading document...' :
                                                        <img className="w-8 h-8" src={PdfSvg} alt="pdf-svg" />)}
                                                </PDFDownloadLink>

                                            </div>
                                        </td>
                                    </tr>
                                )
                                )}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default Invoices