

const formatDateTime = (stringDate) => {
    const dateTime = new Date(stringDate);
    const year = dateTime.getFullYear();
    const month = String(dateTime.getMonth() + 1).padStart(2, '0'); // Month is 0-based
    const day = String(dateTime.getDate()).padStart(2, '0');
    const hours = String(dateTime.getHours()).padStart(2, '0');
    const minutes = String(dateTime.getMinutes()).padStart(2, '0');
    const seconds = String(dateTime.getSeconds()).padStart(2, '0');
    return `${year}/${month}/${day} - ${hours}:${minutes}:${seconds}`;
}

export const calculateAgeInTime = (birthDate) => {
    const today = new Date();
    const birthDateObj = new Date(birthDate);
    const diffTime = Math.abs(today - birthDateObj);
    const hours = Math.floor(diffTime / (1000 * 60 * 60));
    const minutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diffTime % (1000 * 60)) / 1000);
    return {
        hours,
        minutes,
        seconds
    };
}

export const calaulateDayAndDate = (inputDate) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dateObj = new Date(inputDate);
    const day = days[dateObj.getDay()];
    const formattedDate = dateObj.getDate().toString();
    return { day: day, formattedDate: formattedDate };
}

export const TrimId = (Id) => {
    return Id.substring((Id.length - 10), Id.length);

}

export const formatDateOnly = (stringDate) => {
    const dateTime = new Date(stringDate);
    const year = dateTime.getFullYear();
    const month = String(dateTime.getMonth() + 1).padStart(2, '0'); // Month is 0-based
    const day = String(dateTime.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}
export const findHours = (startdate, end_time) => {
    let [h2, m2] = end_time.split(":");
    let [h1, m1] = startdate.split(":");
    let tempEnd = new Date().setHours(parseInt(h2), parseInt(m2));
    let tempStart = new Date().setHours(parseInt(h1), parseInt(m1));
    tempEnd = new Date(tempEnd);
    tempStart = new Date(tempStart);
    let difference = tempEnd.getTime() - tempStart.getTime();
    let hours = ((difference / 60000) / 60);
    return parseFloat(hours).toFixed(2);
}
export default formatDateTime;
