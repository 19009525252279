import React from 'react';
import './Modal.css';
import { CrossSvg } from '../../constants';
import moment from 'moment';

const Modal = ({
    isOpen,
    closeModal,
    title,
    showComponent = false,
    component,
    message,
    onConfirmClick,
    confirmButtonText = 'Confirm',
    selectedDate,
    isEditMode
}) => {
    return (
        <>
            {isOpen ? (
                <div
                    className="fixed top-0 left-0 flex flex-col items-center justify-center min-h-full w-full bg-gray-800/60 backdrop-blur-sm z-50 md:px-0 px-4">
                    <div className="md:min-w-[600px] min-w-full max-w-2xl relative rounded-lg bg-white">
                        <div className="flex items-center justify-between px-5 py-3 bg-gray-100 rounded-t-lg">
                            <h2 className='text-gray-600 text-xl'>
                                {title}
                            </h2>
                            <svg onClick={closeModal} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-6 h-6 cursor-pointer stroke-gray-600 hover:stroke-gray-500 duration-300">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                        {
                            showComponent
                            &&
                            <div className="p-5 border-y overflow-y-auto max-h-[70vh]">
                                {component()}
                            </div>
                        }
                        <div className="flex items-center justify-end gap-2 px-5 py-3 bg-gray-100 rounded-b-lg">
                            <button onClick={closeModal} type="button"
                                className="h-10 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-red-500 text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-all text-sm">
                                Cancel
                            </button>
                            <button disabled={isEditMode && moment(selectedDate).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')} onClick={onConfirmClick} type="button"
                                className={"h-10 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-green-500 text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all text-sm" + (isEditMode && moment(selectedDate).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') ? " bg-green-700" : "")}>
                                {confirmButtonText}
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default Modal;
