//Libraries
import React, {useEffect, useState} from "react";
import {ToastContainer} from 'react-toastify';
import {useLocation, useNavigate} from 'react-router-dom';
import jwtDecode from "jwt-decode";

//Services
import {updateUserPassword} from "../../../services/userService";
import drivePro from "../../../assets/drivePro1.jpeg";

export default function UpdatePassword() {
    const navigate = useNavigate();
    const location = useLocation();

    const [tokenPayload, setTokenPayload] = useState('');
    const [passowrd, setPassword] = useState('');
    const [confirmPassowrd, setConfirmPassword] = useState('');

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');
        const {token_payload} = jwtDecode(token)
        setTokenPayload(token_payload)
    }, [location.search]);

    const updatePassword = async () => {
        const body = {
            email: tokenPayload.email,
            user_type: tokenPayload.user_type,
            password: passowrd
        }
        const success = await updateUserPassword(body)

        if (success) navigateToSignIn();
    }

    const navigateToSignIn = () => {
        navigate('/login')
    }

    return (
        <>
            <div className="grid lg:grid-cols-2 min-h-screen bg-white">

                {/* Left Container */}
                <div className="lg:flex hidden flex-col items-center justify-center">
                    <div className="">
                        <div className="">
                            <img src={drivePro} alt="" className="xl:h-28 h-20 xl:w-48 w-36 object-contain"/>
                            <h2 className="xl:text-6xl text-5xl text-blue-500">Drive <span
                                className="text-gray-500">Book</span></h2>
                        </div>
                        <p className="xl:text-2xl text-xl text-gray-500">You're only a few seconds away from
                            learning!</p>
                    </div>
                </div>

                {/* Right Container */}
                <div className="flex flex-col items-center justify-center shadow-2xl bg-blue-500 py-6 md:px-8 px-4">
                    <div className="bg-white shadow-2xl md:min-w-[400px] min-w-full py-6 md:px-8 px-6 rounded-2xl">
                        <h3 className="text-gray-500 text-3xl tracking-wide text-center">Change Password</h3>
                        <div className="space-y-6 mt-6">
                            <div className="space-y-5">
                                <div className="space-y-1">
                                    <label className="form-label">
                                        Email Address
                                    </label>
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        value={tokenPayload ? tokenPayload.email : ''}
                                        className="form-input w-full"
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label className="form-label">
                                        New Password
                                    </label>
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        onChange={e => setPassword(e.target.value)}
                                        value={passowrd}
                                        className="form-input w-full"
                                    />
                                </div>
                                <div className="space-y-1">
                                    <label className="form-label">
                                        Confirm New Password
                                    </label>
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        onChange={e => setConfirmPassword(e.target.value)}
                                        value={confirmPassowrd}
                                        className="form-input w-full"
                                    />
                                </div>
                            </div>
                            <button
                                onClick={updatePassword}
                                className="btn btn-primary w-full"
                                disabled={passowrd !== confirmPassowrd}
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            <ToastContainer/>
        </>
    );
}