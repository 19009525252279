
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import InstructorRequests from './instructorRequests';
// import BulkLessonPlanRequest from './bulkLessonPlanRequest';
import LessonPlans from './lessonPlan/lessonPlan';

const RequestTabs = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const RequestSubTabs = ['Instructor Request', 'Lesson Signature Request'];
    const [currentTab, setCurrentTab] = useState('Instructor Request');
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const storedTab = searchParams.get('requestDetailTab');
        if (!storedTab) updateQueryParam(currentTab);
    }, [])
    const handleTabClick = (tabId, event) => {
        event.preventDefault();
        setCurrentTab(tabId);
        updateQueryParam(tabId);
    };
    const updateQueryParam = (tabId) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('requestDetailTab', tabId);
        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        window.history.pushState({ path: newUrl }, '', newUrl);
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const storedTab = searchParams.get('requestDetailTab');
        if (storedTab) {
            setCurrentTab(storedTab);
        }
    }, [location.search]);
    return (
        <>
            <div className="space-y-4">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                        {/* <div
                            className="h-10 w-10 border rounded-md flex items-center justify-center hover:bg-gray-50 duration-300 cursor-pointer"
                            onClick={() => { navigate('/instructor/requests') }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                stroke="currentColor" className="w-5 h-5 stroke-gray-500">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                    d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                            </svg>
                        </div> */}
                        <h3 className="text-gray-600 lg:text-3xl text-2xl font-semibold">Requests</h3>
                        {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                            stroke="currentColor" className="w-7 h-7 stroke-green-600">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                        </svg> */}
                    </div>
                </div>
                <div className="flex flex-wrap items-center border-b">
                    {
                        RequestSubTabs.map((item, key) => (
                            <a key={key} href="#myStudents" className={currentTab === item ?
                                'border-b-2 border-b-blue-500 px-4 py-3 flex items-center gap-1 font-semibold text-sm text-blue-500' :
                                'px-4 py-3 flex items-center gap-1 text-gray-400 font-semibold text-sm'
                            }
                                onClick={(event) => handleTabClick(item, event)}>
                                {item}
                            </a>
                        ))
                    }
                </div>
                <div id="Instructor Request" style={{ display: currentTab === 'Instructor Request' ? 'block' : 'none' }}>
                    <InstructorRequests
                    />
                </div>
                <div id="Instructor Request" style={{ display: currentTab === 'Lesson Signature Request' ? 'block' : 'none' }}>
                    <LessonPlans
                    />
                </div>
            </div>
        </>
    )
}

export default RequestTabs