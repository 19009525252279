//Libraries
import React, { useEffect, useState } from "react";

//Pages
import MyStudents from "./myStudents";
import { useLocation } from "react-router-dom";
const Students = () => {
    const location = useLocation();
    const [subStudentTab, SetSubStudentTab] = useState('myStudents');
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const storedTab = searchParams.get('studentDetailTab');
        if (!storedTab) updateQueryParam(subStudentTab);
    }, [])
    const handleTabClick = (tabId, event) => {
        event.preventDefault();
        SetSubStudentTab(tabId);
        updateQueryParam(tabId);
    };

    const updateQueryParam = (tabId) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('studentDetailTab', tabId);
        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        window.history.pushState({ path: newUrl }, '', newUrl);
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const storedTab = searchParams.get('studentDetailTab');
        if (storedTab) {
            SetSubStudentTab(storedTab);
        }
    }, [location.search]);

    return <>
    <div className="space-y-4">
        <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
                <h3 className="text-gray-600 lg:text-3xl text-2xl font-semibold">Students</h3>
            </div>
        </div>
        <MyStudents />
    </div>
    </>
}

export default Students