//Libraries
import React, {useEffect, useState} from "react";
import moment from 'moment'

//Components
import Modal from "../../../components/modal/Modal";

//Services
import {
    assignStudentLesson,
    getLessonPlansList,
    getClassTypeList,
    getAssingedLessonPlan
} from "../../../services/schoolService";

const AssignStudentLesson = ({studentId, studentName, setStudentLessonModalOpen}) => {
    const [lessonList, setLessonList] = useState([]);
    const [classTypes, setClassTypes] = useState([]);
    const [selectedLessonId, setSelectedLessonId] = useState('');

    useEffect(() => {
        const fetchLessons = async () => {
            try {
                const lessonList = await getLessonPlansList(true);
                setLessonList(lessonList);
            } catch (error) {
                console.log(error);
            }
        };
        fetchLessons();
    }, []);

    useEffect(() => {
        const fetchClassTypesList = async () => {
            try {
                const classTypes = await getClassTypeList();
                setClassTypes(classTypes);
            } catch (error) {
                console.log(error);
            }
        };
        fetchClassTypesList();
    }, []);

    useEffect(() => {
        const fetchAssignedLesson = async () => {
            try {
                const response = await getAssingedLessonPlan(studentId);
                if (response && response.length) setSelectedLessonId(response[0].lesson_id)
            } catch (error) {
                console.log(error);
            }
        };
        fetchAssignedLesson();
    }, [studentId]);

    const assignLesson = async () => {
        if (!selectedLessonId !== '') {
            const selectedLessonPlan = lessonList.find((lesson) => lesson._id === selectedLessonId);
            const body = {
                student_id: studentId,
                student_name: studentName,
                lesson_id: selectedLessonId,
                lesson_name: selectedLessonPlan.name,
                classes: selectedLessonPlan.classes.map(lessonPlanClass => {
                    const classType = classTypes.find((classType) => classType._id === lessonPlanClass.class_id);
                    return {
                        ...lessonPlanClass,
                        class_duration: classType.duration,
                        class_taken: 0,
                        status: 'ASSIGNED',
                        instructor_id: null,
                        instructor_signature: null,
                        instructor_signature_date: null,
                        instructor_feedback: null,
                        student_signature: null,
                        student_signature_date: null,
                        appointed_to_student: false,
                        start_date: moment().format('YYYY-MM-DD'),
                        completion_date: null,
                    }
                })
            }
            await assignStudentLesson(body);
            closeModal();
        }
    }

    const closeModal = () => {
        setSelectedLessonId('');
        setStudentLessonModalOpen(false);
    }

    const assignLessonPlanComponent = () => <>
        <div className="space-y-1">
            <label className="form-label">Select Plan</label>
            <select
                id='lesson_id'
                name='lesson_id'
                className="form-select w-1/2"
                value={selectedLessonId}
                onChange={event => setSelectedLessonId(event.target.value)}
            >
                <option disabled value="">
                    - Select Lesson Plan -
                </option>
                {
                    lessonList && lessonList.map((lesson) => <option key={lesson._id}
                                                                     value={lesson._id}>{lesson.name}</option>)
                }
            </select>
        </div>
    </>

    return <>
        <Modal
            isOpen={true}
            closeModal={closeModal}
            title={'Assign Lesson Plan'}
            component={assignLessonPlanComponent}
            showComponent={true}
            onConfirmClick={assignLesson}
        />
    </>
}

export default AssignStudentLesson