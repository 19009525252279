//Libraries
import React, { useEffect, useState } from "react";

//Components
import Modal from "../../../components/modal/Modal";
import AlertModalBody from "../../../components/modal/AlertModalBody";

//Helpers
import { checkScreenSize } from "../../../helpers/screenSize";

//Constants
import { CrossSvg } from "../../../constants";

//Services
import { leaveSchoolById, viewMySchools } from "../../../services/instructorService";
import { useNavigate } from "react-router-dom";

const MySchools = () => {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedSchoolId, setSelectedSchoolId] = useState(null);
    const [mySchools, setMySchools] = useState([])

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(checkScreenSize(768));
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchSchools = async () => {
            try {
                const mySchools = await viewMySchools();
                setMySchools(mySchools);
            } catch (error) {
                console.log(error);
            }
        };
        fetchSchools();
    }, []);

    const openModal = (school_id) => {
        setSelectedSchoolId(school_id)
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const leaveSchool = async () => {
        let body = {
            school_id: selectedSchoolId
        }
        await leaveSchoolById(body)
        const updatedSchools = mySchools.filter(
            school => school._id !== selectedSchoolId
        );

        setMySchools(updatedSchools);
        closeModal();
    }

    const DeleteModalComponent = () => {
        return (<AlertModalBody message={`Are you sure you want to leave this school?`} />)
    }

    return (
        <>
            <div className="space-y-4">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                        <h3 className="text-gray-600 lg:text-3xl text-2xl font-semibold">My Schools</h3>
                    </div>
                </div>
                {!isMobile ?
                    <div className="space-y-4">
                        <div className="flex items-center justify-between">
                            <div className="relative">
                                <input
                                    id="search"
                                    name="search"
                                    type="text"
                                    placeholder="Search by school name..."
                                    className="form-input-icon w-96"
                                />
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                     stroke="currentColor"
                                     className="w-5 h-5 stroke-gray-500 absolute inset-0 top-2.5 left-2">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                </svg>
                            </div>
                        </div>
                        <div className="border rounded-md overflow-hidden">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead>
                                <tr className="bg-gray-50">
                                    <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                                        Name
                                    </td>
                                    <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                        Email
                                    </td>
                                    <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                        Location
                                    </td>
                                    <td>
                                    </td>
                                </tr>
                                </thead>
                                <tbody id="table-body"
                                       className="divide-y divide-gray-200">
                                {mySchools && mySchools.map((school, index) => {
                                    return (
                                        // <tr>
                                        //     <td colSpan="4" className="px-6 py-3 text-center text-red-500">No Instructor Found.</td>
                                        // </tr>
                                        <tr>
                                            <td
                                                onClick={(event) => {
                                                    navigate(`/instructor/my-schools/${school._id}?schoolDetailTab=General Info`)
                                                }}
                                                key={index}
                                                className="px-6 py-4 align-middle whitespace-nowrap text-gray-500 hover:underline cursor-pointer">
                                                {school.name}
                                            </td>
                                            <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                                {school.email}
                                            </td>
                                            <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                                {school.city} - {school.state} - {school.country}
                                            </td>
                                            <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                                <div className="flex justify-end gap-8">
                                                    <svg onClick={e => openModal(school._id, false)}
                                                         xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                                         className="w-6 h-6 stroke-red-600 cursor-pointer">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    :
                    <div className="space-y-4">
                        <div className="flex items-center justify-between gap-4">
                            <div className="relative w-full">
                                <input
                                    id="search"
                                    name="search"
                                    type="text"
                                    placeholder="Search by school name..."
                                    className="form-input-icon w-full"
                                />
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth="1.5"
                                     stroke="currentColor"
                                     className="w-5 h-5 stroke-gray-500 absolute inset-0 top-2.5 left-2">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                </svg>
                            </div>
                        </div>
                        {mySchools && mySchools.map((school, index) => {
                            return (
                                <div className="border rounded-md p-4" key={index}>
                                    <div className="flex justify-between">
                                        <div className="space-y-0.5">
                                            <h5 onClick={(event) => {
                                                navigate(`/instructor/my-schools/${school._id}?schoolDetailTab=General Info`)
                                            }} className="leading-none text-base text-gray-500 font-semibold underline">{school.name}</h5>
                                            <p className="text-sm text-gray-500">{school.email}</p>
                                            <p className="text-xs text-gray-500">{school.city} - {school.state} - {school.country}</p>
                                        </div>
                                        <div className="flex flex-col gap-1">
                                            <button
                                                onClick={e => openModal(school._id, true)}
                                                className="deactivate-user-btn">
                                                <CrossSvg />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                }
            </div>
            {modalOpen && (
                <Modal
                    isOpen={true}
                    closeModal={closeModal}
                    title={'Leave School'}
                    component={DeleteModalComponent}
                    showComponent={true}
                    onConfirmClick={leaveSchool}
                />
            )}
        </>
    )
}

export default MySchools;