//Libraries
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import moment from "moment";

//Components
import Calendar from "../../../components/calendar/calendar";

//Pages
import AppointmentModal from "./appointmentModal";

//Helpers
import notify from "../../../helpers/raiseToast";

//Services
import {
  createAppointment,
  deleteAppointmentById,
  editAppointmentById,
  getClassTypeList,
  getSchoolInstructors,
  getStudentsWithLessonPlan,
  viewAllAppointments
} from "../../../services/schoolService";

const Appointment = () => {
  const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState('');
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [classTypes, setClassTypes] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [events, setEvents] = useState([]);
  const [studentsToAdd, setStudentsToAdd] = useState([]);
  const [studentsToRemove, setStudentsToRemove] = useState([]);

  const formik = useFormik({
    initialValues: {
      name: '',
      school_id: '',
      instructor_id: '',
      instructor_name: '',
      start_time: '',
      end_time: '',
      date: [],
      address: '',
      has_student: false,
      total_seats: 0,
      class_id: '',
      class_qty: 0,
      student: [],
      cancel_description: '',
      hexCode: '',
    },
    onSubmit: async values => {
      setIsAppointmentModalOpen(false);
      if (isEditMode) {
        if (formik.values.created_by !== 'SCHOOL') {
          notify('ERROR', 'top-right', 'Access Denied: You can only edit appointments you created!')
        }
        else if (moment(selectedDate).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD'))
          notify('ERROR', 'top-right', 'Cannot Edit: Past Appointments are not editable!')
        else {
          const body = {
            name: values.name,
            address: values.address,
            start_time: values.start_time,
            end_time: values.end_time,
            date: values.date,
            instructor_id: values.instructor_id,
            instructor_name: values.instructor_name,
            students_to_add: studentsToAdd,
            students_to_remove: studentsToRemove,
            hexCode: values.hexCode,
          }
          const response = await editAppointmentById(body, selectedAppointmentId);
          if (response && response.data && response.data.updated_appointment) {
            const allAppointments = await viewAllAppointments();
            setAppointments(allAppointments);
          }
        }
      }
      closeModal();
    },
  });

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const allAppointments = await viewAllAppointments();
        setAppointments(allAppointments);
      } catch (error) { }
    }
    fetchAppointments();
  }, [])

  useEffect(() => {
    let id = 0;
    setEvents(appointments.flatMap((appointment) => {
      id++;
      return {
        id: id,
        instructor_name: appointment.instructor_name,
        title: appointment.name,
        heading: appointment.heading,
        hexCode: appointment.hexCode,
        start: moment(`${appointment.date} ${appointment.start_time}`, 'YYYY-MM-DD HH:mm A').format('YYYY-MM-DD HH:mm'),
        end: moment(`${appointment.date} ${appointment.end_time}`, 'YYYY-MM-DD HH:mm A').format('YYYY-MM-DD HH:mm'),
        appointment_id: appointment._id,
        fullyBooked: appointment.seats_remaining === 0,
        display: ""
      }

    }));
  }, [appointments])

  useEffect(() => {
    if (appointments && selectedAppointmentId.length > 0) {
      const findAppointment = async () => {
        const appointment = appointments.find((appointmentItem) => appointmentItem._id === selectedAppointmentId)
        formik.setValues(appointment);
      }
      findAppointment();
    }
  }, [selectedAppointmentId])

  useEffect(() => {
    const fetchClassesAndStudentsAndInstructors = async () => {
      try {
        const students = await getStudentsWithLessonPlan();
        setStudents(students);

        const instructors = await getSchoolInstructors();
        setInstructors(instructors);

        const classTypes = await getClassTypeList();
        setClassTypes(classTypes);
      } catch (error) { }
    }
    fetchClassesAndStudentsAndInstructors();
  }, [])

  useEffect(() => {
    if (formik.values && formik.values.class_id) {
      const filteredStudents = students.filter((student) => student.class_id && student.class_id.includes(formik.values.class_id));
      setFilteredStudents(filteredStudents);
    }
    else setFilteredStudents([]);
  }, [formik.values.class_id, students])

  const deleteAppointment = async (appointmentId) => {
    const payload = {
      cancel_description: formik.values.cancel_description
    }
    try {
      if (await deleteAppointmentById(appointmentId, payload)) {
        const updatedAppointments = appointments.filter((appointment) => appointment._id !== selectedAppointmentId);
        setAppointments(updatedAppointments);
      }
    }
    catch (error) { }
  }



  const closeModal = () => {
    setIsAppointmentModalOpen(false);
    setStudentsToAdd([]);
    setStudentsToRemove([]);
    setSelectedAppointmentId('');
    formik.resetForm();
  }

  return (
    <>
      <Calendar
        setIsAppointmentModalOpen={setIsAppointmentModalOpen}
        setSelectedAppointmentId={setSelectedAppointmentId}
        setSelectedDate={setSelectedDate}
        setIsEditMode={setIsEditMode}
        events={events}
      />
      {isAppointmentModalOpen &&
        <AppointmentModal
          isAppointmentModalOpen={isAppointmentModalOpen}
          setIsAppointmentModalOpen={setIsAppointmentModalOpen}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          formik={formik}
          students={filteredStudents}
          instructors={instructors}
          classTypes={classTypes}
          selectedAppointmentId={selectedAppointmentId}
          setSelectedAppointmentId={setSelectedAppointmentId}
          selectedDate={selectedDate}
          deleteAppointment={deleteAppointment}
          studentsToAdd={studentsToAdd}
          setStudentsToAdd={setStudentsToAdd}
          studentsToRemove={studentsToRemove}
          setStudentsToRemove={setStudentsToRemove}
          closeModal={closeModal}
          createAppointment={createAppointment}
          editAppointmentById={editAppointmentById}
          setAppointments={setAppointments}
          viewAllAppointments={viewAllAppointments}
        />
      }
    </>
  );
};

export default Appointment;