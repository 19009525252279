//Libraries
import React, {useEffect, useState} from "react";

//Components
import Modal from "../../../components/modal/Modal";
import AlertModalBody from "../../../components/modal/AlertModalBody";

//Constants
import {RemoveUserSvg} from "../../../constants";

//Services
import {removeInstructor} from '../../../services/schoolService'

//Helpers
import {checkScreenSize} from "../../../helpers/screenSize";

//styles
import './instructors.css'
import {useNavigate} from "react-router-dom";

const MyInstructors = ({myInstructorsList, setMyInstructorsList, allInstructors, setAllInstructors}) => {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedInstructorId, setSelectedInstructorId] = useState(null);
    const [filteredUsers, setFilteredUsers] = useState([])
    const [searchUser, setSearchUser] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(checkScreenSize(768));
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (searchUser === '') {
            setFilteredUsers(myInstructorsList)
        } else {
            const filteredData = myInstructorsList.filter((user) => {
                const name = user.name.toLowerCase();
                const email = user.email.toLowerCase();
                const searchValue = searchUser.toLowerCase();
                return name.includes(searchValue) || email.includes(searchValue)
            });
            setFilteredUsers(filteredData)
        }
    }, [searchUser, myInstructorsList])

    const openModal = (instructor_id) => {
        setSelectedInstructorId(instructor_id)
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const RemoveModalComponent = () => {
        return (<AlertModalBody message={'Are you sure you want to remove this instructor?'}/>)
    }

    const changeInstructorStatus = async () => {
        let body = {
            instructor_id: selectedInstructorId
        }
        await removeInstructor(body);
        const updatedInstructors = myInstructorsList.filter(
            instructor => instructor._id !== selectedInstructorId
        );
        const updatedAllInstructors = myInstructorsList.find((instructor) => instructor._id === selectedInstructorId)
        setAllInstructors([...allInstructors, updatedAllInstructors])
        setMyInstructorsList(updatedInstructors);
        closeModal();
    }

    return (
        <div>
            {!isMobile ?
                <div className="space-y-4">
                    <div className="flex items-center justify-between">
                        <div className="relative">
                            <input
                                id="search"
                                name="search"
                                type="text"
                                placeholder="Search by instructor name..."
                                onChange={event => setSearchUser(event.target.value)}
                                value={searchUser}
                                className="form-input-icon w-96"
                            />
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                 stroke="currentColor"
                                 className="w-5 h-5 stroke-gray-500 absolute inset-0 top-2.5 left-2">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                            </svg>
                        </div>
                    </div>
                    <div className="border rounded-md overflow-hidden">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead>
                            <tr className="bg-gray-50">
                                <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                                    Name
                                </td>
                                <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                    Email
                                </td>
                                <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                    Location
                                </td>
                                <td>
                                </td>
                            </tr>
                            </thead>
                            <tbody key={filteredUsers && filteredUsers.length} id="table-body"
                                   className="divide-y divide-gray-200">
                            {filteredUsers && filteredUsers?.length === 0 ?
                                <>
                                    <tr>
                                        <td colSpan="4" className="px-6 py-3 text-center text-red-500">No Instructor
                                            Found.
                                        </td>
                                    </tr>
                                </> :
                                filteredUsers && filteredUsers.map((instructor, index) => {
                                    return (
                                        <tr>
                                            <td key={index}
                                                onClick={(event) => {
                                                    navigate(`/school/instructors/${instructor._id}?instructorDetailTab=General Info`)
                                                }}
                                                className="px-6 py-4 align-middle whitespace-nowrap text-gray-500 cursor-pointer hover:underline">
                                                {instructor.name}
                                            </td>
                                            <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                                {instructor.email}
                                            </td>
                                            <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                                {instructor.city} - {instructor.state} - {instructor.country}
                                            </td>
                                            <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                                <div className="flex justify-end">
                                                    <svg onClick={e => openModal(instructor._id)}
                                                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                         strokeWidth="1.5"
                                                         stroke="currentColor"
                                                         className="w-6 h-6 stroke-red-600 cursor-pointer">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M22 10.5h-6m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"/>
                                                    </svg>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                :
                <div className="space-y-4">
                    <div className="flex items-center justify-between">
                        <div className="relative w-full">
                            <input
                                id="search"
                                name="search"
                                type="text"
                                placeholder="Search by instructor name..."
                                onChange={event => setSearchUser(event.target.value)}
                                value={searchUser}
                                className="form-input-icon w-full"
                            />
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                 stroke="currentColor"
                                 className="w-5 h-5 stroke-gray-500 absolute inset-0 top-2.5 left-2">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                            </svg>
                        </div>
                    </div>
                    {filteredUsers && filteredUsers.map((instructor, index) => {
                        return (
                            <div className="border rounded-md p-4" key={index}>
                                <div className="flex justify-between">
                                    <div className="space-y-0.5">
                                        <h5 onClick={(event) => {
                                            navigate(`/school/instructors/${instructor._id}?instructorDetailTab=General Info`)
                                        }} className="leading-none text-base text-gray-500 font-semibold">{instructor.name}</h5>
                                        <p className="text-sm text-gray-500">{instructor.email}</p>
                                        <p className="text-xs text-gray-500">{instructor.city} - {instructor.state} - {instructor.country}</p>
                                    </div>
                                    <div className="">
                                        <svg onClick={e => openModal(instructor._id)} xmlns="http://www.w3.org/2000/svg"
                                             fill="none" viewBox="0 0 24 24"
                                             strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 stroke-red-600">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            }
            {modalOpen && (
                <Modal
                    isOpen={true}
                    closeModal={closeModal}
                    title={'Remove Instructor'}
                    component={RemoveModalComponent}
                    showComponent={true}
                    onConfirmClick={changeInstructorStatus}
                />
            )}
        </div>
    )
}

export default MyInstructors;