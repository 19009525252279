import React, {useEffect, useState} from 'react'
import formatDateTime from '../../../../helpers/utils'

const Students = (props) => {

    return (
        <>
            <div className="space-y-4 md:block hidden">
                <div className="border rounded-md overflow-hidden">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                        <tr className="bg-gray-50">
                            <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                                #
                            </td>
                            <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                                Name
                            </td>
                            <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                Email
                            </td>
                            <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                Created At
                            </td>
                            <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                Status
                            </td>
                        </tr>
                        </thead>
                        <tbody id="table-body"
                               className="divide-y divide-gray-200">
                        {
                            props.studentsData && props.studentsData.length === 0 ?
                                <tr>
                                    <td colSpan="5" className="px-6 py-3 text-center text-red-500">
                                        No student found.
                                    </td>
                                </tr>
                                :
                                props.studentsData && props.studentsData.map((item, key) => (
                                    <tr>
                                        <td
                                            className="px-6 py-4 align-middle whitespace-nowrap text-gray-500 hover:underline cursor-pointer">
                                            {key + 1}
                                        </td>
                                        <td className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                                            {item?.name}
                                        </td>
                                        <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                            {item?.email}
                                        </td>
                                        <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                            {formatDateTime(item?.createdAt)}
                                        </td>
                                        <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                            {item?.is_active ? "Active" : "InActive"}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="space-y-4 md:hidden block">
                {
                    props.studentsData && props.studentsData.length === 0 ?
                        <div>
                            <div colSpan="5" className="px-6 py-3 text-center text-red-500">
                                No student found.
                            </div>
                        </div>
                        :
                        props.studentsData && props.studentsData.map((item, key) => (
                            <div className="border rounded-md p-3 shadow">
                                <div className="space-y-0.5">
                                    <h4 className="text-base text-gray-500 font-semibold">{item?.name}</h4>
                                    <p className="text-sm text-gray-500">{item.email}</p>
                                    <p className="text-sm text-gray-500">{formatDateTime(item?.createdAt)}</p>
                                    <div>
                                    <span className={item.is_active ?
                                        'bg-green-500 text-white text-xs px-4 py-1 rounded-full capitalize' :
                                        'bg-red-500 text-white text-xs px-4 py-1 rounded-full capitalize'
                                    }>
                                        {item.is_active ? "Active" : "In Active"}
                                    </span>
                                    </div>
                                </div>
                            </div>
                        ))
                }
            </div>
        </>
    )
}

export default Students