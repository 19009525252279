import React from 'react'
import formatDateTime from '../../../../helpers/utils';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PdfSvg from '../../../../assets/Pdf.svg'
import InvoiceTemplate from './InvoiceTemplate';

const TransactionHistory = (props) => {

    return (
        <>
            <div className="border rounded-md overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                        <tr className="bg-gray-50">
                            <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                                #
                            </td>
                            <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                                Detail
                            </td>
                            <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                Amount
                            </td>
                            <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                Date
                            </td>
                            <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                Pdf Download
                            </td>
                        </tr>
                    </thead>
                    <tbody id="table-body"
                        className="divide-y divide-gray-200">
                        {
                            props.studentTransactionData && props.studentTransactionData.length === 0 ?
                                <tr>
                                    <td colSpan="4" className="px-6 py-3 text-center text-red-500">No history found.</td>
                                </tr>
                                :
                                props.studentTransactionData && props.studentTransactionData.map((item, key) => (
                                    <tr key={key}>
                                        <td className="px-6 py-4 align-middle whitespace-nowrap text-gray-500 cursor-pointer hover:underline">
                                            {key + 1}

                                        </td>
                                        <td className="px-6 py-4 align-middle whitespace-nowrap text-gray-500 cursor-pointer hover:underline">
                                            {item?.class_data?.length > 0 ? item?.class_data[0]?.name : item?.package_data[0]?.name}
                                        </td>
                                        <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                            Kr {parseFloat(item?.amount).toFixed(2)}
                                        </td>
                                        <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                            {formatDateTime(item?.createdAt)}
                                        </td>
                                        <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                            <div className="flex items-center justify-center gap-8">
                                                <PDFDownloadLink
                                                    document={<InvoiceTemplate studentTransaction={item} />}
                                                    fileName={`${'Invoice'}.pdf`}
                                                >
                                                    {({ blob, url, loading, error }) => (loading ? 'Loading document...' :
                                                        <img className="w-8 h-8" src={PdfSvg} alt="pdf-svg" />)}
                                                </PDFDownloadLink>
                                            </div>
                                        </td>
                                    </tr>
                                )
                                )}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default TransactionHistory