import React, { useEffect, useState } from 'react'
import GeneralInfo from "./generalInfo";
import LessonPlan from "./lessonPlan/lessonPlan";
import Calender from "./calender";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getStudent } from '../../../../services/instructorService';
import { getLessonByUserId } from '../../../../services/instructorService';
import { getAppointmentsByUserId } from '../../../../services/instructorService';


const StudentInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const studentSubTabs = ['General Info', 'Lesson Plan', 'Calender'];
  const [subStudentTab, SetSubStudentTab] = useState('General Info');
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const storedTab = searchParams.get('studentDetailTab');
    if (!storedTab) updateQueryParam(subStudentTab);
  }, [])
  const handleTabClick = (tabId, event) => {
    event.preventDefault();
    SetSubStudentTab(tabId);
    updateQueryParam(tabId);
  };
  const updateQueryParam = (tabId) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('studentDetailTab', tabId);
    const newUrl = `${location.pathname}?${searchParams.toString()}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const storedTab = searchParams.get('studentDetailTab');
    if (storedTab) {
      SetSubStudentTab(storedTab);
    }
  }, [location.search]);


  //data

  const [studentData, setStudentData] = useState([]);
  useEffect(() => {
    const fetchGeneralInfo = async () => {
      try {
        const student = await getStudent(params['Id']);
        setStudentData(student);
      } catch (error) {
        console.log(error);
      }
    };
    fetchGeneralInfo();
  }, [])


  const [studentLesson, setstudentLesson] = useState([]);
  useEffect(() => {
    const fetchLessonPlan = async () => {
      try {
        const LessonPlans = await getLessonByUserId(params['Id']);
        console.log(LessonPlans)
        setstudentLesson(LessonPlans);
      } catch (error) {
        console.log(error);
      }
    };
    fetchLessonPlan();
  }, [])

  const [appointmentStudents, setAppointmentStudents] = useState([]);
  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const appointmentStudents = await getAppointmentsByUserId('STUDENT', params['Id']);
        setAppointmentStudents(appointmentStudents);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAppointments();
  }, [])



  return (
    <>
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <div
              className="h-10 w-10 border rounded-md flex items-center justify-center hover:bg-gray-50 duration-300 cursor-pointer"
              onClick={() => { navigate('/instructor/my-students') }}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                stroke="currentColor" className="w-5 h-5 stroke-gray-500">
                <path strokeLinecap="round" strokeLinejoin="round"
                  d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
              </svg>
            </div>
            <h3 className="text-gray-600 lg:text-3xl text-2xl font-semibold">{studentData?.name}</h3>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
              stroke="currentColor" className="w-7 h-7 stroke-green-600">
              <path strokeLinecap="round" strokeLinejoin="round"
                d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
            </svg>
          </div>
        </div>
        <div className="flex flex-wrap items-center border-b">
          {
            studentSubTabs.map((item, key) => (
              <a key={key} href="#myStudents" className={subStudentTab === item ?
                'border-b-2 border-b-blue-500 px-4 py-3 flex items-center gap-1 font-semibold text-sm text-blue-500' :
                'px-4 py-3 flex items-center gap-1 text-gray-400 font-semibold text-sm'
              }
                onClick={(event) => handleTabClick(item, event)}>
                {item}
              </a>
            ))
          }
        </div>
        <div id="General Info" style={{ display: subStudentTab === 'General Info' ? 'block' : 'none' }}>
          <GeneralInfo
            studentId={params['Id']}
            studentData={studentData}
          />
        </div>
        <div id="Lesson Plan" style={{ display: subStudentTab === 'Lesson Plan' ? 'block' : 'none' }}>
          <LessonPlan
            studentId={params['Id']}
            studentLesson={studentLesson}
          />
        </div>
        <div id="Lesson Plan" style={{ display: subStudentTab === 'Calender' ? 'block' : 'none' }}>
          <Calender
            setAppointmentStudents={setAppointmentStudents}
            studentId={params['Id']}
            appointmentStudents={appointmentStudents}
          />
        </div>
      </div>
    </>
  )
}

export default StudentInfo