//Libraries
import React from "react";

//Constants
import {CrossSvg, DashSvg, PlusSvg} from "../../../../constants";

const PackageMobileDesign = ({formik, classTypeList, packageFormChange, addClass, removeClass}) => {
    return (
        <form>
            <div className="flex items-center pt-2 gap-2 mb-4">
                <h4 className="font-bold">
                    Name:
                </h4>
                <input
                    id="name"
                    name="name"
                    type="text"
                    key='name'
                    placeholder="Package Name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    className="input"
                />
            </div>
            <div>
                <h4 className="font-bold text-sm md:text-lg lg:text-xl mb-2">Classes:</h4>
                {
                    formik.values.classes.map((classType, index) => <div className="card" key={classType._id}>
                        <div className="card-body">
                            <div className="left-container-card">
                                <h6 className="card-title w-full flex align-center justify-between">
                                    <span className="font-bold w-1/3">Name: </span>
                                    <select
                                        id='class_id'
                                        name='class_id'
                                        key={`class_id_${index}`}
                                        className="text-center border-solid border-2 rounded h-6 w-2/3"
                                        onChange={event => packageFormChange(index, event.target.name, event.target.value)}
                                        value={classType.class_id}
                                    >
                                        <option disabled value="">
                                            - Select Class Type -
                                        </option>
                                        {
                                            classTypeList.map((classTypeItem) => <option key={classTypeItem._id}
                                                                                         value={classTypeItem._id}>{classTypeItem.name}</option>)
                                        }
                                    </select>
                                </h6>
                                <h6 className="card-title w-full flex align-center justify-between">
                                    <span className="font-bold w-1/3">Quantity: </span>
                                    <input
                                        id="quantity"
                                        name="quantity"
                                        type="number"
                                        key={`quantity_${index}`}
                                        onChange={event => packageFormChange(index, event.target.name, parseInt(event.target.value))}
                                        value={classType.quantity}
                                        className="text-center border-solid border-2 rounded h-6 w-2/3"
                                    />
                                </h6>
                                <h6 className="card-title w-full flex">
                                    <span className="font-bold w-1/3">Unlimited: </span>
                                    <input
                                        id="is_unlimited"
                                        name="is_unlimited"
                                        type="checkbox"
                                        className="align-middle"
                                        key={`is_unlimited_${index}`}
                                        checked={classType.is_unlimited}
                                        value={classType.is_unlimited}
                                        onChange={event => packageFormChange(index, event.target.name, event.target.checked)}
                                    />
                                </h6>
                            </div>
                            <div className="right-container-card flex items-start gap-2">
                                <div
                                    onClick={e => removeClass(index)}
                                    className="deactivate-user-btn">
                                    <CrossSvg/>
                                </div>
                            </div>
                        </div>
                    </div>)
                }
            </div>
            <div className="pb-2 flex items-center justify-between">
                <div className="package-row-breaker">
                    <DashSvg/>
                    <div className="cursor-pointer" onClick={addClass} style={{color: "var(--primary-color)"}}>
                        <PlusSvg/>
                    </div>
                    <DashSvg/>
                </div>
            </div>
            <div>
                <div className="flex items-center h-8 border-solid border-t-2">
                    <h4 className="font-bold">Original Price:</h4> <p
                    className="ml-2">Kr {parseFloat(formik.values.price_before_discount).toFixed(2)}</p>
                </div>
                <div className="flex items-center h-8 border-solid border-t-2 w-full">
                    <h4 className="font-bold">
                        Discount:
                    </h4>
                    <div className="flex items-center gap-2 w-full ml-7">
                        <input
                            id="discount"
                            name="discount_on_package"
                            type="number"
                            key={`discount`}
                            onChange={formik.handleChange}
                            value={formik.values.discount_on_package}
                            className="text-center border-solid border-2 rounded h-6 w-1/6"
                        />
                        <h4
                            className="font-bold"
                        >
                            %
                        </h4>
                    </div>

                </div>
                <div className="flex items-center h-8 border-solid border-t-2">
                    <h4 className="font-bold">Total Price:</h4>
                    <p className="ml-5">Kr {parseFloat(formik.values.price_before_discount - (formik.values.discount_on_package * formik.values.price_before_discount) / 100).toFixed(2)}</p>
                </div>
            </div>
        </form>
    )
}

export default PackageMobileDesign;