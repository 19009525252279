import React, { useEffect, useState } from 'react'
import formatDateTime from '../../../helpers/utils';
import { PDFDownloadLink } from "@react-pdf/renderer";
import InvoiceTemplate from "./InvoiceTemplate";
import PdfSvg from "../../../assets/Pdf.svg";

const CreditHistory = (props) => {

    return (
        <>
            <div className="space-y-4 md:block hidden">
                <div className="border rounded-md overflow-hidden">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                            <tr className="bg-gray-50">
                                <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                                    #
                                </td>
                                <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                                    Amount
                                </td>
                                <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                                    Status
                                </td>
                                <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                                    Date
                                </td>
                            </tr>
                        </thead>
                        <tbody id="table-body"
                            className="divide-y divide-gray-200">
                            {
                                props.CreditHistoryData && props.CreditHistoryData.length === 0 ?
                                    <tr>
                                        <td colSpan="4" className="px-6 py-3 text-center text-red-500">No history found.
                                        </td>
                                    </tr>
                                    :
                                    props.CreditHistoryData && props.CreditHistoryData.map((item, key) => (
                                        <tr>
                                            <td className="px-6 py-4 align-middle whitespace-nowrap text-gray-500 cursor-pointer hover:underline">
                                                {key + 1}
                                            </td>
                                            <td className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                                                Kr {parseFloat(item?.amount).toFixed(2)}
                                            </td>
                                            <td className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                                                <span className={item?.convention === 'credit' ?
                                                    'bg-green-500 text-white text-sm px-4 py-1 rounded-full capitalize' :
                                                    'bg-red-500 text-white text-sm px-4 py-1 rounded-full capitalize'
                                                }>
                                                    {item?.convention}
                                                </span>
                                            </td>
                                            <td className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                                                {formatDateTime(item?.createdAt)}
                                            </td>
                                        </tr>
                                    )
                                    )}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="space-y-4 md:hidden block">
                {
                    props.CreditHistoryData && props.CreditHistoryData.length === 0 ?
                        <div>
                            <div className="px-6 py-3 text-center text-red-500">No history found.</div>
                        </div>
                        :
                        props.CreditHistoryData && props.CreditHistoryData.map((item, key) => (
                            <div className="border rounded-md p-3 shadow">
                                <div>
                                    <div className="flex items-center justify-between py-2 border-b">
                                        <span className="text-gray-500">Invoice Amount:</span>
                                        <span className="font-semibold text-gray-500">Kr {item?.amount}</span>
                                    </div>
                                    <div className="flex items-center justify-between py-2 border-b">
                                        <span className="text-gray-500">Date:</span>
                                        <span
                                            className="font-semibold text-gray-500">{formatDateTime(item?.createdAt)}</span>
                                    </div>
                                    <div className="flex items-center justify-between pt-1">
                                        <span className="text-gray-500">Status:</span>
                                        <span className={item?.convention === 'credit' ?
                                            'bg-green-500 text-white text-xs px-4 py-1 rounded-full capitalize' :
                                            'bg-red-500 text-white text-xs px-4 py-1 rounded-full capitalize'
                                        }>
                                            {item?.convention}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ))
                }
            </div>
        </>
    )
}

export default CreditHistory