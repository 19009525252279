//Libraries
import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';

//Helpers
import logout from '../../helpers/logout';

export default function UserDropdown() {
    const [userEmail] = useState(localStorage.getItem('email'))
    const [userType] = useState(localStorage.getItem('user_type'))
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (dropdownRef.current &&
            !dropdownRef.current.contains(event.target) &&
            !buttonRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);

    const toggleUserDropdown = () => {
        setIsOpen(!isOpen);
    };


    return (
        <div className="relative flex w-full flex-wrap items-stretch">
            <div ref={buttonRef} onClick={toggleUserDropdown} id="userDropdownButton"
                 data-dropdown-toggle="userDropdown" className="flex items-center gap-1 cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                     stroke="currentColor" className="w-8 h-8 stroke-gray-500">
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"/>
                </svg>
                <span className="hover:underline text-gray-600 md:text-base text-sm md:block hidden">{userEmail}</span>
                <svg className="w-5 h-5 fill-gray-500 md:block hidden" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"></path>
                </svg>
            </div>

            {isOpen && (
                <div ref={dropdownRef} id="userDropdown"
                     className="top-2 right-0 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-60 ">
                    <div className="px-4 py-3 text-gray-900">
                        <div className="font-semibold capitalize text-gray-600 text-base">{userType}</div>
                        <div className="text-sm truncate">{userEmail}</div>
                    </div>
                    <div className="py-2">
                        <Link onClick={logout} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 duration-300">Sign
                            Out</Link>
                    </div>
                </div>
            )}
        </div>
    )
}