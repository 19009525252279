//Libraries
import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';

//Routes
import InstructorRoutes from '../routes/instructorRoutes';

//Components
import Sidebar from '../components/sidebar/Sidebar'
import Navbar from '../components/navbars/Navbar'

//Constants
import { INSTRUCTOR_SIDEBAR_OPTIONS, SCHOOL_SIDEBAR_OPTIONS } from '../constants';
import SchoolRoutes from "../routes/schoolRoutes";

const InstructorLayout = ({ isSideBar, setIsSideBar }) => {
  return (
    <>
      <div className="flex flex-row ">
        <Sidebar isSideBar={isSideBar} setIsSideBar={setIsSideBar} sidebarOptions={INSTRUCTOR_SIDEBAR_OPTIONS} />
        <div className="flex-1 min-h-screen">
          <Navbar isSideBar={isSideBar} setIsSideBar={setIsSideBar} />
          <div className="container py-5 mb-16">
            <InstructorRoutes />
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default InstructorLayout;
