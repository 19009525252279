//Libraries
import React, { useEffect, useState } from "react";

//Components
import Modal from "../../../components/modal/Modal";

//Constants
import { AcceptSvg, CrossSvg } from "../../../constants";

//Helpers
import { checkScreenSize } from "../../../helpers/screenSize";

//Services
import { viewSchoolRequests, changeRequestStatus } from "../../../services/instructorService";

const InstructorRequests = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedSchoolId, setSelectedSchoolId] = useState(null);
    const [schoolRequests, setSchoolRequests] = useState([])
    const [requestStatus, setRequestStatus] = useState('ACTIVE');
    const [modalTitle, setModalTitle] = useState('Accept Request');
    const [modalMessage, setModalMessage] = useState('Are you sure you want to accept request!');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(checkScreenSize(768));
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchSchoolRequests = async () => {
            try {
                const schoolRequests = await viewSchoolRequests('pending');
                setSchoolRequests(schoolRequests);
            } catch (error) {
                console.log(error);
            }
        };
        fetchSchoolRequests();

    }, [])

    const openModal = (schoolId, status) => {
        if (status) {
            setModalTitle('Accept Request')
            setModalMessage('Are you sure you want to accept request!');
            setRequestStatus('ACCEPTED');
        } else {
            setModalTitle('Cancel Request')
            setModalMessage('Are you sure you want to cancel request!');
            setRequestStatus('REJECTED');
        }
        setSelectedSchoolId(schoolId)
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const modifyRequestStatus = async () => {
        let body = {
            status: requestStatus,
            school_id: selectedSchoolId
        }
        await changeRequestStatus(selectedSchoolId, body);
        const updatedSchools = schoolRequests.filter(
            school => school._id !== selectedSchoolId
        );

        setSchoolRequests(updatedSchools);
        closeModal();
    }

    return (
        <>
            <div className="space-y-4">
                {/* <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                        <h3 className="text-gray-600 lg:text-3xl text-2xl font-semibold">Requests</h3>
                    </div>
                </div> */}
                {!isMobile ?
                    <div className="space-y-4">
                        <div className="border rounded-md overflow-hidden">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead>
                                <tr className="bg-gray-50">
                                    <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                                        Name
                                    </td>
                                    <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                                        Email
                                    </td>
                                    <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                                        Location
                                    </td>
                                    <td></td>
                                </tr>
                                </thead>
                                <tbody id="table-body"
                                       className="divide-y divide-gray-200">
                                {schoolRequests.length === 0 ?
                                    <tr>
                                        <td colSpan="4" className="px-6 py-3 text-center text-red-500">No requests yet.</td>
                                    </tr>
                                    : ""
                                }
                                {schoolRequests.map((school, index) => {
                                    return (
                                        <tr>
                                            <td key={index} className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                                                {school.name}
                                            </td>
                                            <td className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                                                {school.email}
                                            </td>
                                            <td className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                                                {school.city} - {school.state} - {school.country}
                                            </td>
                                            <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                                <div className="flex items-center justify-end gap-2">
                                                    <button onClick={e => openModal(school._id, false)} type="button"
                                                            className="h-8 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-red-500 text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-all text-xs">
                                                        Reject
                                                    </button>
                                                    <button onClick={e => openModal(school._id, true)} type="button"
                                                            className="h-8 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-green-500 text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all text-xs">
                                                        Accept
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    :
                    <div className="space-y-4">
                        {schoolRequests.map((school, index) => {
                            return (
                                <div className="border rounded-md p-4" key={index}>
                                    <div className="flex justify-between">
                                        <div className="space-y-0.5">
                                            <h5 className="leading-none text-base text-gray-500 font-semibold underline">{school.name}</h5>
                                            <p className="text-sm text-gray-500">{school.email}</p>
                                            <p className="text-xs text-gray-500">{school.city} - {school.state} - {school.country}</p>
                                        </div>
                                        <div className="flex flex-col gap-1">
                                            <button
                                                onClick={e => openModal(school._id, false)}
                                                className="deactivate-user-btn">
                                                <CrossSvg />
                                            </button>
                                            <button
                                                onClick={e => openModal(school._id, true)}
                                                className="activate-user-btn">
                                                <AcceptSvg />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>}
            </div>
            {modalOpen && (
                <Modal
                    isOpen={true}
                    closeModal={closeModal}
                    title={modalTitle}
                    message={modalMessage}
                    onConfirmClick={modifyRequestStatus}
                />
            )}
        </>
    )
}

export default InstructorRequests;