//Libraries
import { Navigate, Outlet} from 'react-router-dom';

const PrivateRoute = ({ isProtected = true, redirectTo = '/login', allowedUserType,element: Element, ...rest }) => {
  const token = localStorage.getItem('accessToken')
  const userType = localStorage.getItem('user_type')

  let isAuthenticated = false
  if(token){
    isAuthenticated = true
  }
  return (
    isProtected ? (
      allowedUserType === userType ? (
        isAuthenticated ? <Outlet /> : <Navigate to={redirectTo} />
      ) : (
        userType ? <Navigate to={`/${userType}/home`} replace /> : <Navigate to={redirectTo} />
      )
    ) : (
      <Outlet />
    )
  );
};


export default PrivateRoute;