//Libraries
import React, {useEffect, useState} from "react";

//Components
import Modal from "../../../components/modal/Modal";
import AlertModalBody from "../../../components/modal/AlertModalBody";

//Pages
import AssignStudentPackage from "./assignStudentPackage";
import AssignStudentLesson from "./assignStudentLesson";

//Constants
import {BookSvg, DollarSvg, InviteSvg, RemoveUserSvg} from "../../../constants";

//Helpers
import {checkScreenSize} from "../../../helpers/screenSize";

//Services
import {getSchoolStudents, removeStudentFromSchool, sendInvitationToStudent} from "../../../services/schoolService";

//Styles
import './students.css'
import {useNavigate} from "react-router-dom";


const MyStudents = (props) => {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [studentPackageModelOpen, setStudentPackageModelOpen] = useState(false);
    const [studentLessonModalOpen, setStudentLessonModalOpen] = useState(false);
    const [myStudents, setMyStudents] = useState([]);
    const [selectedStudentId, setSelectedStudentId] = useState(null);
    const [selectedStudentName, setSelectedStudentName] = useState('');
    const [inviteModalOpen, setInviteModalOpen] = useState(false);
    const [invitationEmail, setInvitationEmail] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([])
    const [searchUser, setSearchUser] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(checkScreenSize(768));
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchMyStudents = async () => {
            try {
                const myStudents = await getSchoolStudents();
                setMyStudents(myStudents);
            } catch (error) {
                console.log(error);
            }
        };
        fetchMyStudents();
    }, []);

    const handleEmailChange = (email) => {
        setInvitationEmail(email);
    };

    useEffect(() => {
        if (searchUser === '') {
            setFilteredUsers(myStudents)
        } else {
            const filteredData = myStudents.filter((user) => {
                const name = user.name.toLowerCase();
                const email = user.email.toLowerCase();
                const searchValue = searchUser.toLowerCase();
                return name.includes(searchValue) || email.includes(searchValue)
            });
            setFilteredUsers(filteredData)
        }
    }, [searchUser, myStudents])

    const openInviteModal = () => {
        setInviteModalOpen(true);
    }

    const closeInviteModal = () => {
        setInvitationEmail('');
        setInviteModalOpen(false);
    }

    const openModal = (student_id) => {
        setSelectedStudentId(student_id)
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const removeStudent = async () => {
        let body = {
            student_id: selectedStudentId
        }
        await removeStudentFromSchool(body)
        const updatedStudents = myStudents.filter(
            student => student._id !== selectedStudentId
        );
        setMyStudents(updatedStudents);
        closeModal();
    }

    const sendInvite = () => {
        let body = {
            email: invitationEmail
        }
        sendInvitationToStudent(body)
        closeInviteModal();
    }

    const InviteComponent = () => {
        return (
            <div className="">
                <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Enter your email here..."
                    onChange={event => handleEmailChange(event.target.value)}
                    value={invitationEmail}
                    required
                    className="form-input w-full"
                />
            </div>
        )
    }

    const DeleteModalComponent = () => {
        return (<AlertModalBody message={`Are you sure you want to remove this student?`}/>)
    }

    const openAssignStudentPackageModal = (studentId) => {
        setSelectedStudentId(studentId);
        setStudentPackageModelOpen(true);
    }

    const openAssignStudentLessonModal = (student) => {
        setSelectedStudentId(student._id);
        setSelectedStudentName(student.name);
        setStudentLessonModalOpen(true);
    }

    return (
        <div>
            {!isMobile ?
                <div className="space-y-4">
                    <div className="flex items-center justify-between">
                        <div className="relative">
                            <input
                                id="search"
                                name="search"
                                type="text"
                                placeholder="Search by student name..."
                                onChange={event => setSearchUser(event.target.value)}
                                value={searchUser}
                                className="form-input-icon w-96"
                            />
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                 stroke="currentColor"
                                 className="w-5 h-5 stroke-gray-500 absolute inset-0 top-2.5 left-2">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                            </svg>
                        </div>
                        <button onClick={openInviteModal} type="button"
                                className="h-10 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm">
                            Invite Via Mail
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2"
                                 stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"/>
                            </svg>
                        </button>
                    </div>
                    <div className="border rounded-md overflow-hidden">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead>
                            <tr className="bg-gray-50">
                                <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                                    Name
                                </td>
                                <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                    Email
                                </td>
                                <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                    Location
                                </td>
                                <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                    Credit
                                </td>
                                <td>
                                </td>
                            </tr>
                            </thead>
                            <tbody key={filteredUsers && filteredUsers.length} id="table-body"
                                   className="divide-y divide-gray-200">
                            {filteredUsers && filteredUsers.map((student, index) => {
                                return (
                                    // <tr>
                                    //     <td colSpan="4" className="px-6 py-3 text-center text-red-500">No Instructor Found.</td>
                                    // </tr>
                                    <tr>
                                        <td key={index}
                                            onClick={(event) => {
                                                navigate(`/school/students/${student._id}?studentDetailTab=General Info`)
                                            }}
                                            className="px-6 py-4 align-middle whitespace-nowrap text-gray-500 cursor-pointer hover:underline">
                                            {student.name}
                                        </td>
                                        <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                            {student.email}
                                        </td>
                                        <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                            {student.city} - {student.state} - {student.country}
                                        </td>
                                        <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                            Credit
                                        </td>
                                        <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                            <div className="flex items-center justify-end gap-8">
                                                <button onClick={() => openAssignStudentLessonModal(student)}
                                                        className="btn btn-sm btn-secondary-outline">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg" fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth="1.5" stroke="currentColor"
                                                        className="w-5 h-5 stroke-gray-500 cursor-pointer">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"/>
                                                    </svg>
                                                    Assign Lesson
                                                </button>
                                                <button onClick={() => openAssignStudentPackageModal(student._id)}
                                                        className="btn btn-sm btn-secondary-outline">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24"
                                                         strokeWidth="1.5" stroke="currentColor"
                                                         className="w-5 h-5 stroke-gray-500 cursor-pointer">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                                    </svg>
                                                    Add Package/Class
                                                </button>
                                                <svg onClick={e => openModal(student._id)}
                                                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                     strokeWidth="1.5"
                                                     stroke="currentColor"
                                                     className="w-6 h-6 stroke-red-600 cursor-pointer">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"/>
                                                </svg>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
                :
                <div className="space-y-4">
                    <div className="flex items-center justify-between gap-4">
                        <div className="relative w-full">
                            <input
                                id="search"
                                name="search"
                                type="text"
                                placeholder="Search by student name..."
                                onChange={event => setSearchUser(event.target.value)}
                                value={searchUser}
                                className="form-input-icon w-full"
                            />
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 strokeWidth="1.5"
                                 stroke="currentColor"
                                 className="w-5 h-5 stroke-gray-500 absolute inset-0 top-2.5 left-2">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                            </svg>
                        </div>
                        <button onClick={openInviteModal} className="btn btn-primary">Invite</button>
                    </div>
                    {filteredUsers && filteredUsers.map((student, index) => {
                        return (
                            <div className="border rounded-md p-4" key={index}>
                                <div className="flex justify-between">
                                    <div className="space-y-0.5">
                                        <h5 onClick={(event) => {
                                            navigate(`/school/students/${student._id}?studentDetailTab=General Info`)
                                        }}
                                            className="leading-none text-base text-gray-500 font-semibold underline">{student.name}</h5>
                                        <p className="text-sm text-gray-500">{student.email}</p>
                                        <p className="text-xs text-gray-500">{student.city} - {student.state} - {student.country}</p>
                                    </div>
                                    <div className="flex flex-col gap-1">
                                        <button
                                            onClick={() => openAssignStudentPackageModal(student._id)}
                                            className="activate-student-user-btn">
                                            Credit
                                        </button>
                                        <button
                                            onClick={() => openAssignStudentLessonModal(student._id)}
                                            className="activate-student-user-btn">
                                            Lesson Plan
                                        </button>
                                        <button
                                            onClick={e => openModal(student._id)}
                                            className="deactivate-student-user-btn">
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            }
            {modalOpen && (
                <Modal
                    isOpen={true}
                    closeModal={closeModal}
                    title={'Remove Student'}
                    component={DeleteModalComponent}
                    showComponent={true}
                    onConfirmClick={removeStudent}
                />
            )}
            {inviteModalOpen && (
                <Modal
                    isOpen={true}
                    closeModal={closeInviteModal}
                    title={'Send Invite'}
                    component={InviteComponent}
                    showComponent={true}
                    onConfirmClick={sendInvite}
                />
            )}
            {studentPackageModelOpen && (
                <AssignStudentPackage studentId={selectedStudentId}
                                      setStudentPackageModelOpen={setStudentPackageModelOpen}/>
            )}

            {studentLessonModalOpen && (
                <AssignStudentLesson studentId={selectedStudentId} studentName={selectedStudentName}
                                     setStudentLessonModalOpen={setStudentLessonModalOpen}/>
            )}
        </div>
    )
}

export default MyStudents;