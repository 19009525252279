import React, { useEffect, useState } from 'react';
import { Document, Page, View, Text, Image } from '@react-pdf/renderer';
import drivePro from './../../../../assets/drivePro.jpeg';
import { TrimId, formatDateOnly } from '../../../../helpers/utils';
import { getClassDataOnIds } from '../../../../services/instructorService';

const InvoiceTemplate = (props) => {
    const [instructorData] = useState(props?.instructorTransaction?.instructor_data[0]);
    const [instructorPayload] = useState(props?.instructorTransaction?.instructor_payload[0]);
    const [classesData] = useState(props?.instructorTransaction?.classes_data[0]);
    const [totalPrice, setTotalPrice] = useState(props?.instructorTransaction?.total_earned);
    const [percentagePrice, setPercentagePrice] = useState(props?.instructorTransaction?.total_earned * 0.25);


    return (
        <Document>
            <Page size="A4" style={{
                paddingHorizontal: 30,
                paddingVertical: 20,
                fontSize: '10',
                color: '#3F3F46',
                fontFamily: 'Helvetica'
            }}>

                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View style={{ width: '50%' }}>
                        <View>
                            <View style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: "space-between",
                                borderBottom: 0.5,
                                borderBottomColor: '#D4D4D8',
                                padding: 5
                            }}>
                                <Text style={{ fontSize: 20, fontFamily: 'Helvetica-Bold' }}>Drive <Text
                                    style={{ color: '#3B82F6' }}>Pro</Text></Text>
                            </View>
                            <View style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: "space-between",
                                borderBottom: 0.5,
                                borderBottomColor: '#D4D4D8',
                                padding: 5
                            }}>
                                <Text>Invoice Number</Text>
                                <Text>#{TrimId(props?.instructorTransaction?._id)}</Text>
                            </View>

                            <View style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: "space-between",
                                borderBottom: 0.5,
                                borderBottomColor: '#D4D4D8',
                                padding: 5
                            }}>
                                <Text>Invoice Date</Text>
                                <Text>{formatDateOnly(props?.instructorTransaction?.date)}</Text>
                            </View>

                            <View style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: "space-between",
                                borderBottom: 0.5,
                                borderBottomColor: '#D4D4D8',
                                padding: 5
                            }}>
                                <Text>Address</Text>
                                <Text>{instructorPayload?.address}</Text>
                            </View>

                            <View style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: "space-between",
                                borderBottom: 0.5,
                                borderBottomColor: '#D4D4D8',
                                padding: 5
                            }}>
                                <Text>Email</Text>
                                <Text>{instructorPayload?.email}</Text>
                            </View>

                        </View>
                    </View>
                    <View style={{ width: '50%' }}>
                        <View style={{ maxHeight: 180 }}>
                            <Image src={drivePro}
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </View>
                    </View>
                </View>

                <View style={{
                    backgroundColor: '#3B82F6',
                    color: 'white',
                    display: 'flex',
                    paddingHorizontal: 6,
                    paddingVertical: 8,
                    flexDirection: 'row',
                    marginTop: -30
                }}>
                    <Text style={{ width: '40%', fontFamily: 'Helvetica-Bold' }}>Appointment Title</Text>
                    <Text style={{ width: '20%', textAlign: 'center', fontFamily: 'Helvetica-Bold' }}>Class quantity</Text>
                    <Text style={{ width: '20%', textAlign: 'center', fontFamily: 'Helvetica-Bold' }}>Hour Rate</Text>
                    <Text style={{ width: '20%', textAlign: 'center', fontFamily: 'Helvetica-Bold' }}>Total</Text>
                </View>
                <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    borderBottom: 0.5,
                    borderBottomColor: '#D4D4D8',
                    paddingHorizontal: 6,
                    paddingVertical: 8,
                }}>
                    <Text style={{ width: '40%' }}>{props?.instructorTransaction?.name}</Text>
                    <Text style={{ width: '20%', textAlign: 'center' }}>{props?.instructorTransaction?.class_qty}</Text>
                    <Text style={{ width: '20%', textAlign: 'center' }}>DKK {parseFloat(instructorData?.hr_rate).toFixed(2)}</Text>
                    <Text
                        style={{ width: '20%', textAlign: 'center' }}>DKK {parseFloat((props?.instructorTransaction?.total_earned ? props?.instructorTransaction?.total_earned : 0)).toFixed(2)}</Text>
                </View>

                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 30 }}>
                    <View style={{ width: '50%' }}>
                        <View style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: "space-between",
                            borderBottom: 0.5,
                            borderBottomColor: '#D4D4D8',
                            paddingHorizontal: 6,
                            paddingVertical: 8,
                        }}>
                            <Text>Amount excl. VAT</Text>
                            <Text>DKK {parseFloat(totalPrice - percentagePrice)?.toFixed(2)}</Text>
                        </View>

                        <View style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: "space-between",
                            borderBottom: 0.5,
                            borderBottomColor: '#D4D4D8',
                            paddingHorizontal: 6,
                            paddingVertical: 8,
                        }}>
                            <Text>25% moms</Text>
                            <Text>DKK {parseFloat(percentagePrice)?.toFixed(2)}</Text>
                        </View>

                        <View style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: "space-between",
                            backgroundColor: '#3B82F6',
                            color: 'white',
                            paddingHorizontal: 6,
                            paddingVertical: 8,
                            fontFamily: 'Helvetica-Bold'
                        }}>
                            <Text>Total</Text>
                            <Text>DKK {parseFloat(totalPrice)?.toFixed(2)}</Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default InvoiceTemplate;
