//Libraries
import React, {useEffect, useState} from "react";
import moment from "moment";
import {useNavigate} from "react-router-dom";

//Services
import {getAllUserNotifications, setNotificationViewed} from "../../services/userService";

//Constants
import {ColorTypes, NotificationListingIcons} from "../../constants";

const Notifications = () => {
    const navigate = useNavigate();
    const [activeButton, setActiveButton] = useState('all');
    const [notifications, setNotifications] = useState([]);
    const [filteredNotifications, setFilteredNotifications] = useState([]);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await getAllUserNotifications();
                setNotifications(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchNotifications();
    }, [])

    useEffect(() => {
        setFilteredNotifications(notifications);
    }, [notifications])

    const renderIcon = (messageType, status) => {
        let IconName = '';
        let ColorType = '';

        switch (status) {
            case 'ACCEPTED':
                IconName = 'TICK_ICON';
                ColorType = 'SUCCESS';
                break;
            case 'REJECTED':
                IconName = 'CROSS_ICON';
                ColorType = 'ERROR';
                break;
            case 'CANCELLED':
                IconName = 'CROSS_ICON';
                ColorType = 'ERROR';
                break;
            default:
                IconName = messageType;
                ColorType = 'PRIMARY';
                break;
        }
        const IconComponent = NotificationListingIcons[IconName];
        return (<span style={{color: ColorTypes[ColorType]}}>
            {IconComponent ? <IconComponent/> : ""}
        </span>);
    }

    const handleButtonClick = (type) => {
        setActiveButton(type);
        let updatedNotifications = [];

        if (type === 'all') updatedNotifications = [...notifications]; else updatedNotifications = notifications.filter((notification) => !notification.is_read);

        setFilteredNotifications(updatedNotifications);
    };

    const redirectToRoute = async (route, notificationId, isRead) => {
        if (!isRead && await setNotificationViewed(notificationId)) {
            const updatedNotifications = notifications.map((notification) => {
                if (notification._id === notificationId) notification.is_read = true;
                return notification;
            })
            setNotifications(updatedNotifications);
        }
        navigate(route);
    }

    const toTitleCase = (str) => {
        return str.replace(/\b\w+/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    return (
        <div className="space-y-4">
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                    <h3 className="text-gray-600 lg:text-3xl text-2xl font-semibold">Notifications</h3>
                </div>
            </div>
            <div>
                <button
                    onClick={() => handleButtonClick("all")}
                    className={`
            ${activeButton === "all" ? "bg-blue-500 text-white" : "bg-white text-gray-500"}
            py-2 px-8 rounded-l hover:bg-blue-600 hover:text-white focus:outline-none duration-300`}
                >
                    All
                </button>
                <button
                    onClick={() => handleButtonClick("unread")}
                    className={`
            ${activeButton === "unread" ? "bg-blue-500 text-white" : "bg-white text-gray-500"}
            py-2 px-4 rounded-r hover:bg-blue-600 hover:text-white focus:outline-none duration-300`}
                >
                    Unread
                </button>
            </div>
            <div className="space-y-2">
                {filteredNotifications.map((notification, index) => {
                    return (
                        // className="flex items-center gap-4 p-3 hover:bg-gray-100 cursor-pointer first:rounded-t last:rounded-b duration-300 border-l-2"
                        <div
                            className={`flex items-center gap-4 p-3 border border-gray-200 rounded hover:bg-gray-50 cursor-pointer shadow-sm
                            ${notification.is_read ? 'border-l-4 border-l-blue-500' : 'border-l-4 bg-gray-50'}`}
                            key={index}
                            onClick={() => redirectToRoute(notification.route, notification._id, notification.is_read)}
                            // style={{backgroundColor: notification.is_read ? '' : '#F9FAFB'}}
                        >
                            <div>
                                {renderIcon(notification.message_type, notification.status)}
                            </div>
                            <div>
                                <p className="text-sm text-gray-500">
                                    {moment(notification.createdAt).format('DD-MM-YYYY hh:mm A')}
                                </p>
                                <h5 className="font-semibold text-gray-500">
                                    {toTitleCase(notification.title)}
                                </h5>
                                <p className="text-sm text-gray-500">
                                    {notification.sender_name} {notification.message}
                                </p>
                            </div>
                        </div>)
                })}
            </div>
        </div>)
}

export default Notifications;