//Libraries
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

//Components
import Calendar from "../../../components/calendar/calendar";
import notify from "../../../helpers/raiseToast";

//Pages
import AppointmentModal from "./appointmentModal";

//Services
import { bookStudentAppointment, cancelAppointmentById, getAllClassTypes, getStudentLessonPlans, viewAllAppointments, viewMySchool } from "../../../services/studentService";
import { StudentContext } from "../../../layouts/StudentLayout";

const Appointment = () => {
    const { setCreditAvailable } = useContext(StudentContext);
    const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedAppointmentId, setSelectedAppointmentId] = useState('');
    const [selectedAppointment, setSelectedAppointment] = useState({});
    const [selectedDate, setSelectedDate] = useState('');
    const [school, setSchool] = useState({});
    const [classTypes, setClassTypes] = useState([]);
    const [appointments, setAppointments] = useState([]);
    const [events, setEvents] = useState([]);
    const [lessonPlan, setLessonPlan] = useState({});

    useEffect(() => {
        const fetchClassesAndSchoolAndLessonPlan = async () => {
            try {
                const classTypes = await getAllClassTypes();
                setClassTypes(classTypes);
                const { data } = await viewMySchool();
                setSchool(data[0]);
                const lessonPlan = await getStudentLessonPlans();
                setLessonPlan(lessonPlan);
            } catch (error) { }
        }
        fetchClassesAndSchoolAndLessonPlan();
    }, [])
    const fetchAppointments = async () => {
        try {
            if (school && school._id) {
                const allAppointments = await viewAllAppointments(school._id);
                const filteredAppointments = filterAppointments(allAppointments);
                setAppointments(filteredAppointments);
            }
        } catch (error) { }
    }
    useEffect(() => {
        fetchAppointments();
    }, [school])

    useEffect(() => {
        let id = 0;
        setEvents(appointments.flatMap((appointment) => {
            id++;
            return {
                id: id,
                title: appointment.name,
                instructor_name: appointment.instructor_name,
                heading: appointment.heading,
                start: moment(`${appointment.date} ${appointment.start_time}`, 'YYYY-MM-DD HH:mm A').format('YYYY-MM-DD HH:mm'),
                end: moment(`${appointment.date} ${appointment.end_time}`, 'YYYY-MM-DD HH:mm A').format('YYYY-MM-DD HH:mm'),
                appointment_id: appointment._id,
                fullyBooked: appointment.seats_remaining === 0,
                bookedByUser: appointment.student.find((student) => localStorage.getItem('id') === student.id && student.status === 'ACCEPTED' && student.is_active),
                hexCode: appointment.hexCode
            }
        }));
    }, [appointments])

    useEffect(() => {
        if (appointments && selectedAppointmentId.length > 0) {
            const findAppointment = async () => {
                const appointment = appointments.find((appointmentItem) => appointmentItem._id === selectedAppointmentId)
                setSelectedAppointment(appointment);
            }
            findAppointment();
        }
    }, [selectedAppointmentId])

    const bookAppointment = async () => {
        setIsAppointmentModalOpen(false);
        if (selectedAppointment.student.find((student) => student.id === localStorage.getItem('id') && student.status === 'ACCEPTED' && student.is_active)) notify('ERROR', 'top-right', `Appointment already booked!`);
        else if (selectedAppointment.seats_remaining === 0) notify('ERROR', 'top-right', `Appointment booking failed! Not enough seats available.`);
        else if (lessonPlan && !lessonPlan.classes.find((classType) => classType.class_id === selectedAppointment.class_id)) notify('ERROR', 'top-right', `Appointment booking failed! You do not have the necessary assignment for that class.`);
        else {
            const response = await bookStudentAppointment(selectedAppointment._id)
            if (response && response.updated_appointment) {
                // let updated_appointments = appointments.filter((appointment) => appointment._id !== selectedAppointment._id)
                // updated_appointments.push(response.updated_appointment)
                // setAppointments(updated_appointments)
                setSelectedAppointment({});
                fetchAppointments();
                setSelectedAppointmentId('');
                // setCreditAvailable(-1)
            }
        }
    }

    const cancelAppointment = async (appointmentId, cancellationReason) => {
        try {
            if (await cancelAppointmentById(appointmentId, cancellationReason)) {
                fetchAppointments();
                // const updated_appointments = appointments.map((appointment) => {
                //     let body = {};
                //     if (appointment._id === appointmentId) {
                //         const seats_remaining = appointment.seats_remaining + 1;
                //         const seats_booked = appointment.seats_booked - 1;
                //         const students = appointment.student.map((student) => {
                //             if (student.id === localStorage.getItem('id')) {
                //                 student.status = 'CANCELLED';
                //                 student.is_active = false;
                //             }
                //             return student;
                //         })
                //         body = {
                //             ...appointment,
                //             seats_remaining: seats_remaining,
                //             seats_booked: seats_booked,
                //             student: students
                //         }
                //     }
                //     else {
                //         body = {
                //             ...appointment
                //         }
                //     }
                //     return body;
                // })
                // const filteredAppointments = filterAppointments(updated_appointments);
                // setAppointments(filteredAppointments);
                // setCreditAvailable(-1)
            }

        }
        catch (error) { }
    }

    const filterAppointments = (allAppointments) => {
        const currentDate = moment().format('YYYY-MM-DD');
        const studentId = localStorage.getItem('id');

        return allAppointments.filter((appointment) => {
            const appointmentDates = moment(appointment.date).format('YYYY-MM-DD');
            const isAppointmentInFuture = moment(appointmentDates).diff(currentDate) >= 0;

            const isStudentBooked = appointment.student.some((student) => student.id === studentId);
            const isStudentCancelled = appointment.student.some((student) => student.id === studentId && student.status === 'CANCELLED');
            if (!isAppointmentInFuture) {
                // For past appointments, filter out if the student is not present or has 'Cancelled' status
                return isStudentBooked && !isStudentCancelled;
            } else {
                // For future appointments, check if the student is enrolled or there are available seats
                return isStudentBooked || appointment.seats_remaining > 0;
            }
        });
    }

    return (
        <>
            <Calendar
                setIsAppointmentModalOpen={setIsAppointmentModalOpen}
                setSelectedAppointmentId={setSelectedAppointmentId}
                setIsEditMode={setIsEditMode}
                setSelectedDate={setSelectedDate}
                events={events}
            />
            {isAppointmentModalOpen &&
                <AppointmentModal
                    isAppointmentModalOpen={isAppointmentModalOpen}
                    setIsAppointmentModalOpen={setIsAppointmentModalOpen}
                    school={school}
                    classTypes={classTypes}
                    selectedAppointmentId={selectedAppointmentId}
                    selectedAppointment={selectedAppointment}
                    setSelectedAppointment={setSelectedAppointment}
                    setSelectedAppointmentId={setSelectedAppointmentId}
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    bookAppointment={bookAppointment}
                    cancelAppointment={cancelAppointment}
                />
            }
        </>
    );
};

export default Appointment;