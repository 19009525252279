/*eslint-disable*/
import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";

import './Sidebar.css'

//Constants
import {Icons} from "../../constants";
import drivePro from "../../assets/drivePro1-without-bg.png";

export default function Sidebar({sidebarOptions, isSideBar, setIsSideBar}) {
    const [userType] = useState(localStorage.getItem('user_type'));
    const location = useLocation();
    const navigate = useNavigate();
    const currentRoute = location && location.pathname ? location.pathname.split('/')[2] : 'home';
    const [selectedTab, setSelectedTab] = useState(currentRoute)

    useEffect(() => {
        if (location && location.pathname) setSelectedTab(location.pathname.split('/')[2].toLowerCase());
    }, [location])

    const handleTabClick = (tabId, event) => {
        setSelectedTab(tabId.toLowerCase());
    };

    useEffect(() => {
        if (isSideBar) {
            setIsSideBar(false);
        }
    }, [navigate])

    const renderIcon = (iconName) => {
        const IconComponent = Icons[iconName];
        if (IconComponent) {
            return <IconComponent/>;
        }
        return null;
    };

    return (
        <>
            <div className="xl:block hidden">
                <nav className="min-w-[290px] bg-gray-50 border-r lg:relative min-h-full z-50 duration-300">
                    <div className="h-20 flex items-end justify-center">
                        <h3 className="text-gray-500 text-3xl tracking-wide">Drive<span
                            className="text-blue-600">Book</span>
                        </h3>
                        <img src={drivePro} alt="" className="h-16 w-24 object-contain"/>
                        {/*<svg className="h-12 w-16" viewBox="0 0 1024 1024" version="1.1"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M766.976 508.736c80.576 0 152.448 32.128 199.232 82.176" fill="#AEBCC3" />
                            <path
                                d="M64.704 684.992c10.816 19.2 32.064 32.192 56.576 32.192h784.64c35.84 0 64.832-27.648 64.832-61.76v-17.408h-36.608a15.744 15.744 0 0 1-16.064-15.296V550.912a277.568 277.568 0 0 0-150.144-44.16h1.6l-55.04-0.256c-53.632-115.2-157.504-210.752-294.208-210.752-136.512 0-251.008 89.728-282.176 210.688h-16.832c-35.456 0-56.128 27.392-56.128 61.184"
                                fill="#3B82F6" />
                            <path
                                d="M64.704 654.464h13.76a39.168 39.168 0 0 0 40.064-38.272v-17.6c0-21.12-17.92-38.208-40.064-38.208h-13.376"
                                fill="#71717A" />
                            <path d="M160 684.992a101.632 96.832 0 1 0 203.264 0 101.632 96.832 0 1 0-203.264 0Z"
                                fill="#71717A" />
                            <path d="M218.88 684.992a42.752 40.768 0 1 0 85.504 0 42.752 40.768 0 1 0-85.504 0Z"
                                fill="#FFFFFF" />
                            <path d="M652.032 684.992a101.568 96.832 0 1 0 203.136 0 101.568 96.832 0 1 0-203.136 0Z"
                                fill="#71717A" />
                            <path d="M710.912 684.992a42.752 40.768 0 1 0 85.504 0 42.752 40.768 0 1 0-85.504 0Z"
                                fill="#FFFFFF" />
                            <path
                                d="M966.272 591.104v-0.192a257.92 257.92 0 0 0-48.192-40V622.72c0 8.448 7.232 15.296 16.064 15.296h36.608v-42.304l-4.48-4.608z"
                                fill="#71717A" />
                            <path
                                d="M405.568 335.616c-104.896 6.336-191.296 76.8-216.64 170.816h216.64V335.616zM445.696 506.432h216.64c-41.216-86.848-117.12-159.616-216.64-170.048v170.048z"
                                fill="#FFFFFF" />
                        </svg>*/}
                    </div>
                    <div>
                        <ul className="p-4 space-y-1">
                            <div className="space-y-1">
                                {
                                    sidebarOptions.map((option) => {
                                        return (
                                            <Link
                                                className={selectedTab === option.routePath.toLowerCase() ? "flex items-center gap-3 bg-gray-200 rounded-md h-12 px-4 hover:bg-gray-200 text-gray-600 font-semibold transition-all text-sm" : "flex items-center gap-3 rounded-md h-12 px-4 hover:bg-gray-200 text-gray-500 font-semibold hover:text-gray-600 text-sm"}
                                                to={option.routePath}
                                                onClick={event => handleTabClick(option.routePath, event)}
                                                key={option.routePath}
                                            >
                                                {renderIcon(option.iconName)}
                                                {option.routeName}
                                            </Link>)
                                    })
                                }
                            </div>
                            <div className="space-y-1">
                                <Link
                                    className={selectedTab === 'notifications' ? "flex items-center gap-3 bg-gray-200 rounded-md h-12 px-4 hover:bg-gray-200 text-gray-600 font-semibold transition-all text-sm" : "flex items-center gap-3 rounded-md h-12 px-4 hover:bg-gray-200 text-gray-500 font-semibold hover:text-gray-600 text-sm"}
                                    to={'notifications'}
                                    onClick={event => handleTabClick('notifications', event)}
                                    key={'notifications'}
                                >
                                    {renderIcon('notification')}
                                    Notifications
                                </Link>
                            </div>
                        </ul>
                    </div>
                </nav>
            </div>
            <div className="xl:hidden block">
                <div onClick={() => {
                    setIsSideBar(!isSideBar)
                }} className={`${isSideBar ? "min-w-full fixed min-h-full z-50" : ''}`}></div>
                <nav
                    className={`${isSideBar ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-full'} min-w-[290px] bg-gray-50 border-r fixed min-h-full z-50 duration-300`}>
                    <div className="h-20 flex items-end justify-center">
                        <h3 className="text-gray-500 text-3xl tracking-wide">Drive<span
                            className="text-blue-600">Book</span>
                        </h3>
                        <img src={drivePro} alt="" className="h-16 w-24 object-contain"/>
                        {/*<svg className="h-12 w-16" viewBox="0 0 1024 1024" version="1.1"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M766.976 508.736c80.576 0 152.448 32.128 199.232 82.176" fill="#AEBCC3"/>
                            <path
                                d="M64.704 684.992c10.816 19.2 32.064 32.192 56.576 32.192h784.64c35.84 0 64.832-27.648 64.832-61.76v-17.408h-36.608a15.744 15.744 0 0 1-16.064-15.296V550.912a277.568 277.568 0 0 0-150.144-44.16h1.6l-55.04-0.256c-53.632-115.2-157.504-210.752-294.208-210.752-136.512 0-251.008 89.728-282.176 210.688h-16.832c-35.456 0-56.128 27.392-56.128 61.184"
                                fill="#3B82F6"/>
                            <path
                                d="M64.704 654.464h13.76a39.168 39.168 0 0 0 40.064-38.272v-17.6c0-21.12-17.92-38.208-40.064-38.208h-13.376"
                                fill="#71717A"/>
                            <path d="M160 684.992a101.632 96.832 0 1 0 203.264 0 101.632 96.832 0 1 0-203.264 0Z"
                                  fill="#71717A"/>
                            <path d="M218.88 684.992a42.752 40.768 0 1 0 85.504 0 42.752 40.768 0 1 0-85.504 0Z"
                                  fill="#FFFFFF"/>
                            <path
                                d="M652.032 684.992a101.568 96.832 0 1 0 203.136 0 101.568 96.832 0 1 0-203.136 0Z"
                                fill="#71717A"/>
                            <path d="M710.912 684.992a42.752 40.768 0 1 0 85.504 0 42.752 40.768 0 1 0-85.504 0Z"
                                  fill="#FFFFFF"/>
                            <path
                                d="M966.272 591.104v-0.192a257.92 257.92 0 0 0-48.192-40V622.72c0 8.448 7.232 15.296 16.064 15.296h36.608v-42.304l-4.48-4.608z"
                                fill="#71717A"/>
                            <path
                                d="M405.568 335.616c-104.896 6.336-191.296 76.8-216.64 170.816h216.64V335.616zM445.696 506.432h216.64c-41.216-86.848-117.12-159.616-216.64-170.048v170.048z"
                                fill="#FFFFFF"/>
                        </svg>*/}
                    </div>
                    <div>
                        <ul className="p-4 space-y-1">
                            <div className="space-y-1">
                                {
                                    sidebarOptions.map((option) => {
                                        return (
                                            <Link
                                                className={selectedTab === option.routePath.toLowerCase() ? "flex items-center gap-3 bg-gray-200 rounded-md h-12 px-4 hover:bg-gray-200 text-gray-600 font-semibold transition-all text-sm" : "flex items-center gap-3 rounded-md h-12 px-4 hover:bg-gray-200 text-gray-500 font-semibold hover:text-gray-600 text-sm"}
                                                to={option.routePath}
                                                onClick={event => handleTabClick(option.routePath, event)}
                                                key={option.routePath}
                                            >
                                                {renderIcon(option.iconName)}
                                                {option.routeName}
                                            </Link>)
                                    })
                                }
                            </div>
                            <div className="space-y-1">
                                <Link
                                    className={selectedTab === 'notifications' ? "flex items-center gap-3 bg-gray-200 rounded-md h-12 px-4 hover:bg-gray-200 text-gray-600 font-semibold transition-all text-sm" : "flex items-center gap-3 rounded-md h-12 px-4 hover:bg-gray-200 text-gray-500 font-semibold hover:text-gray-600 text-sm"}
                                    to={'notifications'}
                                    onClick={event => handleTabClick('notifications', event)}
                                    key={'notifications'}
                                >
                                    {renderIcon('notification')}
                                    Notifications
                                </Link>
                            </div>
                        </ul>
                    </div>
                </nav>
                <div className="h-16 w-full bg-gray-100 fixed left-0 bottom-0 z-40 md:hidden grid grid-cols-4 divide-x">

                    <Link to={'home'}
                          className={`flex flex-col items-center justify-center ${selectedTab === 'home' ? 'bg-blue-400' : ''}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                             stroke="currentColor"
                             className={`w-7 h-7 ${selectedTab === 'home' ? 'stroke-white' : 'stroke-gray-500'}`}>
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"/>
                        </svg>
                        {/*<h6 className="text-sm text-gray-600">Dashboard</h6>*/}
                    </Link>

                    <Link to={'appointment'}
                          className={`flex flex-col items-center justify-center ${selectedTab === 'appointment' ? 'bg-blue-400' : ''}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                             stroke="currentColor"
                             className={`w-7 h-7 ${selectedTab === 'appointment' ? 'stroke-white' : 'stroke-gray-500'}`}>
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"/>
                        </svg>
                        {/*<h6 className="text-sm text-gray-600">Dashboard</h6>*/}
                    </Link>


                    <Link
                        to={userType !== 'instructor' ? userType === 'school' ? 'lesson-plans' : 'lesson-plan' : 'my-students'}
                        className={`flex flex-col items-center justify-center ${selectedTab === (userType !== 'instructor' ? userType === 'school' ? 'lesson-plans' : 'lesson-plan' : 'my-students') ? 'bg-blue-400' : ''}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                             stroke="currentColor"
                             className={`w-7 h-7 ${selectedTab === (userType !== 'instructor' ? userType === 'school' ? 'lesson-plans' : 'lesson-plan' : 'my-students') ? 'stroke-white' : 'stroke-gray-500'}`}>
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"/>
                        </svg>
                        {/*<h6 className="text-sm text-gray-600">Dashboard</h6>*/}
                    </Link>

                    <div className="flex flex-col items-center justify-center" onClick={() => {
                        setIsSideBar(!isSideBar)
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                             stroke="currentColor" className="w-7 h-7 stroke-gray-500">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/>
                        </svg>
                        {/*<h6 className="text-sm text-gray-600">Menu</h6>*/}
                    </div>
                </div>
            </div>
        </>
    );
}
