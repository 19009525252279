//Libraries
import React, {useEffect, useState} from "react";

//Components
import Modal from "../../../components/modal/Modal";
import AlertModalBody from "../../../components/modal/AlertModalBody";

//Constants
import {RemoveUserSvg} from "../../../constants";

//Services
import {cancelRequest} from "../../../services/schoolService";

//Helpers
import {checkScreenSize} from "../../../helpers/screenSize";

//Styles
import './instructors.css'

const PendingRequests = ({requests, setRequests, setAllInstructors, allInstructors}) => {
    const [isMobile, setIsMobile] = useState(false);
    const [selectedInstructorId, setSelectedInstructorId] = useState(null)
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(checkScreenSize(768));
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const openModal = (instructor_id) => {
        setSelectedInstructorId(instructor_id)
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const CancelRequestModalComponent = () => {
        return (<AlertModalBody message={'Are you sure you want to cancel request?'}/>)
    }

    const cancelInstructorRequest = async () => {
        let body = {
            instructor_id: selectedInstructorId
        }
        await cancelRequest(body);
        const updatedRequests = requests.filter(
            request => request._id !== selectedInstructorId
        );
        const updatedAllInstructors = requests.find((instructor) => instructor._id === selectedInstructorId)
        setAllInstructors([...allInstructors, updatedAllInstructors])
        setRequests(updatedRequests);
        closeModal();
    }

    return (
        <div>
            {!isMobile ?
                <div className="border rounded-md overflow-hidden">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                        <tr className="bg-gray-50">
                            <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                                Name
                            </td>
                            <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                Email
                            </td>
                            <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                Location
                            </td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody id="table-body" className="divide-y divide-gray-200">
                        {requests && requests?.length === 0 ?
                            <>
                                <tr>
                                    <td colSpan="4" className="px-6 py-3 text-center text-red-500">
                                        No Pending Requests.
                                    </td>
                                </tr>
                            </> :
                            requests && requests.map((instructor, index) => {
                                return (
                                    <tr>
                                        <td key={instructor.email}
                                            className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                                            {instructor.name}
                                        </td>
                                        <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                            {instructor.email}
                                        </td>
                                        <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                            {instructor.city} - {instructor.state} - {instructor.country}
                                        </td>
                                        <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                            <div className="flex justify-end">
                                                <svg onClick={e => openModal(instructor._id)}
                                                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                     strokeWidth="1.5"
                                                     stroke="currentColor"
                                                     className="w-6 h-6 stroke-red-600 cursor-pointer">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          d="M22 10.5h-6m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"/>
                                                </svg>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                :
                <div>
                    {requests && requests.map((instructor, index) => {
                        return (
                            <div className="border rounded-md p-4">
                                <div className="flex justify-between">
                                    <div className="space-y-0.5">
                                        <h5 className="leading-none text-base text-gray-500 font-semibold">{instructor.name}</h5>
                                        <p className="text-sm text-gray-500">{instructor.email}</p>
                                        <p className="text-xs text-gray-500">{instructor.city} - {instructor.state} - {instructor.country}</p>
                                    </div>
                                    <div className="">
                                        <button
                                            onClick={e => openModal(instructor._id)}
                                            className="deactivate-user-btn">
                                            Cancel Request
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>}
            {
                modalOpen && (
                    <Modal
                        isOpen={true}
                        closeModal={closeModal}
                        title={'Cancel Request'}
                        component={CancelRequestModalComponent}
                        showComponent={true}
                        onConfirmClick={cancelInstructorRequest}
                    />
                )
            }
        </div>
    )
}

export default PendingRequests;