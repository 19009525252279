import React, { useEffect, useState } from 'react'
import GeneralInfo from "./generalInfo";
import Invoices from "./invoices";

import Instructors from "./instructors";
import { useLocation, useParams } from 'react-router-dom';

import { getInstructors, getSchool, getStudentCreditHistory, getTransactionStudent } from '../../../services/studentService';
import CreditHistory from './creditHistory';
const SchoolInfo = () => {
    const location = useLocation();
    const params = useParams();
    const studentSubTabs = ['General Info', 'Instructors', 'Invoices', 'Credit History'];
    const [subStudentTab, SetSubStudentTab] = useState('General Info');
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const storedTab = searchParams.get('schoolDetailTab');
        if (!storedTab) updateQueryParam(subStudentTab);
    }, [])
    const handleTabClick = (tabId, event) => {
        event.preventDefault();
        SetSubStudentTab(tabId);
        updateQueryParam(tabId);
    };
    const updateQueryParam = (tabId) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('schoolDetailTab', tabId);
        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        window.history.pushState({ path: newUrl }, '', newUrl);
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const storedTab = searchParams.get('schoolDetailTab');
        if (storedTab) {
            SetSubStudentTab(storedTab);
        }
    }, [location.search]);

    //data
    const [instructorsData, setInstructorsData] = useState([]);
    useEffect(() => {
        const fetchInstructor = async () => {
            try {
                const instructors = await getInstructors();
                setInstructorsData(instructors?.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchInstructor();
    }, [])

    const [schoolData, setSchoolData] = useState([]);
    useEffect(() => {
        const fetchInstructor = async () => {
            try {
                const school = await getSchool();
                setSchoolData(school?.data[0]);
            } catch (error) {
                console.log(error);
            }
        };
        fetchInstructor();
    }, [])


    const [studentTransaction, setStudentTransaction] = useState([]);
    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                const studentTransactionResponse = await getTransactionStudent();
                setStudentTransaction(studentTransactionResponse);
            } catch (error) {
                console.log(error);
            }
        };
        fetchTransactions();
    }, [])


    const [creditHistoryData, setCreditHistoryData] = useState([]);
    useEffect(() => {
        const fetchCreditHistory = async () => {
            try {
                const creditHistoryResponse = await getStudentCreditHistory();
                setCreditHistoryData(creditHistoryResponse);
            } catch (error) {
                console.log(error);
            }
        };
        fetchCreditHistory();
    }, [])



    return (
        <>
            <div className="space-y-4">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                        <h3 className="text-gray-600 lg:text-3xl text-2xl font-semibold">My School</h3>
                    </div>
                </div>
                <div className="flex flex-wrap items-center border-b">
                    {
                        studentSubTabs.map((item, key) => (
                            <a href="#myStudents" key={key}
                                className={subStudentTab === item ?
                                    'border-b-2 border-b-blue-500 px-4 py-3 flex items-center gap-1 font-semibold md:text-sm text-xs text-blue-500 whitespace-nowrap' :
                                    'px-4 py-3 flex items-center gap-1 text-gray-400 font-semibold md:text-sm text-xs whitespace-nowrap'}
                                onClick={(event) => handleTabClick(item, event)}
                            >
                                <span>{item}</span>
                            </a>
                        ))}
                </div>
                <div id="General Info" style={{ display: subStudentTab === 'General Info' ? 'block' : 'none' }}>
                    <GeneralInfo
                        schoolData={schoolData}
                        schoolId={params['Id']}
                    />
                </div>
                <div id="Instructors" style={{ display: subStudentTab === 'Instructors' ? 'block' : 'none' }}>
                    <Instructors
                        instructorsData={instructorsData}
                        schoolId={params['Id']}
                    />
                </div>
                <div id="Invoices" style={{ display: subStudentTab === 'Invoices' ? 'block' : 'none' }}>
                    <Invoices
                        studentTransactions={studentTransaction}
                        schoolId={params['Id']}
                    />
                </div>
                <div id="Credit History" style={{ display: subStudentTab === 'Credit History' ? 'block' : 'none' }}>
                    <CreditHistory
                        CreditHistoryData={creditHistoryData}
                        schoolId={params['Id']}
                    />
                </div>
            </div>
        </>
    )
}

export default SchoolInfo