//Libraries
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { PDFDownloadLink } from "@react-pdf/renderer";

//Pages
import DesktopLessonDesign from "./lesson-desktop-view";
import MobileLessonDesign from "./lesson-mobile-view";
import { LessonPlanDocument } from "./lessonPlanPDF";

//Components
import SignaturePad from "../../../../../components/signature/signature";
import Modal from "../../../../../components/modal/Modal";

//Services
import { SignLesson, getAllClassTypes, getQuestionaireOnId } from "../../../../../services/instructorService";

//Helpers
import { checkScreenSize } from "../../../../../helpers/screenSize";

//Constants
import PdfSvg from '../../../../../assets/Pdf.svg'
import { getLessonByUserId } from "../../../../../services/instructorService";

const LessonPlan = (props) => {
    const params = useParams();

    const [ActiveLesson, setActiveLession] = useState(null);
    const [questionRating, setQuestionRating] = useState({});
    const [instructorDate, setInstructorDate] = useState();
    const [stars, setStars] = useState([1, 2, 3, 4, 5]);
    const handleChangeRatingInput = (_id, e) => {

        setQuestionRating(prev => ({ ...prev, [_id]: e }));
    }
    const [lessonPlan, setLessonPlan] = useState({
        classes: []
    });
    const [isMobile, setIsMobile] = useState(false);
    const [classTypeList, setClassTypeList] = useState([]);
    const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);
    const [selectedClass, setSelectedClass] = useState({});
    const sigCanvasRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(checkScreenSize(768));
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const [questionaireData, setQuestionaireData] = useState();
    useEffect(() => {
        const fetchQuestionaire = async () => {
            try {
                const questionaire = await getQuestionaireOnId(ActiveLesson?.class_id);
                setQuestionaireData(questionaire);
            } catch (error) {
                console.log(error);
            }
        };
        fetchQuestionaire();
    }, [ActiveLesson])


    useEffect(() => {
        const fetchLesson = async () => {
            try {
                const lessonPlan = await getLessonByUserId(params['Id']);
                if (Object.keys(lessonPlan).length > 0)
                    setLessonPlan(lessonPlan);
            } catch (error) {
                console.log(error);
            }
        };
        fetchLesson();
    }, [])

    useEffect(() => {
        const fetchClassTypes = async () => {
            try {
                const classTypes = await getAllClassTypes(params['Id']);
                console.log("sdfsdf",classTypes)
                setClassTypeList(classTypes);
            } catch (error) {
                console.log(error);
            }
        };
        fetchClassTypes();
    }, [])

    const signaturePadComponent = () => {

        return <SignaturePad
            topComponent={
                <>
                    <div className="space-y-1">
                        <label className="text-sm font-semibold text-gray-600">Student Name: </label>
                        <span className="text-gray-600 text-base">{lessonPlan?.student_name} </span>
                    </div>
                    <div className="space-y-1">
                        <label className="form-label">Select Date:</label>
                        <input type="date" className="form-input w-full" value={instructorDate} onChange={(e) => {
                            setInstructorDate(e.target.value)
                        }} />
                    </div>
                </>
            }
            questionareComponent={
                <>
                    {
                        questionaireData && questionaireData.length === 0 ? "" :
                            <>

                                <div className="space-y-1">
                                    <label className="text-sm font-semibold text-gray-600">Feedback: </label>
                                    <ul className="px-2 space-y-2">
                                        {questionaireData && questionaireData.map((item, key) => {
                                            return (
                                                <li key={key} className="flex items-center justify-between">
                                                    <div className="text-sm">{key + 1}. {item.question} </div>
                                                    <div className="flex items-center gap-0.5">
                                                        {
                                                            stars.map((item1, key1) => (
                                                                <svg key={key1} xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none" viewBox="0 0 24 24" strokeWidth="1"
                                                                    stroke="currentColor"
                                                                    className={`w-5 h-5 cursor-pointer ${(questionRating[item.question] > key1) ? 'fill-yellow-600 stroke-white' : ''}`}
                                                                    onClick={(e) => handleChangeRatingInput(item.question, item1)}>
                                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                                        d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                                                                </svg>
                                                            ))
                                                        }
                                                    </div>
                                                </li>
                                            )
                                        })}

                                    </ul>
                                </div>
                            </>
                    }
                </>
            }
            sigCanvasRef={sigCanvasRef}
        />
    }

    const saveSignature = async () => {
        if (!sigCanvasRef.current.isEmpty()) {
            const signatureImage = sigCanvasRef.current.toDataURL();
            const body = {
                student_lesson_class_id: selectedClass._id,
                instructor_signature: signatureImage,
                questionRating: questionRating,
                instructor_signature_date: instructorDate
            }
            if (await SignLesson(lessonPlan._id, body)) {
                const updated_classes = lessonPlan.classes.map((classItem) => {
                    if (classItem._id === selectedClass._id) return {
                        ...classItem,
                        instructor_signature: body.instructor_signature,
                    }
                    else return classItem;
                })
                setLessonPlan({ ...lessonPlan, classes: updated_classes });
            }
        }
        closeModal();
    };
    const closeModal = () => {
        setIsSignatureModalOpen(false);
        setSelectedClass({});
        sigCanvasRef.current.clear();
    }

    return (
        <>
            {
                isSignatureModalOpen &&
                <Modal
                    isOpen={isSignatureModalOpen}
                    closeModal={closeModal}
                    title={ActiveLesson?.class_name}
                    component={signaturePadComponent}
                    showComponent={true}
                    onConfirmClick={saveSignature}
                />
            }
            <div className="space-y-4">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                        <h4 className="text-gray-600 text-2xl font-semibold">Lesson Plan</h4>
                    </div>
                    {
                        Object.keys(lessonPlan).length > 1 ?
                            <PDFDownloadLink
                                document={
                                    <LessonPlanDocument lessonPlan={lessonPlan} />
                                }
                                fileName={`${lessonPlan ? lessonPlan.lesson_id : ''}.pdf`}
                            >
                                {({ blob, url, loading, error }) => (loading ? 'Loading document...' :
                                    <img className="w-8 h-8" src={PdfSvg} alt="pdf-svg" />)}
                            </PDFDownloadLink>
                            : ""
                    }
                </div>
                {!isMobile
                    ?
                    <DesktopLessonDesign
                        setActiveLession={setActiveLession}
                        questionaireData={questionaireData}
                        lessonPlan={lessonPlan}
                        classTypeList={classTypeList}
                        setIsSignatureModalOpen={setIsSignatureModalOpen}
                        setSelectedClass={setSelectedClass}
                    />
                    :
                    <MobileLessonDesign
                        lessonPlan={lessonPlan}
                        classTypeList={classTypeList}
                        setIsSignatureModalOpen={setIsSignatureModalOpen}
                        setSelectedClass={setSelectedClass}
                    />
                }
            </div>
        </>
    )
}

export default LessonPlan;