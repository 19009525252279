//Libraries
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

//Pages
import ClassTypes from "./class-types/classTypes";
import Packages from "./packages/packages";

//Services
import {getClassTypeList} from "../../../services/schoolService";

const ClassesAndPackages = () => {
    const [selectedTab, setSelectedTab] = useState('classTypes');
    const [classTypeList, setClassTypeList] = useState([]);
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const storedTab = searchParams.get('selectedTab');
        if (!storedTab) updateQueryParam(selectedTab);
    }, [])

    const handleTabClick = (tabId, event) => {
        event.preventDefault();
        setSelectedTab(tabId);
        updateQueryParam(tabId);
    };

    const updateQueryParam = (tabId) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('selectedTab', tabId);
        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        window.history.pushState({path: newUrl}, '', newUrl);
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const storedTab = searchParams.get('selectedTab');
        if (storedTab) {
            setSelectedTab(decodeURIComponent(storedTab));
        }

        const fetchClassTypes = async () => {
            try {
                const classTypes = await getClassTypeList();
                setClassTypeList(classTypes);
            } catch (error) {
                console.log(error);
            }
        };
        fetchClassTypes();
    }, [location.search]);

    return <>
        <div className="space-y-4">
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                    <h3 className="text-gray-600 lg:text-3xl text-2xl font-semibold">Classes & Packages</h3>
                </div>
            </div>
            <div className="flex flex-wrap items-center border-b">
                <a href="#classTypes"
                   className={selectedTab === 'classTypes' ?
                       'border-b-2 border-b-blue-500 px-4 py-3 flex items-center gap-1 font-semibold text-sm text-blue-500' :
                       'px-4 py-3 flex items-center gap-1 text-gray-400 font-semibold text-sm'}
                   onClick={(event) => handleTabClick('classTypes', event)}
                >
                    <span>Class Types</span>
                </a>
                <a href="#packages"
                   className={selectedTab === 'packages' ?
                       'border-b-2 border-b-blue-500 px-4 py-3 flex items-center gap-1 font-semibold text-sm text-blue-500' :
                       'px-4 py-3 flex items-center gap-1 text-gray-400 font-semibold text-sm'}
                   onClick={(event) => handleTabClick('packages', event)}
                >
                    <span>Packages</span>
                </a>
            </div>
            <div id="classTypes" style={{display: selectedTab === 'classTypes' ? 'block' : 'none'}}>
                <ClassTypes classTypesList={classTypeList} setClassTypeList={setClassTypeList}/>
            </div>
            <div id="packages" style={{display: selectedTab === 'packages' ? 'block' : 'none'}}>
                <Packages classTypesList={classTypeList}/>
            </div>
        </div>
    </>
}

export default ClassesAndPackages;