import React, {useEffect, useState} from 'react'
import formatDateTime from '../../../../helpers/utils'

const Instructors = (props) => {

    return (
        <div>
            <div className="md:block hidden border rounded-md overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                    <tr className="bg-gray-50">
                        <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                            #
                        </td>
                        <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                            Name
                        </td>
                        <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                            Email
                        </td>
                        <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                            Created At
                        </td>
                        <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                            Status
                        </td>
                    </tr>
                    </thead>
                    <tbody id="table-body"
                           className="divide-y divide-gray-200">
                    {
                        props.instructorsData && props.instructorsData.length === 0 ?
                            <tr>
                                <td colSpan="5" className="px-6 py-3 text-center text-red-500">
                                    No instructor found.
                                </td>
                            </tr>
                            :
                            props.instructorsData && props.instructorsData.map((item, key) => (
                                <tr>
                                    <td
                                        className="px-6 py-4 align-middle whitespace-nowrap text-gray-500 hover:underline cursor-pointer">
                                        {key + 1}
                                    </td>
                                    <td className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                                        {item?.name}
                                    </td>
                                    <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                        {item?.email}
                                    </td>
                                    <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                        {formatDateTime(item?.createdAt)}
                                    </td>
                                    <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                        {item?.is_active ? "Active" : "InActive"}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
            <div className="space-y-4 md:hidden block">
                {
                    props.instructorsData && props.instructorsData.length > 0 && props.instructorsData.map((item) => (
                        <div className="border rounded-md p-3 shadow">
                            <div className="flex flex-col items-center gap-0.5">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                     stroke="currentColor" className="w-10 h-10 stroke-gray-500">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                                <h4 className="text-base text-gray-500 font-semibold"> {item.name}</h4>
                                <p className="text-sm text-gray-500">{item.email}</p>
                                <p className="text-sm text-gray-500">{item.phone}</p>
                                <p className="text-sm text-gray-500">{item.state}</p>
                                <div className={item.is_active ?
                                    'bg-green-500 text-white text-xs px-4 py-1 rounded-full capitalize' :
                                    'bg-red-500 text-white text-xs px-4 py-1 rounded-full capitalize'
                                }>
                                    {item.is_active ? "Active" : "In Active"}
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
export default Instructors