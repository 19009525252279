//Libraries
import React, { useEffect, useState } from "react";
import { ToastContainer } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import jwtDecode from "jwt-decode";

//Pages
import StudentSignupForm from "./studentSignupForm";
import InstructorSignupForm from "./instructorSignupForm";
import drivePro from "../../../assets/drivePro1.jpeg";
import SchoolSignupForm from "../../school/signup/schoolSignupForm";

export default function InvitationRegister() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedUserType, setSelectedUserType] = useState("student");
  const [email_payload, setEmailPayload] = useState({});

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const { email_payload } = jwtDecode(token)
    setEmailPayload(email_payload)
    setSelectedUserType(email_payload.user_type)
  }, [location.search]);
  


  const navigateToSignIn = () =>{
    navigate('/login')
  }

  return (
    <>
      <div className="grid xl:grid-cols-2 min-h-screen bg-white">
        {/* Left Container */}
        <div className="xl:flex hidden flex-col items-center justify-center">
          <div className="">
            <div className="">
              <img src={drivePro} alt="" className="xl:h-28 h-20 xl:w-48 w-36 object-contain"/>
              <h2 className="xl:text-6xl text-5xl text-blue-500">Drive <span
                  className="text-gray-500">Book</span></h2>
            </div>

            <p className="xl:text-2xl text-xl text-gray-500">You're only a few seconds away from
              learning!</p>
          </div>
        </div>

        {/* Right Container */}
        <div className="flex flex-col items-center justify-center shadow-2xl bg-blue-500 py-6 md:px-8 px-4">
          <div className="bg-white shadow-2xl md:min-w-[600px] min-w-full py-6 md:px-8 px-6 rounded-2xl">
            <h3 className="text-gray-500 text-3xl tracking-wide text-center">Register</h3>
            <div className="space-y-4 mt-6">
              <div className="space-y-1">
                <label className="form-label">
                  User Type
                </label>
                <select disabled className="form-select w-full" value={selectedUserType}>
                  <option value="student">Student</option>
                  <option value="instructor">Instructor</option>
                  <option value="school">School</option>
                </select>
              </div>
              {
                selectedUserType === 'STUDENT' ? <StudentSignupForm navigateToSignIn={navigateToSignIn} email_payload={email_payload}/>
                    :
                    <InstructorSignupForm navigateToSignIn={navigateToSignIn} email_payload={email_payload}/>
              }
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}