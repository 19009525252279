//Libraries
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useFormik } from "formik";

//Services
import { createPackage, getClassTypeList, getPackageById, updatePackage } from "../../../../services/schoolService";

//Helpers
import { checkScreenSize } from "../../../../helpers/screenSize";

//Constants
import { BackArrowSvg, CrossSvg, DashSvg, PlusSvg } from "../../../../constants";

//Styles
import './packages.css'
import PackageMobileDesign from "./package-mobile-view";

const Package = () => {
    const routeParams = useParams();
    const navigate = useNavigate();

    const [classTypeList, setClassTypeList] = useState([]);
    const [isMobile, setIsMobile] = useState(false);
    const listingContainerRef = useRef(null);

    const formik = useFormik({
        initialValues: {
            name: '',
            price_before_discount: 0,
            price_after_discount: undefined,
            discount_on_package: 0,
            school_id: localStorage.getItem('id'),
            is_deleted: false,
            is_active: true,
            classes: [
                {
                    class_id: '',
                    quantity: 0,
                    is_unlimited: false
                },
            ]
        },
    });

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(checkScreenSize(768));
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (routeParams.packageId) {
            const fetchPackageById = async () => {
                try {
                    const packageDetails = await getPackageById(routeParams.packageId);
                    formik.setValues(packageDetails);
                } catch (error) {
                    console.log(error);
                }
            };
            fetchPackageById();
        }
    }, [routeParams])

    useEffect(() => {
        const fetchClassTypes = async () => {
            try {
                const classTypes = await getClassTypeList();
                setClassTypeList(classTypes);
            } catch (error) {
                console.log(error);
            }
        };
        fetchClassTypes();
    }, [routeParams])


    useEffect(() => {
        scrollToBottom();
    }, [formik.values.classes]);

    const scrollToBottom = () => {
        if (listingContainerRef.current) {
            listingContainerRef.current.scrollTo({
                top: listingContainerRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }
    };

    const addClass = () => {
        const newClasses = [...formik.values.classes, {
            class_id: '',
            quantity: 0,
            is_unlimited: false
        }];
        formik.setValues({
            ...formik.values,
            classes: newClasses,
        });
    }

    const removeClass = (indexToRemove) => {
        if (formik.values.classes.length > 1) {
            const updatedClasses = formik.values.classes.filter(
                (_, index) => index !== indexToRemove
            );
            const totalPrice = calculateTotalPrice(updatedClasses);
            formik.setValues({
                ...formik.values,
                price_before_discount: totalPrice,
                classes: updatedClasses,
            });
        }
    }

    const packageFormChange = (index, fieldName, inputValue) => {
        const updatedClasses = [...formik.values.classes];
        updatedClasses[index][fieldName] = inputValue;

        if (fieldName === 'is_unlimited' && inputValue) updatedClasses[index].quantity = 32;

        formik.setValues({
            ...formik.values,
            price_before_discount: calculateTotalPrice(updatedClasses),
            credit_student_price: calculateTotalForUnlimitedPrice(updatedClasses),
            classes: updatedClasses,
        });
    }

    const calculateTotalPrice = (classTypesObject) => {
        let totalPrice = 0;

        classTypesObject.forEach((classObj) => {
            const classType = classTypeList.find(
                (classType) => classType._id === classObj.class_id
            );

            if (classType) {
                totalPrice += (classObj.quantity > 0 ? (classObj.quantity * classType.price) : 0);
            }
        });

        return totalPrice;
    }
    const calculateTotalForUnlimitedPrice = (classTypesObject) => {
        let totalPrice = 0;

        classTypesObject.forEach((classObj) => {
            const classType = classTypeList.find(
                (classType) => classType._id === classObj.class_id
            );

            if (classType) {
                totalPrice += (classObj.quantity > 0 ? (classObj.is_unlimited ? 0 : (classObj.quantity * classType.price)) : 0);
            }
        });

        return totalPrice;
    }


    const isPackageFormValid = () => {
        let isValid = true;

        if (formik.values.name === ''
            || formik.values.classes.find((classType) => classType.class_id === '' || classType.quantity < 1)) isValid = false;

        return isValid;
    }

    const savePackage = async (e) => {
        e.preventDefault();
        if (isPackageFormValid()) {
            let totalPrice = 0;

            if (formik.values.discount_on_package > 0) {
                totalPrice = formik.values.price_before_discount - (formik.values.discount_on_package * formik.values.price_before_discount) / 100;
            }
            else {
                totalPrice = formik.values.price_before_discount;
            }

            const body = {
                ...formik.values,
                price_after_discount: totalPrice,
            }

            if (!routeParams.packageId) {
                await createPackage(body);
                navigateBackToPackages();
            } else {
                await updatePackage(routeParams.packageId, body);
                navigateBackToPackages();
            }
        }
    }

    const navigateBackToPackages = () => {
        navigate('/school/classes-and-packages?selectedTab=packages');
    }

    return (
        <div className="space-y-4">
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                    <div
                        onClick={navigateBackToPackages}
                        className="h-10 w-10 border rounded-md flex items-center justify-center hover:bg-gray-50 duration-300 cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                            stroke="currentColor" className="w-5 h-5 stroke-gray-500">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                        </svg>
                    </div>
                    <h3 className="text-gray-600 lg:text-3xl text-2xl font-semibold">{routeParams.packageId ? 'Edit' : 'Create'} Package</h3>
                </div>
            </div>
            {!isMobile ?
                <form className="space-y-4">
                    <div className="flex items-center justify-between">
                        <input
                            id="name"
                            name="name"
                            type="text"
                            key='name'
                            placeholder="Enter Package Name"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            className="form-input w-1/3"
                        />
                        <button className="btn btn-success" onClick={savePackage}>
                            {routeParams.packageId ? 'Update' : 'Create'}
                            <svg className="w-4 h-4 fill-white" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd"
                                    d="M18.1716 1C18.702 1 19.2107 1.21071 19.5858 1.58579L22.4142 4.41421C22.7893 4.78929 23 5.29799 23 5.82843V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H18.1716ZM4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21L5 21L5 15C5 13.3431 6.34315 12 8 12L16 12C17.6569 12 19 13.3431 19 15V21H20C20.5523 21 21 20.5523 21 20V6.82843C21 6.29799 20.7893 5.78929 20.4142 5.41421L18.5858 3.58579C18.2107 3.21071 17.702 3 17.1716 3H17V5C17 6.65685 15.6569 8 14 8H10C8.34315 8 7 6.65685 7 5V3H4ZM17 21V15C17 14.4477 16.5523 14 16 14L8 14C7.44772 14 7 14.4477 7 15L7 21L17 21ZM9 3H15V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V3Z" />
                            </svg>
                        </button>
                    </div>
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-9 border rounded-md overflow-hidden h-fit shadow">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead>
                                    <tr className="bg-gray-50">
                                        <td className="px-6 py-3 text-left text-xs font-semibold text-gray-500">
                                            Class Type
                                        </td>
                                        <td className="px-6 py-3 text-left text-xs font-semibold text-gray-500">
                                            No. of Classes
                                        </td>
                                        <td className="px-6 py-3 text-left text-xs font-semibold text-gray-500">
                                            Unlimited
                                        </td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody id="table-body" ref={listingContainerRef}
                                    className="divide-y divide-gray-200">
                                    {
                                        formik.values.classes.map((classType, index) =>
                                            <tr key={`row-${index}`}>
                                                <td key={`class_id_${index}`}
                                                    className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                                                    <select
                                                        id='class_id'
                                                        name='class_id'
                                                        className="form-select w-52"
                                                        onChange={event => packageFormChange(index, event.target.name, event.target.value)}
                                                        value={classType.class_id}
                                                    >
                                                        <option disabled value="">
                                                            - Select Class Type -
                                                        </option>
                                                        {
                                                            classTypeList.map((classTypeItem) => <option
                                                                key={classTypeItem._id}
                                                                value={classTypeItem._id}>{classTypeItem.name}</option>)
                                                        }
                                                    </select>
                                                </td>
                                                <td key={`quantity_${index}`}
                                                    className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                                                    <input
                                                        id="quantity"
                                                        name="quantity"
                                                        type="number"
                                                        onChange={event => packageFormChange(index, event.target.name, parseInt(event.target.value))}
                                                        value={classType.quantity}
                                                        className="form-select w-28"
                                                    />
                                                </td>
                                                <td key={`is_unlimited_${index}`}
                                                    className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                                                    <input
                                                        id="is_unlimited"
                                                        name="is_unlimited"
                                                        type="checkbox"
                                                        checked={classType.is_unlimited}
                                                        value={classType.is_unlimited}
                                                        onChange={event => packageFormChange(index, event.target.name, event.target.checked)}
                                                    />
                                                </td>
                                                <td key={`remove_class_${index}`}
                                                    className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                                                    <div className="flex justify-end gap-8">
                                                        <svg onClick={() => removeClass(index)}
                                                            xmlns="http://www.w3.org/2000/svg" fill="none"
                                                            viewBox="0 0 24 24"
                                                            strokeWidth="1.5"
                                                            stroke="currentColor"
                                                            className="w-6 h-6 stroke-red-600 cursor-pointer">
                                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                        </svg>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    <tr>
                                        <td colSpan="5" className="px-6 py-3">
                                            <div className="flex items-center justify-center">
                                                <div onClick={addClass}
                                                    className="btn btn-primary btn-sm cursor-pointer">
                                                    Add
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                        strokeWidth="2" stroke="currentColor"
                                                        className="w-4 h-4 stroke-white">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                            d="M12 4.5v15m7.5-7.5h-15" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-span-3 border rounded-md h-fit shadow-md">
                            <div className="grid grid-cols-2 items-center p-3 border-b">
                                <label className="form-label">Original Price:</label>
                                <div className="text-right font-semibold text-gray-500 tracking-wider">
                                    <span>Kr</span>
                                    <span>{parseFloat(formik.values.price_before_discount).toFixed(2)}</span>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 items-center px-3 py-1 border-b">
                                <label className="form-label">
                                    Discount:
                                </label>
                                <div className="relative">
                                    <input
                                        id="discount"
                                        name="discount_on_package"
                                        type="number"
                                        key={`discount`}
                                        onChange={formik.handleChange}
                                        value={formik.values.discount_on_package}
                                        className="form-input w-full"
                                    />
                                    <div
                                        className="flex items-center justify-center absolute top-0 right-0 rounded-r bg-gray-200 h-full w-10 text-gray-500 font-semibold">%
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 items-center p-3 bg-gray-50 rounded-b-md">
                                <label className="form-label">Total Price:</label>
                                <div className="text-right font-semibold text-gray-500 tracking-wider">
                                    <span>Kr</span>
                                    <span>{parseFloat(formik.values.price_before_discount - (formik.values.discount_on_package * formik.values.price_before_discount) / 100).toFixed(2)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                :
                <PackageMobileDesign
                    formik={formik}
                    classTypeList={classTypeList}
                    packageFormChange={packageFormChange}
                    addClass={addClass}
                    removeClass={removeClass}
                />}
        </div>
    )
}

export default Package;