import React, { useState } from "react";
import drivePro from '../../../assets/drivePro1.jpeg';
import Theory from './theory';
import TheoryLesson from "./theoryLesson";

const TheoryIndex = () => {
    const theoryTabs = ['Theory Teaching (Car)', 'Theory Teaching (Bike)', 'Theory Lesson'];
    const [currentTheoryTab, setCurrentTheoryTab] = useState('Theory Teaching (Car)');
    const handleTabClick = (tabId, event) => {
        event.preventDefault();
        setCurrentTheoryTab(tabId);
        setCurrentTheoryTab(tabId);
    };
    return (
        <>
            <div className="space-y-4">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                        <h3 className="text-gray-600 lg:text-3xl text-2xl font-semibold">Theory</h3>
                    </div>
                </div>
                <div className="flex flex-wrap items-center border-b">
                    {
                        theoryTabs.map((item, key) => (
                            <a href="#myStudents" key={key}
                                className={currentTheoryTab === item ?
                                    'border-b-2 border-b-blue-500 px-4 py-3 flex items-center gap-1 font-semibold md:text-sm text-xs text-blue-500 whitespace-nowrap' :
                                    'px-4 py-3 flex items-center gap-1 text-gray-400 font-semibold md:text-sm text-xs whitespace-nowrap'}
                                onClick={(event) => handleTabClick(item, event)}
                            >
                                <span>{item}</span>
                            </a>
                        ))}
                </div>
                <div id="Theory Teaching (Car)" style={{ display: currentTheoryTab === 'Theory Teaching (Car)' ? 'block' : 'none' }}>
                    <Theory type='car' />
                </div>
                <div id="Theory Teaching (Bike)" style={{ display: currentTheoryTab === 'Theory Teaching (Bike)' ? 'block' : 'none' }}>
                    <Theory type='bike' />
                </div>
                <div id="Theory Lesson" style={{ display: currentTheoryTab === 'Theory Lesson' ? 'block' : 'none' }}>
                    <TheoryLesson />
                </div>
            </div>
        </>
    )
}

export default TheoryIndex;