//Libraries
import React, {useEffect, useState} from "react";

//Components
import Modal from "../../../components/modal/Modal";
import AlertModalBody from "../../../components/modal/AlertModalBody";


//Constants
import {BookSvg, CrossSvg, DollarSvg, InviteSvg, RemoveUserSvg} from "../../../constants";

//Helpers
import {checkScreenSize} from "../../../helpers/screenSize";

//Services
import {getSchoolStudents, removeStudentFromSchool, sendInvitationToStudent} from "../../../services/schoolService";

//Styles
import './../../school/students/students.css';
import {useNavigate} from "react-router-dom";
import {getInstructorStudents} from "../../../services/instructorService";


const MyStudents = (props) => {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false);

    const [myStudents, setMyStudents] = useState([]);

    const [filteredUsers, setFilteredUsers] = useState([])
    const [searchUser, setSearchUser] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(checkScreenSize(768));
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchMyStudents = async () => {
            try {
                const myStudentData = await getInstructorStudents();
                setMyStudents(myStudentData);
            } catch (error) {
                console.log(error);
            }
        };
        fetchMyStudents();
    }, []);


    useEffect(() => {
        if (searchUser === '') {
            setFilteredUsers(myStudents)
        } else {
            const filteredData = myStudents.filter((user) => {
                const name = user.student_name.toLowerCase();
                const email = user.student_email.toLowerCase();
                const searchValue = searchUser.toLowerCase();
                return name.includes(searchValue) || email.includes(searchValue)
            });
            setFilteredUsers(filteredData)
        }
    }, [searchUser, myStudents])


    return (
        <div className="space-y-4">
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                    <h3 className="text-gray-600 lg:text-3xl text-2xl font-semibold">Students</h3>
                </div>
            </div>
            {!isMobile ?
                <div className="space-y-4">
                    <div className="flex items-center justify-between">
                        <div className="relative">
                            <input
                                id="search"
                                name="search"
                                type="text"
                                placeholder="Search by student name..."
                                onChange={event => setSearchUser(event.target.value)}
                                value={searchUser}
                                className="form-input-icon w-96"
                            />
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                 stroke="currentColor"
                                 className="w-5 h-5 stroke-gray-500 absolute inset-0 top-2.5 left-2">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                            </svg>
                        </div>
                    </div>
                    <div className="border rounded-md overflow-hidden">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead>
                            <tr className="bg-gray-50">
                                <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                                    Name
                                </td>
                                <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                                    Email
                                </td>
                                <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                                    School Name
                                </td>
                            </tr>
                            </thead>
                            <tbody key={filteredUsers && filteredUsers.length} id="table-body"
                                   className="divide-y divide-gray-200">
                            {filteredUsers && filteredUsers.map((student, index) => {
                                return (
                                    // <tr>
                                    //     <td colSpan="4" className="px-6 py-3 text-center text-red-500">No Instructor Found.</td>
                                    // </tr>
                                    <tr>
                                        <td key={index}
                                            onClick={(event) => {
                                                navigate(`/instructor/my-students/${student._id}?studentDetailTab=General Info`)
                                            }}
                                            className="px-6 py-4 align-middle whitespace-nowrap text-gray-500 cursor-pointer hover:underline">
                                            {student.student_name}
                                        </td>
                                        <td className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                                            {student.student_email}
                                        </td>
                                        <td className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                                            {student.school_name}
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
                :
                <div className="space-y-4">
                    <div className="flex items-center justify-between gap-4">
                        <div className="relative w-full">
                            <input
                                id="search"
                                name="search"
                                type="text"
                                placeholder="Search by student name..."
                                onChange={event => setSearchUser(event.target.value)}
                                value={searchUser}
                                className="form-input-icon w-full"
                            />
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 strokeWidth="1.5"
                                 stroke="currentColor"
                                 className="w-5 h-5 stroke-gray-500 absolute inset-0 top-2.5 left-2">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                            </svg>
                        </div>
                    </div>
                    {filteredUsers && filteredUsers.map((student, index) => {
                        return (
                            <div className="border rounded-md p-4" key={index}>
                                <div className="flex justify-between">
                                    <div className="space-y-0.5">
                                        <h5 onClick={(event) => {
                                            navigate(`/instructor/my-students/${student._id}?studentDetailTab=General Info`)
                                        }}
                                            className="leading-none text-base text-gray-500 font-semibold underline">{student.student_name}</h5>
                                        <p className="text-sm text-gray-500">{student.student_email}</p>
                                        <p className="text-xs text-gray-500">{student.school_name}</p>
                                    </div>
                                    <div className="flex flex-col gap-1">

                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            }
        </div>
    )
}

export default MyStudents;