//Libraries
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

//Services
import { getLatestNotifications, setNotificationViewed } from '../../services/userService';

//Helpers
import { ColorTypes, NotificationAlertSvg, NotificationListingIcons } from '../../constants';

export default function NotificationDropdown() {
    const [isOpen, setIsOpen] = useState(false);
    const [latestNotifications, setLatestNotifications] = useState([]);
    const [notificationCount, setNotificationCount] = useState([]);
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const fetchLatestNotifications = async () => {
            try {
                const response = await getLatestNotifications();
                setLatestNotifications(response.data);
                if (response.data) {
                    let count = 0;
                    for (let i = 0; i < response.data.length; i++) {
                        let item = response.data[i];
                        if (!item.is_read) {
                            count++;
                        }
                    }
                    setNotificationCount(count);
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchLatestNotifications();
    }, [])

    const handleClickOutside = (event) => {
        if (dropdownRef.current &&
            !dropdownRef.current.contains(event.target) &&
            !buttonRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);

    const toggleUserDropdown = () => {
        setIsOpen(!isOpen);
    };

    const renderIcon = (messageType, status) => {
        let IconName = '';
        let ColorType = '';

        switch (status) {
            case 'ACCEPTED':
                IconName = 'TICK_ICON';
                ColorType = 'SUCCESS';
                break;
            case 'REJECTED':
                IconName = 'CROSS_ICON';
                ColorType = 'ERROR';
                break;
            case 'CANCELLED':
                IconName = 'CROSS_ICON';
                ColorType = 'ERROR';
                break;
            default:
                IconName = messageType;
                ColorType = 'PRIMARY';
                break;
        }
        const IconComponent = NotificationListingIcons[IconName];
        return (<span style={{ color: ColorTypes[ColorType] }}>
            <IconComponent />
        </span>);
    }

    const handleNotificationClick = async (notificationId, isRead) => {
        if (!isRead && await setNotificationViewed(notificationId)) {
            const updatedNotifications = latestNotifications.map((notification) => {
                if (notification._id === notificationId) notification.is_read = true;
                return notification;
            })

            setLatestNotifications(updatedNotifications);
        }
        let count = 0;
        for (let i = 0; i < latestNotifications.length; i++) {
            let item = latestNotifications[i];
            if (!item.is_read) {
                count++;
            }
        }
        setNotificationCount(count);
        setIsOpen(false);
        return;
    }

    const toTitleCase = (str) => {
        return str.replace(/\b\w+/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    return (
        <div className="flex w-full flex-wrap items-stretch relative">
            <div ref={buttonRef} onClick={toggleUserDropdown}
                data-dropdown-toggle="notificationDropdown" className="cursor-pointer">
                {/*<NotificationAlertSvg />*/}
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                    stroke="currentColor" className="md:w-8 md:h-8 h-7 w-7 stroke-blue-600">
                    <path strokeLinecap="round" strokeLinejoin="round"
                        d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5" />
                </svg>
                <span className="absolute md:-top-1 top-0 md:-right-1 right-0 text-xs border rounded-full md:h-5 md:w-5 h-4 w-4 flex items-center justify-center bg-blue-500 text-white">{notificationCount}</span>
            </div>
            {isOpen && (
                <div ref={dropdownRef}
                    className="z-10 absolute top-14 md:-right-40 -right-10 bg-white divide-y divide-gray-900 rounded-lg shadow sm:w-96 w-80">
                    <div className="divide-y divide-gray-250">
                        {latestNotifications.length > 0 && latestNotifications.map((notification) =>
                            notification.route !== "" ? <Link
                                to={notification.route}
                                onClick={() => handleNotificationClick(notification._id, notification.is_read)}
                                className="flex items-center gap-3 px-4 py-2 hover:bg-gray-50 text-gray-500 overflow-hidden"
                                style={{
                                    backgroundColor: notification.is_read ? '' : '#FAFAFA',
                                    color: notification.is_read ? '' : '#3B82F6'
                                }}
                            >
                                {renderIcon(notification.message_type, notification.status)}
                                <div className='overflow-hidden'>
                                    <div className="font-normal text-base whitespace-nowrap overflow-hidden overflow-ellipsis">
                                        {toTitleCase(notification.title)}
                                    </div>
                                    <div className="font-semibold text-xs whitespace-nowrap overflow-hidden overflow-ellipsis">
                                        {notification.sender_name} {notification.message}
                                    </div>
                                </div>
                            </Link> : <Link
                                onClick={() => handleNotificationClick(notification._id, notification.is_read)}
                                className="flex items-center gap-3 px-4 py-2 hover:bg-gray-50 text-gray-500 overflow-hidden"
                                style={{
                                    backgroundColor: notification.is_read ? '' : '#FAFAFA',
                                    color: notification.is_read ? '' : '#3B82F6'
                                }}
                            >
                                {renderIcon(notification.message_type, notification.status)}
                                <div className='overflow-hidden'>
                                    <div className="font-normal text-base whitespace-nowrap overflow-hidden overflow-ellipsis">
                                        {toTitleCase(notification.title)}
                                    </div>
                                    <div className="font-semibold text-xs whitespace-nowrap overflow-hidden overflow-ellipsis">
                                        {notification.sender_name} {notification.message}
                                    </div>
                                </div>
                            </Link>)}
                        <Link
                            onClick={() => setIsOpen(false)}
                            to={'notifications'}
                            className="flex justify-center px-4 py-3 hover:underline text-gray-500 text-sm"
                        >
                            - View All Notifications -
                        </Link>
                    </div>
                </div>
            )}
        </div>
    )
}