//Libraries
import React, {useEffect, useState} from "react";

//Components
import Modal from "../../../components/modal/Modal";
import AlertModalBody from "../../../components/modal/AlertModalBody";

//Services
import {sendRequestToInstructor, sendInvitationToInstructor} from "../../../services/schoolService";

//Styles
import './instructors.css'

//Helpers
import {checkScreenSize} from "../../../helpers/screenSize";

//Constants.
import {AddUserSvg, InviteSvg} from '../../../constants'

const RequestInstructors = ({instructors, setInstructors, requests, setRequests}) => {
    const [isMobile, setIsMobile] = useState(false);
    const [requestModalOpen, setRequestModalOpen] = useState(false);
    const [inviteModalOpen, setInviteModalOpen] = useState(false);
    const [selectedInstructorId, setSelectedInstructorId] = useState(null);
    const [invitationEmail, setInvitationEmail] = useState('');
    const [hr_rate, setHr_rate] = useState();
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [searchUser, setSearchUser] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(checkScreenSize(768));
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleEmailChange = (email) => {
        setInvitationEmail(email);
    };
    const handleHrRate = (rate) => {
        setHr_rate(rate);
    };

    useEffect(() => {
        if (searchUser === '') {
            setFilteredUsers(instructors)
        } else {
            const filteredData = instructors.filter((user) => {
                const name = user.name.toLowerCase();
                const email = user.email.toLowerCase();
                const searchValue = searchUser.toLowerCase();
                return name.includes(searchValue) || email.includes(searchValue)
            });
            setFilteredUsers(filteredData)
        }
    }, [searchUser, instructors])

    const openInviteModal = () => {
        setInviteModalOpen(true);
    }

    const closeInviteModal = () => {
        setInvitationEmail('');
        setHr_rate(0.00)
        setInviteModalOpen(false);
    }

    const openRequestModal = (instructor_id) => {
        setSelectedInstructorId(instructor_id)
        setRequestModalOpen(true);
    };

    const closeRequestModal = () => {
        setRequestModalOpen(false);
    };

    const RequestModalComponent = () => {
        return (<AlertModalBody message={'Are you sure you want to send request?'}/>)
    }

    const sendRequest = async () => {
        let body = {
            instructor_id: selectedInstructorId
        }
        await sendRequestToInstructor(body);
        const updatedInstructors = instructors.filter(
            instructor => instructor._id !== selectedInstructorId
        );
        const updatedRequest = instructors.find((instructor) => instructor._id === selectedInstructorId)
        setRequests([...requests, updatedRequest]);
        setInstructors(updatedInstructors);
        closeRequestModal();
    }

    const sendInvite = () => {
        let body = {
            email: invitationEmail,
            hr_rate: hr_rate
        }
        sendInvitationToInstructor(body)
        closeInviteModal();
    }
    const InviteComponent = () => {
        return (
            <div className="space-y-4">
                <div>
                    <label className="form-label" htmlFor="">Email</label>
                    <input
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Enter your email here..."
                        onChange={event => handleEmailChange(event.target.value)}
                        value={invitationEmail}
                        required
                        className="form-input w-full mt-1"
                    />
                </div>
                <div>
                    <label className="form-label" htmlFor="">Fee</label>
                    <input
                        id="hr_rate"
                        name="hr_rate"
                        type="hr_rate"
                        placeholder="Enter instructor hourly rate..."
                        onChange={event => handleHrRate(event.target.value)}
                        value={hr_rate}
                        required
                        className="form-input w-full mt-1"
                    />
                </div>
            </div>
        )
    }

    return (
        <div>
            {!isMobile ?
                <div className="space-y-4">
                    <div className="flex items-center justify-between">
                        <div className="relative">
                            <input
                                id="search"
                                name="search"
                                type="text"
                                placeholder="Search by user name..."
                                onChange={event => setSearchUser(event.target.value)}
                                value={searchUser}
                                className="form-input-icon w-96"
                            />
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                 stroke="currentColor"
                                 className="w-5 h-5 stroke-gray-500 absolute inset-0 top-2.5 left-2">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                            </svg>
                        </div>
                        <button onClick={openInviteModal} type="button"
                                className="h-10 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm">
                            Invite Via Mail
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2"
                                 stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"/>
                            </svg>
                        </button>
                    </div>
                    <div className="border rounded-md overflow-hidden">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead>
                            <tr className="bg-gray-50">
                                <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                                    Name
                                </td>
                                <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                    Email
                                </td>
                                <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                    Location
                                </td>
                                <td>
                                </td>
                            </tr>
                            </thead>
                            <tbody key={filteredUsers && filteredUsers.length} id="table-body"
                                   className="divide-y divide-gray-200">
                            {filteredUsers && filteredUsers?.length === 0 ?
                                <>
                                    <tr>
                                        <td colSpan="4" className="px-6 py-3 text-center text-red-500">
                                            No Requests Yet.
                                        </td>
                                    </tr>
                                </> :
                                filteredUsers && filteredUsers.map((instructor, index) => {
                                    return (
                                        <tr>
                                            <td key={instructor.email}
                                                className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                                                {instructor.name}
                                            </td>
                                            <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                                {instructor.email}
                                            </td>
                                            <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                                {instructor.city} - {instructor.state} - {instructor.country}
                                            </td>
                                            <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                                <div className="flex justify-end">
                                                    <svg onClick={e => openRequestModal(instructor._id)}
                                                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                         strokeWidth="1.5" stroke="currentColor"
                                                         className="w-6 h-6 stroke-gray-500 cursor-pointer">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"/>
                                                    </svg>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                :
                <div className="space-y-4">
                    <div className="flex items-center justify-between gap-4">
                        <div className="relative w-full">
                            <input
                                id="search"
                                name="search"
                                type="text"
                                placeholder="Search by user name..."
                                onChange={event => setSearchUser(event.target.value)}
                                value={searchUser}
                                className="form-input-icon w-full"
                            />
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                 stroke="currentColor"
                                 className="w-5 h-5 stroke-gray-500 absolute inset-0 top-2.5 left-2">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                            </svg>
                        </div>
                        <button onClick={openInviteModal} className="btn btn-primary">Invite</button>
                    </div>
                    {filteredUsers && filteredUsers.map((instructor, index) => {
                        return (
                            <div className="border rounded-md p-4" key={index}>
                                <div className="flex justify-between">
                                    <div className="space-y-0.5">
                                        <h5 className="leading-none text-base text-gray-500 font-semibold">{instructor.name}</h5>
                                        <p className="text-sm text-gray-500">{instructor.email}</p>
                                        <p className="text-xs text-gray-500">{instructor.city} - {instructor.state} - {instructor.country}</p>
                                    </div>
                                    <div className="">
                                        <button
                                            onClick={e => openRequestModal(instructor._id)}
                                            className="activate-user-btn">
                                            Send Request
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            }
            {requestModalOpen && (
                <Modal
                    isOpen={true}
                    closeModal={closeRequestModal}
                    title={'Send Request'}
                    component={RequestModalComponent}
                    showComponent={true}
                    onConfirmClick={sendRequest}
                />
            )}
            {inviteModalOpen && (
                <Modal
                    isOpen={true}
                    closeModal={closeInviteModal}
                    title={'Send Invite'}
                    message={''}
                    component={InviteComponent}
                    showComponent={true}
                    onConfirmClick={sendInvite}
                />
            )}
        </div>
    )
}

export default RequestInstructors;