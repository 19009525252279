//Libraries
import React from "react";
import { useFormik } from "formik";
import { createClassType } from "../../../../services/schoolService";

import Modal from "../../../../components/modal/Modal";

const AddClassTypeModal = ({ isOpen, setIsOpen, classTypesList, setClassTypeList }) => {

    const formik = useFormik({
        initialValues: {
            name: "",
            price: 0,
            duration: 0,
            // hexCode: '',
        },
        onSubmit: async values => {
            const response = await createClassType(values);
            if (response && response.message) {
                setClassTypeList([...classTypesList, response.message])
                formik.resetForm();
            }
        },
    });

    const AddClassType = () => {
        return (
            <form>
                <div className="space-y-4">
                    <div className="space-y-1">
                        <label className="form-label" htmlFor="grid-password">
                            Name
                        </label>
                        <input
                            id="name"
                            name="name"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            className="form-input w-full"
                            placeholder="Enter name for class type"
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        <div className="space-y-1">
                            <label className="form-label" htmlFor="grid-password">
                                Price
                            </label>
                            <input
                                id="price"
                                name="price"
                                type="number"
                                onChange={formik.handleChange}
                                value={formik.values.price}
                                className="form-input w-full"
                            />
                        </div>
                        <div className="space-y-1">
                            <label className="form-label" htmlFor="grid-password">
                                Duration
                            </label>
                            <input
                                id="duration"
                                name="duration"
                                type="number"
                                onChange={formik.handleChange}
                                value={formik.values.duration}
                                className="form-input w-full"
                            />
                        </div>
                        {/* <div className="space-y-1">
                            <label className="form-label" htmlFor="grid-password">
                                Color
                            </label>
                            <input
                                id="hexCode"
                                name="hexCode"
                                type="color"
                                onChange={formik.handleChange}
                                value={formik.values.hexCode}
                                className="form-input w-full"
                            />
                        </div> */}
                    </div>
                </div>
            </form>
        )
    }

    const createNewClassType = () => {
        // setCreateNewClass(true);
        formik.resetForm();
        formik.handleSubmit();
        closeCreateModal();
    }

    const closeCreateModal = () => {
        setIsOpen(false);
        // setSelectedClassType({});
    }

    return (
        <Modal
            isOpen={isOpen}
            closeModal={closeCreateModal}
            title={'Add Class Type'}
            message={''}
            component={AddClassType}
            showComponent={true}
            onConfirmClick={createNewClassType}
        />
    )
}

export default AddClassTypeModal;