//Libraries
import React from "react";
import {useFormik} from "formik";

//Services
import {schoolRegister} from "../../../services/schoolService";

const SchoolSignupForm = ({navigateToSignIn}) => {
    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            registeration_number: "",
            password: "",
            confirm_password: "",
            phone: "",
            address: "",
            city: "",
            state: "",
            country: "",
        },
        onSubmit: async values => {
            let registerSuccessful = false;
            registerSuccessful = await schoolRegister(values);
            if (registerSuccessful) {
                navigateToSignIn()
            }
        },
    });

    return (
        <form onSubmit={formik.handleSubmit} className="space-y-4">
            <div className="space-y-4">
                <div className="grid md:grid-cols-2 gap-4">
                    <div className="space-y-1">
                        <label
                            className="form-label"
                            htmlFor="grid-password"
                        >
                            Name
                        </label>
                        <input
                            id="name"
                            name="name"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            className="form-input w-full"
                        />
                    </div>
                    <div className="space-y-1">
                        <label
                            className="form-label"
                            htmlFor="grid-password"
                        >
                            Email Address
                        </label>
                        <input
                            id="email"
                            name="email"
                            type="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            className="form-input w-full"
                        />
                    </div>
                </div>
                <div className="grid md:grid-cols-2 gap-4">
                    <div className="space-y-1">
                        <label
                            className="form-label"
                            htmlFor="grid-password"
                        >
                            New Password
                        </label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            className="form-input w-full"
                        />
                    </div>
                    <div className="space-y-1">
                        <label
                            className="form-label"
                            htmlFor="grid-password"
                        >
                            Confirm New Password
                        </label>
                        <input
                            id="confirm_password"
                            name="confirm_password"
                            type="password"
                            onChange={formik.handleChange}
                            value={formik.values.confirm_password}
                            className="form-input w-full"
                        />
                    </div>
                </div>
                <div className="grid md:grid-cols-2 gap-4">
                    <div className="space-y-1">
                        <label
                            className="form-label"
                            htmlFor="grid-password"
                        >
                            Phone
                        </label>
                        <input
                            id="phone"
                            name="phone"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.phone}
                            className="form-input w-full"
                        />
                    </div>
                    <div className="space-y-1">
                        <label
                            className="form-label"
                            htmlFor="grid-password"
                        >
                            Registration Number
                        </label>
                        <input
                            id="registeration_number"
                            name="registeration_number"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.registeration_number}
                            className="form-input w-full"
                        />
                    </div>
                </div>
                <div className="grid md:grid-cols-2 gap-4">
                    <div className="space-y-1">
                        <label
                            className="form-label"
                            htmlFor="grid-password"
                        >
                            Address
                        </label>
                        <input
                            id="address"
                            name="address"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.address}
                            className="form-input w-full"
                        />
                    </div>
                    <div className="space-y-1">
                        <label
                            className="form-label"
                            htmlFor="grid-password"
                        >
                            City
                        </label>
                        <input
                            id="city"
                            name="city"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.city}
                            className="form-input w-full"
                        />
                    </div>
                </div>
                <div className="grid md:grid-cols-2 gap-4">
                    <div className="space-y-1">
                        <label
                            className="form-label"
                            htmlFor="grid-password"
                        >
                            State
                        </label>
                        <input
                            id="state"
                            name="state"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.state}
                            className="form-input w-full"
                        />
                    </div>
                    <div className="space-y-1">
                        <label
                            className="form-label"
                            htmlFor="grid-password"
                        >
                            Country
                        </label>
                        <input
                            id="country"
                            name="country"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.country}
                            className="form-input w-full"
                        />
                    </div>
                </div>
            </div>
            <button
                className="btn btn-primary w-full"
                type="submit"
            >
                Create Account
            </button>
            <div className="text-sm text-gray-500">
                Already have an account? <span onClick={navigateToSignIn}
                                               className="text-blue-500 font-semibold hover:underline cursor-pointer">Sign in</span>
            </div>
        </form>
    )
}

export default SchoolSignupForm