import React, { useEffect, useState } from "react";
import { theoryMotorCylcleList, theoryCarList } from '../../../helpers/theoryTeachingList';
import { Link, useParams } from "react-router-dom";
import image from '../../../assets/drivePro.jpeg'

const TheoryTeaching = (props) => {
    const params = useParams();
    const [currentContent, setCurrentContent] = useState(null);
    const [heading, setHeading] = useState(null);
    useEffect(() => {
        if (params['type'] === 'car') {
            theoryCarList.forEach((item, key) => {
                if (item.id.toString() === params['id']) {
                    setCurrentContent(item.content);
                    setHeading(item.name);
                }
            })
        }
        else if (params['type'] === 'bike') {
            theoryMotorCylcleList.forEach((item, key) => {
                if (item.id.toString() === params['id']) {
                    setCurrentContent(item.content);
                    setHeading(item.name);
                }
            })
        }
    }, [])
    const [userType, setUserType] = useState('student');
    useEffect(() => {
        // Check if the user is already logged in
        const token = localStorage.getItem('accessToken');
        const userType = localStorage.getItem('user_type')
        if (token) {
            setUserType(userType)
        }
    }, []);
    return (
        <>
            <div className="space-y-4">
                <div className="flex items-center gap-4">
                    <Link to={`/${userType}/theory`}><div
                        className="h-10 w-10 border rounded-md flex items-center justify-center hover:bg-gray-50 duration-300 cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                            stroke="currentColor" className="w-5 h-5 stroke-gray-500">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                        </svg>
                    </div></Link>
                    <h3 className="text-gray-600 lg:text-3xl text-2xl font-semibold">{heading}</h3>
                </div>
                <div className="flex justify-center border shadow-sm p-5 font-sans">
                    <div className="space-y-4 text-center md:max-w-[700px] w-full">
                        {
                            currentContent && currentContent.map((item, key) => (
                                <div key={key}>
                                    {item.heading && <h2 className="text-3xl text-gray-600 font-semibold" >{item.heading}</h2>}
                                    {item.subheading && <h4 className="text-xl text-gray-600 font-semibold">{item.subheading}</h4>}
                                    {item.image && <div className="flex items-center justify-center">
                                        <img src={item.image} alt="" className="w-96 h-60 object-contain" />
                                    </div>}
                                    {item.colonLine && <p className="text-gray-500 font-semibold">
                                        {item.colonLine}
                                    </p>}
                                    {item.paragraph && <p className="text-gray-500 font-semibold">
                                        {item.paragraph}
                                    </p>}
                                    {item.space && <br />}
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div >
        </>
        // <>
        //     <div className="space-y-6">
        //         <div className="flex items-center justify-between">
        //             <div className="flex items-center gap-4">
        //                 <h3 className="text-gray-600 lg:text-3xl text-2xl font-semibold">Theory Teaching (Car)</h3>
        //             </div>
        //         </div>
        //         <div>
        //             <div>
        //                 <h3 className="text-base font-semibold text-gray-500">Global</h3>
        //             </div>
        //             <div className="border divide-y bg-white shadow-sm font-sans mt-2">
        //                 <div className="flex items-center justify-between">
        //                     <h4 className="font-sans font-semibold text-gray-600 px-3 py-2">Theory teaching</h4>
        //                     <div
        //                         className="font-sans font-semibold text-sm text-gray-600 px-3 py-2 border-l hover:bg-gray-100 cursor-pointer">
        //                         Traffic Signs
        //                     </div>
        //                 </div>
        //                 <div className="flex items-center justify-between p-3">
        //                     <p className="text-sm text-gray-500 font-semibold">Control Questions for questions test</p>
        //                     <button className="btn btn-success btn-sm">Start</button>
        //                 </div>
        //             </div>
        //         </div>
        //         <div>
        //             <div>
        //                 <h3 className="text-base font-semibold text-gray-500">The Driving School</h3>
        //             </div>
        //             <div className="border divide-y bg-white shadow-sm font-sans mt-2">
        //                 <div className="flex items-center justify-between">
        //                     <h4 className="font-sans font-semibold text-gray-600 px-3 py-2">Theory teaching</h4>
        //                     <div
        //                         className="font-sans font-semibold text-sm text-gray-600 px-3 py-2 border-l hover:bg-gray-100 cursor-pointer">
        //                         Traffic Signs
        //                     </div>
        //                 </div>
        //                 <div className="flex items-center justify-between p-3">
        //                     <p className="text-sm text-gray-500 font-semibold">Control Questions for questions test</p>
        //                     <button className="btn btn-success btn-sm">Start</button>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </>
    )
}

export default TheoryTeaching;