//Libraries
import axios from "axios";

//Components
import notify from '../helpers/raiseToast'

//Helpers
import logout from "../helpers/logout";

//Base Url
const ApiUrl = process.env.REACT_APP_API_URL + '/user';

const axiosInstance = axios.create({
  baseURL: ApiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

//Interceptors to attach tokens dynamically.
//Use axios instance for requests to attach these headers.
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers['x-access-token'] = accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 403) {
      logout();
    }
    return Promise.reject(error);
  }
);

export const searchAccountByEmail = async (body) => {
  try{
    await axios.post(`${ApiUrl}/forget-password`, body);
    notify('SUCCESS', 'top-right', `Password Reset Email Sent! \n Check your email to reset your password.`);
    return true;
  }catch(error){
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.');
    return false;
  }
}

export const updateUserPassword = async (body) => {
  try{
    await axios.put(`${ApiUrl}/update-password`, body);
    notify('SUCCESS', 'top-right', 'Password updated successfully!');
    return true;
  }catch(error){
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.');
    return false;
  }
}

export const getLatestNotifications = async () => {
  try{
    const response = await axiosInstance.get('/notifications/latest');
    return response.data;
  }catch(error){
    return;
  }
}

export const getAllUserNotifications = async () => {
  try{
    const response = await axiosInstance.get('/notifications/all');
    return response.data;
  }catch(error){
    return;
  }
}

export const setNotificationViewed = async (notificationId) => {
  try{
    await axiosInstance.put(`/notification/${notificationId}`);
    return true;
  }catch(error){
    return false;
  }
}