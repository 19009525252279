import { StyleSheet } from "@react-pdf/renderer";

const primaryColor = '#6366f1'
export const mainStyle = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 20,
        paddingRight: 20,
        lineHeight: 1.5,
        flexDirection: 'column',
        color: primaryColor
    },
    logo: {
        width: 30,
        height: 30,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    detailsContainer: {
        flexDirection: 'col',
        gap: '20',
        justifyContent: 'center',
        marginTop: '50'
    },
    details: {
        color: '#1A1110',
        fontSize: 14
    },
    detailsTitle: {
        color: 'black',
        fontSize: 18,
    }
});

export const titleStyle = StyleSheet.create({
    titleContainer: {
        flexDirection: 'column',
        marginTop: 20,
        marginBottom: 10
    },
    title: {
        letterSpacing: 2,
        fontSize: 25,
        textAlign: 'center',
        textTransform: 'uppercase'
    },
    lessonTitle: {
        letterSpacing: 1,
        fontSize: 14,
        textAlign: 'start',
    },
    subTitle:{
        letterSpacing: 1,
        fontSize: 12,
        textAlign: 'center',
        textTransform: 'uppercase'
    }
});

const borderColor = '#e6e6e6'

export const rowStyle = StyleSheet.create({
    container: {
		flexDirection: 'col',
		borderBottomColor: 'black',
		borderBottomWidth: 0.5,
        paddingBottom: '10',
        paddingTop: '10',
        gap: '5',
        color: 'black'
	},
    row: {
        flexDirection: 'row'
    },
    signatureRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    lessonName: {
        fontSize: '14',
        fontWeight: 'extrabold'
    },
    quantity: {
        color: '#5A5A5A'
    },
    signatureBox: {
        border: `1px solid ${borderColor}`,
        borderRadius: '5',
        width: '100',
        height: '30',
        backgroundColor: borderColor,
    },
    date: {
        marginTop: '5px',
        fontSize: '8',
        color: '#5a5a5a'
    },
    key: {},
    value: {
        color: '#5A5A5A'
    }
});