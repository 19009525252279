import React, { useEffect, useState } from "react";
import { getCreditHistoryDataOnUserId, getDashboardDataService } from "../../../services/studentService";
import { useNavigate } from "react-router-dom";
import { calaulateDayAndDate, calculateAgeInTime } from "../../../helpers/utils";

const StudentDashboard = () => {
    const [dashboardData, setDashboardData] = useState([]);

    const navigate = useNavigate()
    useEffect(() => {
        const fetchDashboardData = async () => {
            try {
                const dashboardDataResponse = await getDashboardDataService();
                setDashboardData(dashboardDataResponse);
            } catch (error) {
                console.log(error);
            }
        }
        fetchDashboardData();


    }, [])
    return (
        <div>
            <div className="lg:hidden">
                <h3 className="text-gray-600 text-2xl font-semibold">Welcome</h3>
                <h2 className="text-gray-600 text-3xl font-semibold">{localStorage.getItem('name')}!</h2>
            </div>
            <div className="md:space-y-6 space-y-4 md:mb-0 mb-14">
                <h3 className="text-gray-600 lg:text-3xl text-2xl font-semibold lg:block hidden">Welcome,{localStorage.getItem('name')}!</h3>
                <div className="space-y-4 md:hidden">
                    {
                        dashboardData?.todayAppointments && dashboardData?.todayAppointments.length === 0 ?
                            <p className="text-sm text-red-500 italic font-semibold underline underline-offset-2">No Appointments!</p> :
                            <p className="text-sm text-gray-500 italic font-semibold underline underline-offset-2">Today's Appointment!</p>
                    }
                    {dashboardData?.todayAppointments && dashboardData?.todayAppointments.map((item, key) => (
                        <div onClick={() => {
                            navigate('/student/appointment')
                        }} key={key} className="w-full shadow-lg rounded-lg px-5 py-3 flex items-center gap-4 text-base bg-gray-50 relative">
                            <div
                                className="w-16 h-16 bg-white shadow-md rounded-lg flex flex-col items-center justify-center">
                                <span className="text-xs text-green-500 font-semibold">{calaulateDayAndDate(item?.date)?.day}</span>
                                <h4 className="text-4xl text-gray-500 font-semibold">{calaulateDayAndDate(item?.date)?.formattedDate}</h4>
                            </div>
                            <div className="flex flex-col gap-1.5">
                                <span className="text-sm text-gray-500 font-semibold">Appointment Name:{item?.name}</span>
                                <span className="text-xs text-gray-500 font-semibold">Start Time: <span className="font-extrabold">{item?.start_time}</span> | End Time: <span className="font-extrabold">{item?.end_time}</span></span>
                            </div>
                            {/*<span className="absolute right-5 top-3 text-xs text-gray-400">{calculateAgeInTime(item?.date).hours + " hrs " + calculateAgeInTime(item?.date).minutes + " mins " + calculateAgeInTime(item?.date).seconds + " secs"}</span>*/}
                        </div>
                    ))}
                </div>
                <div className="px-16 py-2 md:hidden">
                    <div className="border-b-2 border-b-gray-300"></div>
                </div>
                <div onClick={() => {
                    navigate('/student/lesson-plan')
                }}
                    className="flex items-center gap-2 border rounded-md p-4 hover:bg-gray-50 hover:shadow duration-300 cursor-pointer xl:w-1/3 md:w-1/2 w-full">
                    <div className="flex items-center justify-center w-14 h-14 bg-blue-100 rounded-md">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                            stroke="currentColor" className="w-8 h-8 stroke-blue-600">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                        </svg>

                    </div>
                    <h4 className="font-semibold text-xl text-gray-600">Lesson <span
                        className="text-blue-500">Plan</span></h4>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor" className="w-8 h-8 stroke-2 stroke-blue-500">
                        <path strokeLinecap="round" strokeLinejoin="round"
                            d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                    </svg>
                </div>
                <div className="border rounded-md md:block hidden">
                    {
                        dashboardData?.todayAppointments && dashboardData?.todayAppointments.length === 0 ?
                            <div className="border-b p-4 rounded-t-md">
                                <h4 className="font-semibold text-xl text-gray-600">No Appoinments</h4>
                            </div>
                            :
                            <div className="border-b p-4 rounded-t-md">
                                <h4 className="font-semibold text-xl text-gray-600">Today's <span
                                    className="text-blue-500">Appointment</span></h4>
                            </div>
                    }
                    <div className="overflow-hidden">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead>
                                <tr className="bg-gray-50">
                                    <td className="px-6 py-3 text-left text-xs font-semibold text-gray-500">
                                        Appointment Name
                                    </td>
                                    <td className="px-6 py-3 text-left text-xs font-semibold text-gray-500">
                                        Start Time
                                    </td>
                                    <td className="px-6 py-3 text-left text-xs font-semibold text-gray-500">
                                        End Time
                                    </td>
                                </tr>
                            </thead>
                            <tbody id="table-body"
                                className="divide-y divide-gray-200">
                                {dashboardData?.todayAppointments && dashboardData?.todayAppointments.length === 0 ?
                                    <tr>
                                        <td colSpan="5" className="px-6 py-3 text-center text-red-500">
                                            No Appointment found.
                                        </td>
                                    </tr> :
                                    dashboardData?.todayAppointments?.map((item, key) => (

                                        <tr key={key} onClick={() => {
                                            navigate('/student/appointment')
                                        }}>
                                            <td className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                                                {item.name}
                                            </td>
                                            <td className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                                                {item.start_time}
                                            </td>
                                            <td className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                                                {item.end_time}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default StudentDashboard;