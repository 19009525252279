//Libraries
import React, {useState} from "react";
import moment from "moment";

//Components
import {CrossSvg} from "../../../constants";

const AppointmentModal = ({
                              isAppointmentModalOpen,
                              setIsAppointmentModalOpen,
                              school,
                              classTypes,
                              selectedAppointment,
                              setSelectedAppointment,
                              selectedDate = null,
                              setSelectedDate = () => {
                              },
                              bookAppointment,
                              cancelAppointment,
                              setSelectedAppointmentId,
                          }) => {
    const [cancellationReason, setCancellationReason] = useState('');

    const closeModal = () => {
        setIsAppointmentModalOpen(false);
        setSelectedAppointmentId('');
        setSelectedAppointment({});
        setSelectedDate('');
        setCancellationReason('');
    }

    const getClassTypeName = (classId) => {
        if (classTypes.length > 0) {
            const classType = classTypes.find((classItem) => classItem._id === classId)
            return classType.name;
        } else return '';
    }

    const AppointmentModalComponent = () => (
        <>
            {selectedAppointment && selectedAppointment._id && (
                <div className="">
                    <div className="flex justify-between items-center pb-2">
                        <h4 className="font-semibold text-gray-600">Appointment Details</h4>
                        <p className="text-gray-600"><b>Date:</b> <span>{selectedDate}</span></p>
                    </div>
                    <div className="grid md:grid-cols-2 gap-6 py-3 border-y text-sm">
                        <div className="space-y-2">
                            <div className="flex items-center justify-between">
                                <span className="font-semibold text-gray-600">Name:</span>
                                <span className="text-gray-600">{selectedAppointment.name}</span>
                            </div>
                            <div className="flex items-center justify-between">
                                <span className="font-semibold text-gray-600">School:</span>
                                <span className="text-gray-600">{school.name}</span>
                            </div>
                            <div className="flex items-center justify-between">
                                <span className="font-semibold text-gray-600"> Instructor:</span>
                                <span className="text-gray-600">{selectedAppointment.instructor_name}</span>
                            </div>
                            <div className="flex items-center justify-between">
                                <span className="font-semibold text-gray-600">Timing:</span>
                                <span className="text-gray-600">{moment(selectedAppointment.start_time, 'HH:mm').format('hh:mm A')} - {moment(selectedAppointment.end_time, 'HH:mm').format('hh:mm A')}</span>
                            </div>
                            <div className="flex items-center justify-between">
                                <span className="font-semibold text-gray-600"> Address:</span>
                                <span className="text-gray-600">{selectedAppointment.address}</span>
                            </div>
                        </div>
                        <div className="space-y-2">
                            <div className="flex items-center justify-between">
                                <span className="font-semibold text-gray-600">Class:</span>
                                <span className="text-gray-600">{getClassTypeName(selectedAppointment.class_id)}</span>
                            </div>
                            <div className="flex items-center justify-between">
                                <span className="font-semibold text-gray-600">No. of Classes:</span>
                                <span className="text-gray-600">{selectedAppointment.class_qty}</span>
                            </div>
                            <div className="flex items-center justify-between">
                                <span className="font-semibold text-gray-600">No. of Seats:</span>
                                <span className="text-gray-600">{selectedAppointment.total_seats}</span>
                            </div>
                            <div className="flex items-center justify-between">
                                <span className="font-semibold text-gray-600">Seats Booked:</span>
                                <span className="text-gray-600">{selectedAppointment.seats_booked}</span>
                            </div>
                            <div className="flex items-center justify-between">
                                <span className="font-semibold text-gray-600">Seats Remaining:</span>
                                <span className="text-gray-600">{selectedAppointment.seats_remaining}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );

    return (
        <>
            {isAppointmentModalOpen &&
                <div
                    className="fixed top-0 left-0 flex flex-col items-center justify-center min-h-full w-full bg-gray-800/60 backdrop-blur-sm z-50 md:px-0 px-4">
                    <div className="md:min-w-[600px] min-w-full relative rounded-lg bg-white">
                        <div className="flex items-center justify-between px-5 py-3 bg-gray-100 rounded-t-lg">
                            <h2 className='text-gray-600 text-xl'>
                                Appointment Booking
                            </h2>
                            <svg onClick={closeModal} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                 strokeWidth="1.5"
                                 stroke="currentColor"
                                 className="w-6 h-6 cursor-pointer stroke-gray-600 hover:stroke-gray-500 duration-300">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </div>
                        <div className="p-5">
                            <AppointmentModalComponent/>

                            {selectedAppointment.student && selectedAppointment.student.find(
                                (student) => student.id === localStorage.getItem('id') && student.status !== 'CANCELLED' && student.is_active
                            ) && moment(selectedDate).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD') && (
                                <div className="space-y-4 mt-2">
                                    <button onClick={() => {
                                        cancelAppointment(selectedAppointment._id, cancellationReason);
                                        closeModal();
                                    }} type="button"
                                            className="w-full h-10 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-red-500 text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-all text-sm">
                                        Cancel Appointment
                                    </button>
                                    <div className="space-y-1">
                                        <label htmlFor="cancellationReason" className="form-label">Reason for
                                            cancellation</label>
                                        <input
                                            id="cancellationReason"
                                            name="cancellationReason"
                                            type="text"
                                            onChange={e => setCancellationReason(e.target.value)}
                                            value={cancellationReason}
                                            className="form-input w-full"
                                            placeholder="Enter reason here..."
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        {selectedAppointment.student &&
                            (selectedAppointment.student.some(
                                    (student) => student.id === localStorage.getItem('id') && student.status !== 'ACCEPTED'
                                ) ||
                                !selectedAppointment.student.some(
                                    (student) => student.id === localStorage.getItem('id')
                                )) && (
                                <div
                                    className="flex items-center justify-end gap-2 px-5 py-3 bg-gray-100 rounded-b-lg">
                                    <button onClick={closeModal} type="button"
                                            className="h-10 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-red-500 text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-all text-sm">
                                        Cancel
                                    </button>
                                    <button onClick={bookAppointment} type="button"
                                            className="h-10 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-green-500 text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all text-sm">
                                        Confirm
                                    </button>
                                </div>
                            )}
                    </div>
                </div>
            }
        </>
    )
}

export default AppointmentModal;