//Libraries
import React, { useEffect, useState } from "react";

//Components
import Modal from "../../../../components/modal/Modal";
import AlertModalBody from "../../../../components/modal/AlertModalBody";

//Constants
import { EditSvg, TrashSvg } from "../../../../constants";

//Helpers
import { checkScreenSize } from "../../../../helpers/screenSize";

//Services
import { deleteClassTypeById } from "../../../../services/schoolService";
import EditClassTypeModal from "./editClassTypeModal";
import { useNavigate } from "react-router-dom";

const ClassType = ({ classType, classTypesList, setClassTypeList }) => {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false);
    const [classObj, setClassObj] = useState(classType);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(checkScreenSize(768));
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const DeleteModalComponent = () => {
        return (<AlertModalBody message={`Are you sure you want to remove this class type?`} />)
    }

    /* Delete Modal Functions */
    const openDeleteModal = (classType) => {
        // setSelectedClassType(classType);
        setIsDeleteModalOpen(true);
    };

    const deleteClassType = async () => {
        if (await deleteClassTypeById(classObj._id)) {
            const updatedClassTypes = classTypesList.filter(
                classType => classType._id !== classObj._id
            );
            setClassTypeList(updatedClassTypes);
        }
        setIsDeleteModalOpen(false);
    }

    const closeDeleteModal = () => {
        //   setSelectedClassType({});
        setIsDeleteModalOpen(false);
    };
    /* Delete Modal Functions */

    /* Edit Modal Functions */
    const openEditModal = (classType) => {
        setIsEditModalOpen(true);
    }
    /* Edit Modal Functions */

    const getDesktopDesign = () => {
        return (
            // <tr>
            //     <td colSpan="4" className="px-6 py-3 text-center text-red-500">No Instructor Found.</td>
            // </tr>
            <tr>
                <td key={classObj._id} className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                    {classObj.name}
                </td>
                <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                    Kr {parseFloat(classObj.price).toFixed(2)}
                </td>
                <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                    {classObj.duration} minutes
                </td>
                <td onClick={() => navigate(`/school/questionaire/${classObj._id}`)} className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                    <button className="btn btn-secondary-outline btn-sm">Questions</button>
                </td>
                <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                    <div className="flex justify-end gap-8">
                        <svg onClick={e => openEditModal(classObj)} xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 24 24" strokeWidth="1.5"
                            stroke="currentColor" className="w-6 h-6 stroke-gray-500 cursor-pointer">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                        </svg>
                        <svg onClick={e => openDeleteModal(classObj)} xmlns="http://www.w3.org/2000/svg" fill="none"
                            viewBox="0 0 24 24" strokeWidth="1.5"
                            stroke="currentColor" className="w-6 h-6 stroke-red-600 cursor-pointer">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>
                    </div>
                </td>
            </tr>
        )
    }

    const getMobileDesign = () => {
        return (
            <div className="border rounded-md p-4" key={classObj._id}>
                <div className="flex justify-between">
                    <div className="space-y-0.5">
                        <h5 className="leading-none text-base text-gray-500 font-semibold">Name: {classObj.name}</h5>
                        <p className="text-sm text-gray-500">Price: kr {parseFloat(classObj.price).toFixed(2)}</p>
                        <p className="text-xs text-gray-500">Minutes: {classObj.duration}</p>
                        <button onClick={() => navigate(`/school/questionaire/${classObj._id}`)} className="activate-user-btn">Questions</button>
                    </div>
                    <div className="flex items-start gap-2">
                        <button
                            onClick={e => openEditModal(classObj)}
                            className="activate-user-btn">
                            <EditSvg />
                        </button>
                        <button
                            onClick={e => openDeleteModal(classObj)}
                            className="deactivate-user-btn">
                            <TrashSvg />
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {
                !isMobile ? getDesktopDesign() : getMobileDesign()
            }
            {
                isEditModalOpen &&
                <EditClassTypeModal isOpen={isEditModalOpen} setIsOpen={setIsEditModalOpen} classType={classObj}
                    setClassType={setClassObj} classTypesList={classTypesList}
                    setClassTypeList={setClassTypeList} />
            }
            {isDeleteModalOpen && (
                <Modal
                    isOpen={isDeleteModalOpen}
                    closeModal={closeDeleteModal}
                    title={'Remove Class Type'}
                    component={DeleteModalComponent}
                    showComponent={true}
                    onConfirmClick={deleteClassType}
                />
            )}
        </>

    )
}

export default ClassType;