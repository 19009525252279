//Libraries
import React, { useEffect, useState } from "react";

//Pages
import AddClassTypeModal from "./addClassTypeModal";
import ClassType from "./classType";

//Helpers
import { checkScreenSize } from "../../../../helpers/screenSize";


const ClassTypes = ({ classTypesList, setClassTypeList }) => {
    const [isMobile, setIsMobile] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [classTypes, setClassTypes] = useState([]);
    const [searchClass, setSearchClass] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(checkScreenSize(768));
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (!searchClass) {
            setClassTypes(classTypesList);
        } else {
            const filteredData = classTypesList.filter((classType) => {
                const name = classType.name.toLowerCase();
                const searchValue = searchClass.toLowerCase();
                return name.includes(searchValue)
            });
            setClassTypes(filteredData)
        }
    }, [searchClass])

    useEffect(() => {
        setClassTypes(classTypesList);
        setSearchClass('');
    }, [classTypesList])

    /* Create Modal Functions */
    const openCreateModal = () => {
        setIsCreateModalOpen(true);
    }
    /* Create Modal Functions */

    return (
        <div>
            {!isMobile ?
                <div className="space-y-4">
                    <div className="flex items-center justify-between">
                        <div className="relative">
                            <input
                                id="search"
                                name="search"
                                type="text"
                                placeholder="Search by class type..."
                                onChange={event => setSearchClass(event.target.value)}
                                value={searchClass}
                                className="form-input-icon w-96"
                            />
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-5 h-5 stroke-gray-500 absolute inset-0 top-2.5 left-2">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                            </svg>
                        </div>
                        <button onClick={e => openCreateModal()} type="button"
                            className="h-10 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm">
                            Create Class Type
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2"
                                stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>
                        </button>
                    </div>
                    <div className="border rounded-md overflow-hidden">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead>
                                <tr className="bg-gray-50">
                                    <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                                        Name
                                    </td>
                                    <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                        Price
                                    </td>
                                    <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                        Duration
                                    </td>
                                    <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                        Instructor Feedback
                                    </td>
                                    <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                        {/*Actions*/}
                                    </td>
                                </tr>
                            </thead>
                            <tbody key={classTypes && classTypes.length} id="table-body"
                                className="divide-y divide-gray-200">
                                {classTypesList && classTypesList.map((classType, index) => {
                                    return (
                                        <ClassType classType={classType} classTypesList={classTypesList}
                                            setClassTypeList={setClassTypeList} />
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                :
                <div className="space-y-4">
                    <div className="flex items-center justify-between gap-4">
                        <div className="relative w-full">
                            <input
                                id="search"
                                name="search"
                                type="text"
                                placeholder="Search Class Type"
                                onChange={event => setSearchClass(event.target.value)}
                                value={searchClass}
                                className="form-input-icon w-full"
                            />
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-5 h-5 stroke-gray-500 absolute inset-0 top-2.5 left-2">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                            </svg>
                        </div>
                        <button onClick={openCreateModal} className="btn btn-primary">New
                            Add Class
                        </button>
                    </div>
                    {classTypesList && classTypesList.map((classType, index) => {
                        return (
                            <ClassType classType={classType} classTypesList={classTypesList}
                                setClassTypeList={setClassTypeList} />
                        )
                    })}
                </div>
            }

            {
                isCreateModalOpen &&
                <AddClassTypeModal isOpen={isCreateModalOpen} setIsOpen={setIsCreateModalOpen}
                    classTypesList={classTypesList} setClassTypeList={setClassTypeList} />
            }
        </div>
    )
}

export default ClassTypes;