//Libraries
import React from 'react';
import {Routes, Route} from 'react-router-dom';

//Components
import PrivateRoute from '../components/auth/privateRoute';
import Notifications from '../components/notificationListing/Notifications';

//Pages
import StudentDashboard from '../pages/student/Dashboard/studentDashboard'
import StudentRequests from '../pages/student/requests/studentRequests';
import LessonPlan from '../pages/student/lessonPlan/lessonPlan';
import Appointment from '../pages/student/appointment/appointment';
import UserDetails from '../pages/student/user-details/userDetails';
import SchoolInfo from '../pages/student/mySchool/schoolInfo';
import TheoryIndex from '../pages/student/theory/index';
import TheoryTeaching from '../pages/student/theory/teaching';
import TheoryLecture from '../pages/student/theory/lecture'

const StudentRoutes = () => {
    return (
        <Routes>
            <Route element={<PrivateRoute allowedUserType='student'/>}>
                <Route path="/home" element={<StudentDashboard/>}/>
                <Route path='/appointment' element={<Appointment/>}/>
                <Route path="/requests" element={<StudentRequests/>}/>
                <Route path="/lesson-plan" element={<LessonPlan/>}/>
                <Route path="/my-school" element={<SchoolInfo/>}/>
                <Route path='/notifications' element={<Notifications/>}/>
                <Route path='/user-details' element={<UserDetails/>}/>
                <Route path='/theory' element={<TheoryIndex/>}/>
                <Route path='/theory-teaching/:id/:type' element={<TheoryTeaching />}/>
            </Route>
        </Routes>
    );
};
export default StudentRoutes