//Libraries
import React, { useState } from "react";
import { useFormik } from "formik";
import { editClassTypeById } from "../../../../services/schoolService";

import Modal from "../../../../components/modal/Modal";

const EditClassTypeModal = ({ isOpen, setIsOpen, classType, setClassType, classTypesList, setClassTypeList }) => {

    const [classObj, setClassObj] = useState(classType)

    const formik = useFormik({
        initialValues: {
            class_id: classObj._id,
            name: classObj.name,
            price: classObj.price,
            duration: classObj.duration,
            // hexCode: classObj.hexCode
        },
        onSubmit: async values => {
            await editClassTypeById(values);
            const indexToUpdate = classTypesList.findIndex(item => item._id === values.class_id);
            if (indexToUpdate !== -1) {
                const updatedObject = { ...classTypesList[indexToUpdate], ...values };
                delete updatedObject.class_id;

                const updatedClassTypes = [
                    ...classTypesList.slice(0, indexToUpdate),
                    updatedObject,
                    ...classTypesList.slice(indexToUpdate + 1)
                ];

                setClassTypeList(updatedClassTypes);
                setClassObj(updatedObject);
                setClassType(updatedObject);
            }
        },
    });

    const EditClassType = () => {
        return (
            <form>
                <div className="space-y-4">
                    <div className="space-y-1">
                        <label className="form-label" htmlFor="grid-password">
                            Name
                        </label>
                        <input
                            id="name"
                            name="name"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            className="form-input w-full"
                            placeholder="Enter name for class type"
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        <div className="space-y-1">
                            <label className="form-label" htmlFor="grid-password">
                                Price
                            </label>
                            <input
                                id="price"
                                name="price"
                                type="number"
                                onChange={formik.handleChange}
                                value={formik.values.price}
                                className="form-input w-full"
                            />
                        </div>
                        <div className="space-y-1">
                            <label className="form-label" htmlFor="grid-password">
                                Duration
                            </label>
                            <input
                                id="duration"
                                name="duration"
                                type="number"
                                onChange={formik.handleChange}
                                value={formik.values.duration}
                                className="form-input w-full"
                            />
                        </div>
                        {/* <div className="space-y-1">
                            <label className="form-label" htmlFor="grid-password">
                                Color
                            </label>
                            <input
                                id="hexCode"
                                name="hexCode"
                                type="color"
                                onChange={formik.handleChange}
                                value={formik.values.hexCode}
                                className="form-input w-full"
                            />
                        </div> */}
                    </div>
                </div>
            </form>
        )
    }

    const closeEditModal = () => {
        setIsOpen(false);
    }

    const editClassType = () => {
        formik.handleSubmit();
        closeEditModal();
    }

    return (
        <Modal
            isOpen={isOpen}
            closeModal={closeEditModal}
            title={'Edit Class Type'}
            message={''}
            component={EditClassType}
            showComponent={true}
            onConfirmClick={editClassType}
        />
    )
}

export default EditClassTypeModal;