//Libraries
import React, { useContext, useState } from "react";

//Styles
import './Navbar.css'

//Components
import UserDropdown from "../dropdown/UserDropdown";
import NotificationDropdown from "../dropdown/NotificationDropdown";
import { getCreditHistoryDataOnUserId } from "../../services/studentService";
import { StudentContext } from "../../layouts/StudentLayout";

export default function Navbar({ isSideBar, setIsSideBar }) {
    const { creditAvailable } = useContext(StudentContext)
    return (
        <>
            <div className="border-b">
                <div className="flex items-center justify-between container h-16">
                    <div className="flex items-center gap-4">
                        {/*Sidebar Button*/}
                        <div
                            onClick={() => setIsSideBar(!isSideBar)}
                            className="h-10 w-10 border rounded-md xl:hidden md:flex hidden items-center justify-center hover:bg-gray-50 duration-300 cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                            </svg>
                        </div>
                        {localStorage.getItem('user_type') === "student" ?
                            <div className="text-green-500 text-sm font-semibold">
                                Credit Available : <span className="text-base text-blue-500">{creditAvailable ? creditAvailable : 0} credits</span>
                            </div>
                            : ""}
                    </div>

                    <div className="flex items-center lg:gap-8 md:gap-6 gap-4">
                        <NotificationDropdown />
                        <UserDropdown />
                    </div>
                </div>
            </div>
        </>
    );
}
