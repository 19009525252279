import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const notify = (type, position, message) => {

    switch(type){
        case 'SUCCESS': {
            toast.success(message, {
                position: position,
                theme: "colored"
            });
            break;
        }
        case 'ERROR': {
            toast.error(message, {
                position: position,
                theme: "colored"
            });
            break;
        }
        case 'WARN': {
            toast.warn(message, {
                position: position,
                theme: "colored"
            });
            break;
        }
        case 'INFO': {
            toast.info(message, {
                position: position,
                theme: "colored"
            });
            break;
        }
        default: {
            toast("Default Notification !");
            break;
        }
    }
};

export default notify;