//Libraries
import React, { useEffect, useState } from "react";

const DesktopLessonDesign = ({ lessonPlan, classTypeList, setIsSignatureModalOpen, setSelectedClass }) => {
    const [numberSeries, setNumberSeries] = useState([]);
    const getClassTypeName = (classId) => {
        if (classTypeList.length > 0) {
            const classType = classTypeList.find((classItem) => classItem._id === classId)
            return classType.name;
        } else return '';
    }
    useEffect(() => {
        if (lessonPlan && lessonPlan.classes.length > 0) {
            let startingNumber = 1;
            let updatedSeries = [];
            for (let i = 0; i < lessonPlan.classes.length; i++) {
                let classItem = lessonPlan.classes[i];
                let numbers = [];
                for (let j = 0; j < classItem.class_qty; j++) {
                    numbers.push(startingNumber)
                    startingNumber++;
                }
                const series = numbers.join(' + ');
                updatedSeries.push(series)
            }
            setNumberSeries(updatedSeries);
        }
    }, [lessonPlan])

    return (
        <form>
            <div className="border rounded-md overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                        <tr className="bg-gray-50">
                            <td className="px-6 py-3 text-xs font-semibold text-gray-500 whitespace-nowrap">
                                Lesson Name
                            </td>
                            <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500 whitespace-nowrap">
                                Class Type
                            </td>
                            <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500 whitespace-nowrap">
                                Student's Signature
                            </td>
                            <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500 whitespace-nowrap">
                                Instructor's Signature
                            </td>
                        </tr>
                    </thead>
                    <tbody id="table-body"
                        className="divide-y divide-gray-200">
                        {((lessonPlan) && (numberSeries.length === 0) && (lessonPlan.classes.length === 0)) ?
                            <tr>
                                <td colSpan="4" className="px-6 py-3 text-center text-red-500">No Lesson Found.</td>
                            </tr>
                            : lessonPlan.classes.map((classType, index) => {
                                return (
                                    <tr key={index}>
                                        <td key={`class_name${index}`}
                                            className="px-6 py-4 align-middle whitespace-nowrap text-gray-500 leading-4">
                                            <p className="font-semibold">
                                                {
                                                    classType.class_name.length > 25
                                                        ?
                                                        classType.class_name.slice(0, 22) + '...'
                                                        :
                                                        classType.class_name
                                                }
                                            </p>
                                            <span className="text-sm">
                                                {
                                                    numberSeries.length > 0 ? numberSeries[index] + ' ' : ''
                                                }
                                                =
                                                {' ' + classType.class_qty} lessons of {classType.class_duration} min. ({classType.class_qty * classType.class_duration} min.)
                                            </span>
                                        </td>
                                        <td key={`class_id${index}`}
                                            className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                            {classType.class_id ? getClassTypeName(classType.class_id) : ''}
                                        </td>
                                        <td width="15%" key={`student_sign${index}`}
                                            className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                            <div className="flex justify-center">
                                                <button
                                                    key={`sign${index}`}
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        setIsSignatureModalOpen(true);
                                                        setSelectedClass(classType);
                                                    }}
                                                    className={classType.student_signature ? 'btn btn-success btn-sm' : 'btn btn-primary btn-sm'}
                                                    disabled={classType.class_taken < classType.class_qty || classType.student_signature}
                                                >
                                                    {classType.student_signature ? 'Signed' : 'Unsigned'}
                                                </button>
                                            </div>
                                        </td>
                                        <td width="15%" key={`instructor_sign${index}`}
                                            className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                            <div className="flex justify-center">
                                                <button
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        setIsSignatureModalOpen(true);
                                                        setSelectedClass(classType);
                                                    }}
                                                    className={classType.instructor_signature ? 'btn btn-success btn-sm' : 'btn btn-primary btn-sm'}
                                                    disabled={true}
                                                >
                                                    {classType.instructor_signature ? 'Signed' : 'Unsigned'}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>
        </form>
    )
}

export default DesktopLessonDesign;