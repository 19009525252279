//Libraries
import axios from "axios";

//Components
import notify from '../helpers/raiseToast'

//Helpers
import logout from "../helpers/logout";

//Base Url
const ApiUrl = process.env.REACT_APP_API_URL + '/instructor';

const axiosInstance = axios.create({
  baseURL: ApiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

//Interceptors to attach tokens dynamically.
//Use axios instance for requests to attach these headers.
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers['x-access-token'] = accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 403) {
      logout();
    }
    return Promise.reject(error);
  }
);

export const instructorLogin = async (body) => {
  try {
    const response = await axios.post(`${ApiUrl}/login`, body)
    const token = response.data
    localStorage.setItem("id", token.id);
    localStorage.setItem("user_type", token.user_type);
    localStorage.setItem("email", token.email);
    localStorage.setItem("name", token.name);
    localStorage.setItem("accessToken", token.accessToken);
    return true;
  } catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.');
    return false;
  }
}

export const instructorRegister = async (body) => {
  try {
    await axios.post(`${ApiUrl}/signup`, body)
    notify('SUCCESS', 'top-right', 'Registered successfully!')
    return true;
  } catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.');
    return false;
  }
}

export const viewSchoolRequests = async (status) => {
  try {
    const response = await axiosInstance.get(`/viewrequests/${status}`)
    return response.data;
  } catch (error) {
    return;
  }
}

export const changeRequestStatus = async (requestId, body) => {
  try {
    await axiosInstance.post(`/changerequest/${requestId}`, body);
    if (body.status.toLowerCase() === 'accepted') {
      notify('SUCCESS', 'top-right', 'School request accepted successfully!')
    }
    else {
      notify('ERROR', 'top-right', 'School request rejected!')
    }
    return;
  } catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.');
    return;
  }
}

export const viewMySchools = async () => {
  try {
    const response = await axiosInstance.get('/view-my-schools');
    const { data } = response.data
    return data;
  } catch (error) {
    return;
  }
}

export const leaveSchoolById = async (body) => {
  try {
    const response = await axiosInstance.post('/leave-school', body);
    return response.data;
  } catch (error) {
    return;
  }
}

export const invitationInstructorRegister = async (body) => {
  try {
    await axiosInstance.put('/signup-invite', body);
    notify('SUCCESS', 'top-right', 'Registered successfully!');
    return true;
  } catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.');
    return false;
  }
}

export const viewAllAppointments = async () => {
  try {
    const response = await axiosInstance.get('/appointments/all');
    return response.data;
  } catch (error) {
    return;
  }
}

export const createAppointment = async (body) => {
  try {
    const response = await axiosInstance.post('/appointment/create', body);
    if (response.data && response.data.notused_students && response.data.notused_students.length > 0)
      notify('WARN', 'top-right', `Appointment created successfully, but some students weren't added due to insufficient balance.`);
    else if (response.data && response.data.appointment_record) { return response }
    // notify('SUCCESS', 'top-right', 'Appointment created successfully!');
    else notify('ERROR', 'top-right', 'Appointment booking failed! Insufficient balance for students.');
    return response.data;
  } catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.');
    return;
  }
}

export const getStudentsBySchoolId = async (schoolId) => {
  try {
    const response = await axiosInstance.get(`/students/school/${schoolId}`);
    return response.data;
  } catch (error) {
    return;
  }
}

export const getClassTypesBySchoolId = async (schoolId) => {
  try {
    const response = await axiosInstance.get(`/classes/school/${schoolId}`);
    return response.data;
  } catch (error) {
    return;
  }
}

export const deleteAppointmentById = async (appointmentId, body) => {
  try {
    await axiosInstance.post(`/appointment/delete/${appointmentId}`, body);
    notify('SUCCESS', 'top-right', 'Appointment cancelled successfully!');
    return true;
  } catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.');
    return false;
  }
}

export const editAppointmentById = async (body, appointmentId) => {
  try {
    const response = await axiosInstance.put(`/appointment/edit/${appointmentId}`, body);
    notify('SUCCESS', 'top-right', 'Appointment edited successfully!');
    return response.data;
  } catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.');
    return;
  }
}

export const getStudentsWithLessonPlan = async (schoolId) => {
  try {
    const response = await axiosInstance.get(`/lessons/assigned/all/school/${schoolId}`);
    return response.data;
  } catch (error) {
    return;
  }
}
export const getInstructorInfo = async () => {
  try {
    const response = await axiosInstance.get('/getinstructor');
    return response.data;
  } catch (error) {
    return;
  }
}

export const updateInstructor = async (body) => {
  try {
    const response = await axiosInstance.put('profile/update', body);
    notify('SUCCESS', 'top-right', 'Successfully updated')
    return response.data;
  } catch (error) {
    notify('ERROR', 'top-right', 'Invalid data')
    return;
  }
}
export const updateInstructorPassword = async (body) => {
  try {
    const response = await axiosInstance.put('profile/updatePassword', body);
    notify('SUCCESS', 'top-right', 'Password updated')
    return response.data;
  } catch (error) {
    notify('ERROR', 'top-right', 'Invalid password')
    return;
  }
}
export const updateInstructorProfileImage = async (body) => {
  try {
    const response = await axiosInstance.post('profile/updateProfilePicture', body);
    return response.data;
  } catch (error) {
    return;
  }
}

export const getInstructorStudents = async () => {
  try {
    const response = await axiosInstance.get('students');
    return response.data;
  } catch (error) {
    return;
  }
}
export const getLessonPlanStudents = async (class_id) => {
  try {
    const response = await axiosInstance.get(`lessonPlanStudents/${class_id}`);
    return response.data;
  } catch (error) {
    return;
  }
}

export const getStudent = async (Id) => {
  try {
    const response = await axiosInstance.get(`getStudent/${Id}`);
    return response.data;
  } catch (error) {
    return;
  }
}

export const getAppointmentsByUserId = async (user_type, Id) => {
  try {
    const response = await axiosInstance.get(`appointments/${user_type}/${Id}`);
    return response.data;
  } catch (error) {
    return;
  }
}

export const getLessonByUserId = async (userId) => {
  try {
    const response = await axiosInstance.get(`studentLessons/${userId}`);
    return response.data;
  } catch (error) {
    return;
  }
}

export const getLessonByInstructorId = async (userId) => {
  try {
    const response = await axiosInstance.get(`studentLessons`);
    return response.data;
  } catch (error) {
    return;
  }
}

export const getSchool = async (Id) => {
  try {
    const response = await axiosInstance.get(`getSchool/${Id}`);
    return response.data;
  } catch (error) {
    return;
  }
}

export const getInstructors = async (Id) => {
  try {
    const response = await axiosInstance.get(`getInstructors/${Id}`);
    return response.data;
  } catch (error) {
    return;
  }
}


export const getQuestionaireOnId = async (Id) => {
  try {
    const response = await axiosInstance.get(`questionaire/${Id}`);
    return response.data;
  } catch (error) {
    return;
  }
}


export const getAppointmentsBySchoolId = async (Id) => {
  try {
    const response = await axiosInstance.get(`appointments/${Id}`);
    return response.data;
  } catch (error) {
    return;
  }
}

export const getSchoolStudents = async (Id) => {
  try {
    const response = await axiosInstance.get(`getStudentsSchool/${Id}`);
    return response.data;
  } catch (error) {
    return;
  }
}
export const getDashboardDataService = async () => {
  try {
    const response = await axiosInstance.get('dashboard-panel');
    return response.data;
  } catch (error) {
    return;
  }
}

export const getAllClassTypes = async (Id) => {
  try {
    const response = await axiosInstance.get(`/view/classes/all/${Id}`);
    return response.data;
  } catch (error) {
    return;
  }
}
export const getAllClassTypesInstructor = async () => {
  try {
    const response = await axiosInstance.get(`/view/classes/allInstructor`);
    return response.data;
  } catch (error) {
    return;
  }
}


export const getSchoolTransactionService = async (Id) => {
  try {
    const response = await axiosInstance.get(`/getSchoolTransaction/${Id}`);
    return response.data;
  } catch (error) {
    return;
  }
}


export const getClassDataOnIds = async (body) => {
  try {
    const response = await axiosInstance.post(`/getAllClassesOnIds`, body)
    return response.data;
  } catch (error) {
    return;
  }
}

export const SignLesson = async (lessonId, body) => {
  try {
    await axiosInstance.put(`/lesson/sign/${lessonId}`, body)
    notify('SUCCESS', 'top-right', 'Lesson signed successfully!');
    return true;
  } catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.');
    return false;
  }
}

export const SignLessonForMultipleStudents = async (body) => {
  try {
    await axiosInstance.put(`/lesson/sign`, body)
    notify('SUCCESS', 'top-right', 'Lesson signed successfully!');
    return true;
  } catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.');
    return false;
  }
}


