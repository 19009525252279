//Libraries
import axios from "axios";

//Components
import notify from '../helpers/raiseToast'

//Helpers
import logout from "../helpers/logout";

//Base Url
const ApiUrl = process.env.REACT_APP_API_URL + '/school';

const axiosInstance = axios.create({
  baseURL: ApiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

const axiosInstanceMultipart = axios.create({
  baseURL: ApiUrl,
  headers: {
    'accept': 'application/json',
    'Content-Type': `multipart/form-data`,
  },
});
//Interceptors to attach tokens dynamically.
//Use axios instance for requests to attach these headers.
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers['x-access-token'] = accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 403) {
      logout();
    }
    return Promise.reject(error);
  }
);

axiosInstanceMultipart.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers['x-access-token'] = accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstanceMultipart.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 403) {
      logout();
    }
    return Promise.reject(error);
  }
);

export const schoolLogin = async (body) => {
  try {
    const response = await axios.post(`${ApiUrl}/login`, body)
    const token = response.data
    localStorage.setItem("id", token.id);
    localStorage.setItem("user_type", token.user_type);
    localStorage.setItem("email", token.email);
    localStorage.setItem("name", token.name);
    localStorage.setItem("accessToken", token.accessToken);
    return true;
  } catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.');
    return false;
  }
}

export const schoolRegister = async (body) => {
  try {
    await axios.post(`${ApiUrl}/signup`, body)
    notify('SUCCESS', 'top-right', 'Registered successfully!')
    return true;
  } catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.');
    return false;
  }
}

export const getSchoolInstructors = async () => {
  try {
    const response = await axiosInstance.get('/instructors');
    return response.data;
  } catch (error) {
    return false;
  }
}

export const getAllInstructors = async () => {
  try {
    const response = await axiosInstance.get('/allinstructors');
    return response.data;
  } catch (error) {
    return false;
  }
}

export const sendRequestToInstructor = async (body) => {
  try {
    const response = await axiosInstance.post('/request-instructor', body);
    notify('SUCCESS', 'top-right', 'Request sent successfuly!')
    return response;
  } catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.')
    return false;
  }
}

export const sendInvitationToInstructor = async (body) => {
  try {
    const response = await axiosInstance.post('/invite-instructor', body);
    notify('SUCCESS', 'top-right', 'Invitation sent successfuly!')
    return response;
  } catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.')
    return false;
  }
}

export const viewPendingRequests = async () => {
  try {
    const response = await axiosInstance.get('/view-pending-requests-instructor');
    return response.data;
  }
  catch (error) {
    return;
  }
}

export const cancelRequest = async (body) => {
  try {
    const response = await axiosInstance.post('/cancel-request-instructor', body);
    notify('SUCCESS', 'top-right', 'Instructor deactivated successfuly!')
    return response;
  }
  catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.')
    return;
  }
}

export const removeInstructor = async (body) => {
  try {
    const response = await axiosInstance.post('/remove-instructor', body);
    notify('SUCCESS', 'top-right', 'Instructor deactivated successfuly!')
    return response;
  }
  catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.')
    return;
  }
}

export const activateInstructor = async (body) => {
  try {
    const response = await axiosInstance.post('/activate-instructor', body);
    notify('SUCCESS', 'top-right', 'Instructor activated successfuly!')
    return response;
  }
  catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.')
    return;
  }
}

export const getSchoolStudents = async () => {
  try {
    const response = await axiosInstance.get('/students');
    return response.data;
  } catch (error) {
    return false;
  }
}

export const getAllStudents = async () => {
  try {
    const response = await axiosInstance.get('/view-all-students');
    return response.data;
  } catch (error) {
    return false;
  }
}

export const removeStudentFromSchool = async (body) => {
  try {
    const response = await axiosInstance.post('/remove-student', body);
    notify('SUCCESS', 'top-right', 'Student removed successfuly!')
    return response;
  } catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.')
    return false;
  }
}

export const sendRequestToStudent = async (body) => {
  try {
    const response = await axiosInstance.post('/request-student', body);
    notify('SUCCESS', 'top-right', 'Request sent successfuly!')
    return response;
  } catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.')
    return false;
  }
}

export const sendInvitationToStudent = async (body) => {
  try {
    const response = await axiosInstance.post('/invite-student', body);
    notify('SUCCESS', 'top-right', 'Invitation sent successfuly!')
    return response;
  } catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.')
    return false;
  }
}

export const getPendingStudentRequests = async () => {
  try {
    const response = await axiosInstance.get('/view-pending-requests-student');
    return response.data;
  }
  catch (error) {
    return false;
  }
}

export const cancelStudentRequest = async (body) => {
  try {
    const response = await axiosInstance.post('/cancel-request-student', body);
    notify('SUCCESS', 'top-right', 'Instructor deactivated successfuly!')
    return response;
  }
  catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.')
    return;
  }
}

export const createClassType = async (body) => {
  try {
    const response = await axiosInstance.post('/create-class', body);
    notify('SUCCESS', 'top-right', 'Class type created successfully!')
    return response.data;
  }
  catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.')
    return;
  }
}

export const getClassTypeList = async () => {
  try {
    const response = await axiosInstance.get('/all-classes');
    return response.data;
  }
  catch (error) {
    return;
  }
}

export const editClassTypeById = async (body) => {
  try {
    await axiosInstance.put('/update-class', body);
    notify('SUCCESS', 'top-right', 'Class type edited successfully!')
    return;
  }
  catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.')
    return;
  }
}

export const deleteClassTypeById = async (classId) => {
  try {
    await axiosInstance.delete(`/delete-class/${classId}`);
    notify('SUCCESS', 'top-right', 'Class type deleted successfully!')
    return true;
  }
  catch (error) {
    const errorMessage = error.response.data.message;
    notify('ERROR', 'top-right', errorMessage);
    return false;
  }
}

export const getPackagesList = async () => {
  try {
    const response = await axiosInstance.get('/all-packages');
    return response.data;
  }
  catch (error) {
    return;
  }
}

export const createPackage = async (body) => {
  try {
    await axiosInstance.post('/create-package', body);
    notify('SUCCESS', 'top-right', 'Package created successfully!')
    return;
  }
  catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.')
    return;
  }
}

export const deletePackageById = async (packageId) => {
  try {
    await axiosInstance.delete(`/delete-package/${packageId}`);
    notify('SUCCESS', 'top-right', 'Package deleted successfully!')
    return;
  }
  catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.')
    return;
  }
}

export const getPackageById = async (packageId) => {
  try {
    const response = await axiosInstance.get(`/package/${packageId}`);
    return response.data;
  }
  catch (error) {
    return;
  }
}

export const updatePackage = async (packageId, body) => {
  try {
    await axiosInstance.put(`/update-package/${packageId}`, body);
    notify('SUCCESS', 'top-right', 'Package updated successfully!')
    return;
  }
  catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.')
    return;
  }
}

export const assignStudentClass = async (body) => {
  try {
    await axiosInstance.post('/credit/class', body);
    notify('SUCCESS', 'top-right', 'Class assigned successfully!')
    return;
  }
  catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.')
    return;
  }
}

export const assignStudentPackage = async (body) => {
  try {
    await axiosInstance.post('/credit/package', body);
    notify('SUCCESS', 'top-right', 'Package assigned successfully!')
    return;
  }
  catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.')
    return;
  }
}

export const createLessonPlan = async (body) => {
  try {
    await axiosInstance.post('/lesson/create', body);
    notify('SUCCESS', 'top-right', 'Lesson plan created successfully!')
    return;
  }
  catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.')
    return;
  }
}

export const getLessonPlanById = async (lessonId) => {
  try {
    const response = await axiosInstance.get(`/lesson/${lessonId}`);
    return response.data;
  }
  catch (error) {
    return;
  }
}

export const updateLessonPlan = async (lessonId, body) => {
  try {
    await axiosInstance.put(`/lesson/update/${lessonId}`, body);
    notify('SUCCESS', 'top-right', 'Lesson plan updated successfully!')
    return;
  }
  catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.')
    return;
  }
}

export const getLessonPlansList = async (is_active) => {
  try {
    const body = {
      is_active: is_active
    }
    const response = await axiosInstance.post(`/lesson/all`, body);
    return response.data;
  }
  catch (error) {
    return;
  }
}

export const deleteLessonPlanById = async (lessonPlanId) => {
  try {
    await axiosInstance.delete(`/lesson/delete/${lessonPlanId}`);
    notify('SUCCESS', 'top-right', 'Lesson plan deleted successfully!')
    return;
  }
  catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.');
    return;
  }
}

export const assignStudentLesson = async (body) => {
  try {
    await axiosInstance.post('/assign/lesson', body);
    notify('SUCCESS', 'top-right', 'Lesson plan assigned successfully!')
    return;
  }
  catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.');
    return;
  }
}

export const getAssingedLessonPlan = async (studentId) => {
  try {
    const response = await axiosInstance.get(`/student/${studentId}/lesson`);
    return response.data;
  }
  catch (error) {
    return;
  }
}

export const viewAllAppointments = async () => {
  try {
    const response = await axiosInstance.get('/appointment/view/all');
    return response.data;
  } catch (error) {
    return;
  }
}

export const createAppointment = async (body) => {
  try {
    const response = await axiosInstance.post('/appointment/create', body);
    if (response.data.data && response.data.data.notused_students && response.data.data.notused_students.length > 0)
      notify('WARN', 'top-right', `Appointment created successfully, but some students weren't added due to insufficient balance.`);
    else if (response.data.data && response.data.data.appointment_record) {
      return response;
    }
    else notify('ERROR', 'top-right', 'Appointment booking failed! Insufficient balance for students.');
    return response.data;
  } catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.');
    return;
  }
}

export const deleteAppointmentById = async (appointmentId, body) => {
  try {
    await axiosInstance.post(`/appointment/delete/${appointmentId}`, body);
    notify('SUCCESS', 'top-right', 'Appointment deleted successfully!')
    return true;
  } catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.');
    return false;
  }
}

export const editAppointmentById = async (body, appointmentId) => {
  try {
    const response = await axiosInstance.put(`/appointment/edit/${appointmentId}`, body);
    notify('SUCCESS', 'top-right', 'Appointment edited successfully!')
    return response.data;
  } catch (error) {
    notify('ERROR', 'top-right', 'Oops! Something went wrong. Please try again.');
    return;
  }
}

export const getStudentsWithLessonPlan = async () => {
  try {
    const response = await axiosInstance.get('/lessons/assigned/all');
    return response.data;
  } catch (error) {
    return;
  }
}

export const getSchoolInfo = async () => {
  try {
    const response = await axiosInstance.get('/getschool');
    return response.data;
  } catch (error) {
    return;
  }
}

export const updateSchool = async (body) => {
  try {
    const response = await axiosInstance.put('profile/update', body);
    notify('SUCCESS', 'top-right', 'Successfully updated')
    return response.data;
  } catch (error) {
    notify('ERROR', 'top-right', 'Invalid details')
    return;
  }
}
export const updateSchoolPassword = async (body) => {
  try {
    const response = await axiosInstance.put('profile/updatePassword', body);
    notify('SUCCESS', 'top-right', 'Password updated')
    return response.data;
  } catch (error) {
    notify('ERROR', 'top-right', 'Invalid password')
    return;
  }
}
export const updateSchoolProfileImage = async (body) => {
  try {
    const response = await axiosInstance.post('profile/updateProfilePicture', body);
    return response.data;
  } catch (error) {
    return;
  }
}

export const getStudent = async (Id) => {
  try {
    const response = await axiosInstance.get(`getStudent/${Id}`);
    return response.data;
  } catch (error) {
    return;
  }
}
export const getStudentTransactions = async (Id) => {
  try {
    const response = await axiosInstance.get(`getStudentTransactions/${Id}`);
    return response.data;
  } catch (error) {
    return;
  }
}
export const getStudentCreditHistory = async (Id) => {
  try {
    const response = await axiosInstance.get(`getStudentCreditHistory/${Id}`);
    return response.data;
  } catch (error) {
    return;
  }
}

export const getAppointmentsByUserId = async (user_type, Id) => {
  try {
    const response = await axiosInstance.get(`appointments/${user_type}/${Id}`);
    return response.data;
  } catch (error) {
    return;
  }
}

export const getInstructor = async (Id) => {
  try {
    const response = await axiosInstance.get(`getInstructor/${Id}`);
    return response.data;
  } catch (error) {
    return;
  }
}

export const getLessonByUserId = async (Id) => {
  try {
    const response = await axiosInstance.get(`/lessons/assigned/${Id}`);
    return response.data;
  } catch (error) {
    return;
  }
}

export const getDashboardDataService = async () => {
  try {
    const response = await axiosInstance.get('dashboard-panel');
    return response.data;
  } catch (error) {
    return;
  }
}


export const updateHrRates = async (body) => {
  try {
    const response = await axiosInstance.post('instructorHrRate', body);
    return response;
  } catch (error) {
    return;
  }
}

export const getAllClassTypes = async () => {
  try {
    const response = await axiosInstance.get(`/view/classes/all`);
    return response.data;
  } catch (error) {
    return;
  }
}

export const getQeustionaireByUserId = async (Id) => {
  try {
    const response = await axiosInstance.get(`/questionaire/${Id}`)
    return response.data;
  } catch (error) {
    return;
  }
}

export const insertQuestionByUserId = async (Id, payload) => {
  try {
    const response = await axiosInstance.post(`/questionaire/${Id}`, payload)
    return response.data;
  } catch (error) {
    return;
  }
}

export const deleteQuestionId = async (Id) => {
  try {
    const response = await axiosInstance.delete(`/questionaire/${Id}`)
    return response.data;
  } catch (error) {
    return;
  }
}


export const getClassDataOnIds = async (body) => {
  try {
    const response = await axiosInstance.post(`/getAllClassesOnIds`, body)
    return response.data;
  } catch (error) {
    return;
  }
}

export const getTransactionInstructor = async (Id) => {
  try {
    const response = await axiosInstance.get(`/getTransactionsForInstructor/${Id}`)
    return response.data;
  } catch (error) {
    return;
  }
}