//Libraries
import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';

//Routes
import SchoolRoutes from '../routes/schoolRoutes';

//Components
import Sidebar from '../components/sidebar/Sidebar'
import Navbar from '../components/navbars/Navbar'

//Constants
import { SCHOOL_SIDEBAR_OPTIONS } from '../constants';

const SchoolLayout = ({ isSideBar, setIsSideBar }) => {
    return (
        <>
            <div className="flex flex-row">
                <Sidebar isSideBar={isSideBar} setIsSideBar={setIsSideBar} sidebarOptions={SCHOOL_SIDEBAR_OPTIONS} />
                <div className="flex-1 min-h-screen">
                    <Navbar isSideBar={isSideBar} setIsSideBar={setIsSideBar} />
                    <div className="container py-5 mb-16">
                        <SchoolRoutes />
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
};

export default SchoolLayout;