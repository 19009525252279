//Libraries
import React, {useEffect, useState} from "react";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list';
import moment from "moment";

//Helpers
import {checkScreenSize} from "../../helpers/screenSize";

//Styles
import './calendar.css'


const Calendar = ({
                      setIsAppointmentModalOpen,
                      setIsEditMode,
                      events,
                      setSelectedAppointmentId,
                      setSelectedDate = () => {
                      },
                      removeAddAppointment
                  }) => {
    const [isMobile, setIsMobile] = useState(false);
    const [modifiedEvent, setModifiedEvent] = useState(events);
    useEffect(() => {
        const temp = events.map((item, key) => ({
            ...item,
            // color: item.fullyBooked || item.bookedByUser ? '#f6ad55' : '#48bb78',
            color: item.hexCode,
            dotColor: item.fullyBooked || item.bookedByUser ? '#f6ad55' : '#48bb78',
            // backgroundColor: item.fullyBooked || item.bookedByUser ? '#f6ad55' : '#48bb78',
            backgroundColor: item.hexCode,
            textColor: "#fff",
            borderColor: 'none',
            display: 'block',
        }));
        setModifiedEvent(temp)
    }, [events])
    const mobileHeaderToolbar = {
        left: localStorage.getItem('user_type') !== 'student' ? !removeAddAppointment ? 'appointmentButton' : '' : '',
        center: 'prev title next',
        right: localStorage.getItem('user_type') !== 'student' ? 'listWeek listDay' : 'listWeek listDay',
    }
    const desktopHeaderToolbar = {
        left: localStorage.getItem('user_type') !== 'student' ? !removeAddAppointment ? 'appointmentButton' : '' : '',
        center: 'prev title next',
        right: localStorage.getItem('user_type') !== 'student' ? 'today dayGridMonth listMonth listWeek listDay' : 'dayGridMonth listMonth listWeek listDay',
    }

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(checkScreenSize(768));
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const openAppointmentModal = (event = undefined) => {
        if (event) {
            setIsEditMode(true);
            setSelectedAppointmentId(event.extendedProps.appointment_id);
            setSelectedDate(moment(event.start).format('DD-MMMM-YYYY'));
        } else setIsEditMode(false);
        setIsAppointmentModalOpen(true);
    }

    const calculateMins = (startDate, endDate) => {
        let startTimeObj = new Date(startDate);
        let endTimeObj = new Date(endDate);
        let differenceMs = endTimeObj.getTime() - startTimeObj.getTime();
        return Math.floor(differenceMs / (1000 * 60));
    }

    const eventRender = ({event}) => {
        let className = '';
        if (event.extendedProps.bookedByUser) className = 'fully-booked-event';
        else if (event.extendedProps.fullyBooked) className = 'fully-booked-event';
        else className = 'available-event';
        return (
            <div className={className + ' event-primary inline-block'} style={{backgroundColor: event.backgroundColor}}>
                <div
                    className="fc-event-time">{event.extendedProps.heading ?? "Driving lesson " + calculateMins(event.start, event.end) + " mins"}</div>
                <div className="fc-event-title ">{"Title: " + event.title} </div>
                <div className="fc-event-title">{"Instructor: " + event.extendedProps.instructor_name}</div>
                <div
                    className="fc-event-title">{"From: " + moment(event.start, 'HH:mm A').format('HH:mm A') + " To " + moment(event.end, 'HH:mm A').format('HH:mm A')}</div>
                <div className="fc-event-title font-semibold py-1">
                    Status: {event.extendedProps.fullyBooked ? 'Fully Booked' : 'Open'}
                </div>
            </div>)
    };
    // <div
    //     className="fc-event-time">{moment(event.start, 'HH:mm A').format('HH:mm A')} - {moment(event.end, 'HH:mm A').format('HH:mm A')}</div>
    // <div className="fc-event-title">{event.extendedProps.instructor_name + ":"} {event.title}</div>


    return (
        <>
            {
                modifiedEvent &&
                <FullCalendar
                    plugins={[listPlugin, dayGridPlugin, timeGridPlugin]}
                    selectable={true}
                    select={(info) => {
                        alert('Selected: ' + info.startStr + ' to ' + info.endStr);
                    }}
                    buttonText={{
                        dayGridMonth: 'Month Grid',
                        today: 'Today',
                        month: 'Month List',
                        week: 'Week',
                        day: 'Day'
                    }
                    }
                    headerToolbar={isMobile ? mobileHeaderToolbar : desktopHeaderToolbar}
                    initialView={'listDay'}
                    customButtons={{
                        appointmentButton: {
                            text: 'Add Appointment',
                            click: () => openAppointmentModal(),
                        },
                    }}

                    slotDuration="00:30:00"
                    minTime="00:00:00"
                    maxTime="24:00:00"
                    slotLabelFormat={{
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false
                    }}
                    eventTimeFormat={{
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false
                    }}
                    eventClick={event => openAppointmentModal(event.event)}
                    events={modifiedEvent}
                    eventContent={eventRender}
                />
            }

        </>
    );
};

export default Calendar;