//Libraries
import React from "react";

import { useParams } from "react-router";

const DesktopLessonDesign = ({
    formik,
    classTypeList,
    openAddClassModal,
    openEditClassModal,
    removeClass,
    listingContainerRef,
    saveLessonPlan
}) => {
    const routeParams = useParams();
    const getClassTypeName = (classId) => {
        if (classTypeList.length > 0) {
            const classType = classTypeList.find((classItem) => classItem._id === classId)
            return classType.name;
        } else return '';
    }
    return (
        <form className="space-y-4">
            <div className="flex items-center justify-between">
                <input
                    id="name"
                    name="name"
                    type="text"
                    key='name'
                    placeholder="Enter Lesson Plan Name"
                    onChange={formik.handleChange}
                    value={formik.values ? formik.values.name : ''}
                    className="form-input w-1/3"
                />
                <button className="btn btn-success" onClick={saveLessonPlan}>
                    {routeParams.lessonPlanId ? 'Update' : 'Create'}
                    <svg className="w-4 h-4 fill-white" viewBox="0 0 24 24" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M18.1716 1C18.702 1 19.2107 1.21071 19.5858 1.58579L22.4142 4.41421C22.7893 4.78929 23 5.29799 23 5.82843V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H18.1716ZM4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21L5 21L5 15C5 13.3431 6.34315 12 8 12L16 12C17.6569 12 19 13.3431 19 15V21H20C20.5523 21 21 20.5523 21 20V6.82843C21 6.29799 20.7893 5.78929 20.4142 5.41421L18.5858 3.58579C18.2107 3.21071 17.702 3 17.1716 3H17V5C17 6.65685 15.6569 8 14 8H10C8.34315 8 7 6.65685 7 5V3H4ZM17 21V15C17 14.4477 16.5523 14 16 14L8 14C7.44772 14 7 14.4477 7 15L7 21L17 21ZM9 3H15V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V3Z" />
                    </svg>
                </button>
            </div>
            <div className="border rounded-md overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                    <tr className="bg-gray-50">
                        <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                            Lesson Name
                        </td>
                        <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                            Class Type
                        </td>
                        <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                            No. of Classes
                        </td>
                        <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                            Description
                        </td>
                        <td></td>
                    </tr>
                    </thead>
                    <tbody ref={listingContainerRef} id="table-body"
                        className="divide-y divide-gray-200">
                        {
                            formik.values.classes.length === 0 ?
                                <tr>
                                    <td colSpan="5" className="px-6 py-3 text-center text-gray-500 underline">
                                        Click add to start adding lessons in the list
                                    </td>
                                </tr> : ""
                        }

                        {
                            formik.values && formik.values.classes.map((classType, index) =>
                                <tr key={`row-${index}`}>
                                    <td key={`class_name${index}`}
                                        className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                                        {classType.class_name.length > 25 ? classType.class_name.slice(0, 22) + '...' : classType.class_name}
                                    </td>
                                    <td key={`class_id${index}`}
                                        className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                        {classType.class_id ? getClassTypeName(classType.class_id) : ''}
                                    </td>
                                    <td key={`quantity${index}`}
                                        className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                        {classType.class_qty}
                                    </td>
                                    <td key={`description${index}`}
                                        className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                        {classType.description.length > 50 ? classType.description.slice(0, 47) + '...' : classType.description}
                                    </td>
                                    <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                        <div className="flex justify-end gap-8">
                                            <svg onClick={() => openEditClassModal(index)}
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor"
                                                className="w-6 h-6 stroke-gray-500 cursor-pointer">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                            </svg>
                                            <svg onClick={() => removeClass(index)}
                                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor"
                                                className="w-6 h-6 stroke-red-600 cursor-pointer">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                            </svg>
                                        </div>
                                    </td>
                                </tr>
                            )
                        }
                        <tr>
                            <td colSpan="5" className="px-6 py-3">
                                <div className="flex items-center justify-center">
                                    <div onClick={openAddClassModal} className="btn btn-primary btn-sm cursor-pointer">
                                        Add
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            strokeWidth="2" stroke="currentColor" className="w-4 h-4 stroke-white">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                d="M12 4.5v15m7.5-7.5h-15" />
                                        </svg>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </form>
    )
}

export default DesktopLessonDesign;