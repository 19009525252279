import React from "react";
import drivePro from '../../../assets/drivePro1.jpeg';
import { file } from '../../../assets/theorylessons/file1.pptx';
import { theoryLessonsFiles } from '../../../helpers/theoryTeachingList';
const TheoryLesson = (props) => {

    return (
        <>
            <div className="space-y-4">
                <div className="border divide-y bg-white shadow-sm font-sans">
                    {
                        theoryLessonsFiles && theoryLessonsFiles.map((item, key) => (
                            <div className="flex items-center justify-between p-3" >
                                <p className="text-gray-500 font-semibold">{item.name}</p>
                                <a href={item.filename} download target="_blank"><button className="btn btn-success btn-sm" >Download</button></a>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default TheoryLesson;