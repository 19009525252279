import React, {useEffect, useState} from 'react'
//Constants.
import {CrossSvg} from '../../../../constants'
import {deleteQuestionId, getQeustionaireByUserId, insertQuestionByUserId} from '../../../../services/schoolService';
import {useNavigate, useParams} from 'react-router-dom';
import MyStudents from "../../students/myStudents";

const Questionaire = (props) => {
    const navigate = useNavigate();
    const [questionaireData, setQuestionaireData] = useState([]);
    const params = useParams();
    const [questionField, setQuestionField] = useState('');
    const handleQuestionField = (e) => {
        setQuestionField(e.target.value);
    }
    useEffect(() => {
        const fetchQuestionaire = async () => {
            try {
                const questionaireData = await getQeustionaireByUserId(params['Id']);
                setQuestionaireData(questionaireData);
            } catch (error) {
                console.log(error);
            }
        };
        fetchQuestionaire();
    }, [])


    const insertQuestion = async () => {
        try {
            const payload = {
                question: questionField
            }
            if (questionField !== '') {
                const response = await insertQuestionByUserId(params['Id'], payload)
                const questionaireData = await getQeustionaireByUserId(params['Id']);
                setQuestionaireData(questionaireData);
                setQuestionField('');
            } else {
                console.log("enter data first")
            }
        } catch (error) {
            console.log(error)
        }
    }

    const deleteQuestion = async (Id) => {
        try {
            const response = await deleteQuestionId(Id)
            const questionaireData = await getQeustionaireByUserId(params['Id']);
            setQuestionaireData(questionaireData);
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div className="space-y-4">
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                    <div
                        onClick={() => {
                            navigate('/school/classes-and-packages?selectedTab=classTypes')
                        }}
                        className="h-10 w-10 border rounded-md flex items-center justify-center hover:bg-gray-50 duration-300 cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                             stroke="currentColor" className="w-5 h-5 stroke-gray-500">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"/>
                        </svg>
                    </div>
                    <h3 className="text-gray-600 lg:text-3xl text-2xl font-semibold">Question for Feedback</h3>
                </div>
            </div>
            <div className="flex items-center justify-between gap-4">
                <input
                    id="search"
                    name="search"
                    type="text"
                    placeholder="Enter Question for Feedback"
                    onChange={handleQuestionField}
                    value={questionField}
                    className="form-input w-full"
                />
                <button onClick={insertQuestion} type="button"
                        className="h-10 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm">
                    {'Create'}
                </button>
            </div>
            <div className="border rounded-md overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                    <tr className="bg-gray-50">
                        {/*<td className="px-6 py-3 text-xs font-semibold text-gray-500">
                            S.No
                        </td>*/}
                        <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                            Question
                        </td>
                        <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                            Status
                        </td>
                        <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                        </td>
                    </tr>
                    </thead>
                    <tbody key={questionaireData && questionaireData.length} id="table-body"
                           className="divide-y divide-gray-200">
                    {questionaireData && questionaireData.map((question, index) => {
                        return (
                            <tr>
                               {/* <td key={index}
                                    className="px-6 py-4 align-middle whitespace-nowrap text-gray-500 cursor-pointer hover:underline">
                                    {index + 1}
                                </td>*/}
                                <td className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                                    {question?.question}
                                </td>
                                <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                    {question.is_active ? "Active" : "In Active"}
                                </td>
                                <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                    <div className="flex justify-end gap-8">
                                        <svg onClick={() => deleteQuestion(question._id)}
                                             xmlns="http://www.w3.org/2000/svg" fill="none"
                                             viewBox="0 0 24 24" strokeWidth="1.5"
                                             stroke="currentColor" className="w-6 h-6 stroke-red-600 cursor-pointer">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"/>
                                        </svg>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Questionaire