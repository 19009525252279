//Libraries
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

//Servies
import {getAllInstructors, getSchoolInstructors, viewPendingRequests} from "../../../services/schoolService";

//Pages
import MyInstructors from "./myInstructors";
import RequestInstructors from "./requestInstructors";
import PendingRequests from "./pendingRequests";

const Instructors = () => {
    const [selectedTab, setSelectedTab] = useState('myInstructors');
    const [myInstructors, setMyInstructors] = useState([]);
    const [allInstructors, setAllInstructors] = useState([]);
    const [pendingRequests, setPendingRequests] = useState([]);
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const storedTab = searchParams.get('selectedTab');
        if (!storedTab) updateQueryParam(selectedTab);
    }, [])

    useEffect(() => {
        const fetchInstructors = async () => {
            try {
                const myInstructors = await getSchoolInstructors();
                setMyInstructors(myInstructors);
            } catch (error) {
                console.log(error);
            }
        };
        fetchInstructors();
    }, []);

    useEffect(() => {
        const fetchRequests = async () => {
            try {
                const pendingRequests = await viewPendingRequests();
                setPendingRequests(pendingRequests);
            } catch (error) {
                console.log(error);
            }
        };
        fetchRequests();
    }, []);

    useEffect(() => {
        const fetchInstructors = async () => {
            try {
                const allInstructors = await getAllInstructors();
                setAllInstructors(allInstructors);
            } catch (error) {
                console.log(error);
            }
        };
        fetchInstructors();
    }, []);

    const handleTabClick = (tabId, event) => {
        event.preventDefault();
        setSelectedTab(tabId);
        updateQueryParam(tabId);
    };

    const updateQueryParam = (tabId) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('selectedTab', tabId);
        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        window.history.pushState({path: newUrl}, '', newUrl);
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const storedTab = searchParams.get('selectedTab');
        if (storedTab) {
            setSelectedTab(storedTab);
        }
    }, [location.search]);

    return <>
        <div className="space-y-4">
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                    <h3 className="text-gray-600 lg:text-3xl text-2xl font-semibold">Instructors</h3>
                </div>
            </div>
            <div className="flex items-center border-b">
                <a href="#myInstructors"
                   className={selectedTab === 'myInstructors' ?
                       'border-b-2 border-b-blue-500 px-4 py-3 flex items-center gap-1 font-semibold md:text-sm text-xs text-blue-500 whitespace-nowrap' :
                       'px-4 py-3 flex items-center gap-1 text-gray-400 font-semibold md:text-sm text-xs whitespace-nowrap'}
                   onClick={(event) => handleTabClick('myInstructors', event)}
                >
                    <span>My Instructors</span>
                </a>
                <a href="#requestInstructors"
                   className={selectedTab === 'requestInstructors' ?
                       'border-b-2 border-b-blue-500 px-4 py-3 flex items-center gap-1 font-semibold md:text-sm text-xs text-blue-500 whitespace-nowrap' :
                       'px-4 py-3 flex items-center gap-1 text-gray-400 font-semibold md:text-sm text-xs whitespace-nowrap'}
                   onClick={(event) => handleTabClick('requestInstructors', event)}
                >
                    <span>Request Instructors</span>
                </a>
                <a href="#pendingRequest"
                   className={selectedTab === 'pendingRequest' ?
                       'border-b-2 border-b-blue-500 px-4 py-3 flex items-center gap-1 font-semibold md:text-sm text-xs text-blue-500 whitespace-nowrap' :
                       'px-4 py-3 flex items-center gap-1 text-gray-400 font-semibold md:text-sm text-xs whitespace-nowrap'}
                   onClick={(event) => handleTabClick('pendingRequest', event)}
                >
                    <span>Pending Requests</span>
                </a>
            </div>
            <div id="myInstructors" style={{display: selectedTab === 'myInstructors' ? 'block' : 'none'}}>
                <MyInstructors
                    myInstructorsList={myInstructors}
                    setMyInstructorsList={setMyInstructors}
                    allInstructors={allInstructors}
                    setAllInstructors={setAllInstructors}
                />
            </div>
            <div id="requestInstructors" style={{display: selectedTab === 'requestInstructors' ? 'block' : 'none'}}>
                <RequestInstructors
                    instructors={allInstructors}
                    setInstructors={setAllInstructors}
                    requests={pendingRequests}
                    setRequests={setPendingRequests}
                />
            </div>
            <div id="pendingRequest" style={{display: selectedTab === 'pendingRequest' ? 'block' : 'none'}}>
                <PendingRequests
                    requests={pendingRequests}
                    setRequests={setPendingRequests}
                    allInstructors={allInstructors}
                    setAllInstructors={setAllInstructors}
                />
            </div>
        </div>
    </>
}

export default Instructors