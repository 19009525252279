//Libraries
import React, {useState} from "react";
import {useNavigate} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';

//Services
import {searchAccountByEmail} from "../../../services/userService";
import drivePro from "../../../assets/drivePro1.jpeg";

export default function SearchAccount() {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    const navigateToSignIn = () => {
        navigate('/login')
    }

    const searchAccount = async () => {
        setEmailSent(false);
        const body = {
            email: email
        }
        setEmailSent(await searchAccountByEmail(body))
    }

    return (
        <>
            <div className="grid lg:grid-cols-2 min-h-screen bg-white">

                {/* Left Container */}
                <div className="lg:flex hidden flex-col items-center justify-center">
                    <div className="">
                        <div className="">
                            <img src={drivePro} alt="" className="xl:h-28 h-20 xl:w-48 w-36 object-contain"/>
                            <h2 className="xl:text-6xl text-5xl text-blue-500">Drive <span
                                className="text-gray-500">Book</span></h2>
                            {/*<svg className="xl:h-20 h-16 xl:w-20 w-20" viewBox="0 0 1024 1024" version="1.1"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M766.976 508.736c80.576 0 152.448 32.128 199.232 82.176" fill="#AEBCC3" />
                <path
                    d="M64.704 684.992c10.816 19.2 32.064 32.192 56.576 32.192h784.64c35.84 0 64.832-27.648 64.832-61.76v-17.408h-36.608a15.744 15.744 0 0 1-16.064-15.296V550.912a277.568 277.568 0 0 0-150.144-44.16h1.6l-55.04-0.256c-53.632-115.2-157.504-210.752-294.208-210.752-136.512 0-251.008 89.728-282.176 210.688h-16.832c-35.456 0-56.128 27.392-56.128 61.184"
                    fill="#3B82F6" />
                <path
                    d="M64.704 654.464h13.76a39.168 39.168 0 0 0 40.064-38.272v-17.6c0-21.12-17.92-38.208-40.064-38.208h-13.376"
                    fill="#71717A" />
                <path d="M160 684.992a101.632 96.832 0 1 0 203.264 0 101.632 96.832 0 1 0-203.264 0Z"
                      fill="#71717A" />
                <path d="M218.88 684.992a42.752 40.768 0 1 0 85.504 0 42.752 40.768 0 1 0-85.504 0Z"
                      fill="#FFFFFF" />
                <path d="M652.032 684.992a101.568 96.832 0 1 0 203.136 0 101.568 96.832 0 1 0-203.136 0Z"
                      fill="#71717A" />
                <path d="M710.912 684.992a42.752 40.768 0 1 0 85.504 0 42.752 40.768 0 1 0-85.504 0Z"
                      fill="#FFFFFF" />
                <path
                    d="M966.272 591.104v-0.192a257.92 257.92 0 0 0-48.192-40V622.72c0 8.448 7.232 15.296 16.064 15.296h36.608v-42.304l-4.48-4.608z"
                    fill="#71717A" />
                <path
                    d="M405.568 335.616c-104.896 6.336-191.296 76.8-216.64 170.816h216.64V335.616zM445.696 506.432h216.64c-41.216-86.848-117.12-159.616-216.64-170.048v170.048z"
                    fill="#FFFFFF" />
              </svg>*/}
                        </div>
                        <p className="xl:text-2xl text-xl text-gray-500">You're only a few seconds away from
                            learning!</p>
                    </div>
                </div>

                {/* Right Container */}
                <div className="flex flex-col items-center justify-center shadow-2xl bg-blue-500 py-6 md:px-8 px-4">
                    <div className="bg-white shadow-2xl md:min-w-[400px] min-w-full py-6 md:px-8 px-6 rounded-2xl">
                        <h3 className="text-gray-500 text-3xl tracking-wide text-center">Forgot Password</h3>
                        <form className="space-y-4 mt-6">
                            <div className="space-y-5">
                                <div className="space-y-1">
                                    <label className="form-label">
                                        Email Address
                                    </label>
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        onChange={e => setEmail(e.target.value)}
                                        value={email}
                                        className="form-input w-full"
                                    />
                                </div>
                            </div>
                            <button
                                className="btn btn-primary w-full"
                                type="submit"
                                onClick={searchAccount}
                            >
                                Send Email
                            </button>
                            <div className="text-sm text-gray-500">
                                Remember your password? <span onClick={navigateToSignIn}
                                                              className="text-blue-500 font-semibold hover:underline cursor-pointer">Sign In</span>
                            </div>
                            {emailSent &&
                                <>
                                    <p className="text-green-500 mt-2">Email sent successfully!</p>
                                    <p className="text-green-500">Reset your account password by clicking the link in
                                        email.</p>
                                </>
                            }
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </>
    );
}