//Libraries
import React from "react";

//Constants
import {CrossSvg, DashSvg, EditSvg, PlusSvg, TrashSvg} from "../../../constants";

const MobileLessonDesign = ({formik, classTypeList, openAddClassModal, openEditClassModal, removeClass, saveLessonPlan}) => {
    const getClassTypeName = (classId) => {
        if (classTypeList.length > 0) {
            const classType = classTypeList.find((classItem) => classItem._id === classId)
            return classType.name;
        } else return '';
    }
    return (
        <div className="space-y-4">
            <div className="flex items-center justify-between gap-2">
                <input
                    id="name"
                    name="name"
                    type="text"
                    key='name'
                    placeholder="Enter Lesson Plan Name"
                    onChange={formik.handleChange}
                    value={formik.values ? formik.values.name : ''}
                    className="form-input w-full"
                />
                <button className="btn btn-success" onClick={saveLessonPlan}>
                    Update
                    <svg className="w-4 h-4 fill-white" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M18.1716 1C18.702 1 19.2107 1.21071 19.5858 1.58579L22.4142 4.41421C22.7893 4.78929 23 5.29799 23 5.82843V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H18.1716ZM4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21L5 21L5 15C5 13.3431 6.34315 12 8 12L16 12C17.6569 12 19 13.3431 19 15V21H20C20.5523 21 21 20.5523 21 20V6.82843C21 6.29799 20.7893 5.78929 20.4142 5.41421L18.5858 3.58579C18.2107 3.21071 17.702 3 17.1716 3H17V5C17 6.65685 15.6569 8 14 8H10C8.34315 8 7 6.65685 7 5V3H4ZM17 21V15C17 14.4477 16.5523 14 16 14L8 14C7.44772 14 7 14.4477 7 15L7 21L17 21ZM9 3H15V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V3Z" />
                    </svg>
                </button>
            </div>
            <div className="space-y-4">
                {
                    formik.values && formik.values.classes.map((classType, index) =>
                        <div className="border rounded-md p-4" key={classType._id}>
                            <div className="flex justify-between">
                                <div className="space-y-0.5">
                                    <h5 className="leading-none text-base text-gray-500 font-semibold">
                                        Lesson
                                        Name: {classType.class_name.length > 20 ? classType.class_name.slice(0, 17) + '...' : classType.class_name}
                                    </h5>
                                    <p className="text-sm text-gray-500">Class
                                        Type: {classType.class_id ? getClassTypeName(classType.class_id) : ''}</p>
                                    <p className="text-xs text-gray-500">No. of Classes: {classType.class_qty}</p>
                                    <p className="text-xs text-gray-500">Description: {classType.description.length > 25 ? classType.description.slice(0, 22) + '...' : classType.description}</p>
                                </div>
                                <div className="flex items-start gap-2">
                                    <button
                                        onClick={e => openEditClassModal(index)}
                                        className="activate-user-btn">
                                        <EditSvg/>
                                    </button>
                                    <button
                                        onClick={e => removeClass(index)}
                                        className="deactivate-user-btn">
                                        <TrashSvg/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
            <div className="pb-2 flex items-center justify-between">
                <div className="package-row-breaker">
                    <DashSvg/>
                    <div className="cursor-pointer" onClick={openAddClassModal} style={{color: "var(--primary-color)"}}>
                        <PlusSvg/>
                    </div>
                    <DashSvg/>
                </div>
            </div>
        </div>
    )
}

export default MobileLessonDesign;