import React, { useEffect, useState } from 'react'
import { getAllClassTypes } from '../../../../services/schoolService';

const LessonPlan = (props) => {
    const [numberSeries, setNumberSeries] = useState([]);
    const [classTypeList, setClassTypeList] = useState([]);
    useEffect(() => {
        const fetchClassTypes = async () => {
            try {
                const classTypes = await getAllClassTypes();
                setClassTypeList(classTypes);
            } catch (error) {
                console.log(error);
            }
        };
        fetchClassTypes();
    }, [])
    const getClassTypeName = (classId) => {
        if (classTypeList.length > 0) {
            const classType = classTypeList.find((classItem) => classItem._id === classId)
            return classType.name;
        } else return '';
    }
    useEffect(() => {
        if (props.studentLesson) {
            let startingNumber = 1;
            props?.studentLesson?.classes?.forEach((classItem) => {

                const numbers = [];
                for (let i = 0; i < classItem.class_qty; i++) {
                    numbers.push(startingNumber)
                    startingNumber++;
                }
                const series = numbers.join(' + ');
                const updatedSeries = numberSeries;
                updatedSeries.push(series)
                setNumberSeries(updatedSeries);
            })
        }
    }, [props.studentLesson, numberSeries])
    return (
        <>
            {
                props?.studentLesson?.length && props?.studentLesson?.length === 0 ? <>No Record Found</> :
                    <div className="border rounded-md overflow-hidden">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead>
                                <tr className="bg-gray-50">
                                    <td className="px-6 py-3 text-left text-xs font-semibold text-gray-500">
                                        Lesson Name
                                    </td>
                                    <td className="px-6 py-3 text-left text-xs font-semibold text-gray-500">
                                        Class Type
                                    </td>
                                    {/* <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                        Student's Signature
                                    </td>
                                    <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                        Instructor's Signature
                                    </td> */}
                                </tr>
                            </thead>
                            <tbody id="table-body"
                                className="divide-y divide-gray-200">
                                {props?.studentLesson && props?.studentLesson?.classes?.map((classType, index) => {
                                    return (
                                        // <tr>
                                        //     <td colSpan="4" className="px-6 py-3 text-center text-red-500">No Instructor Found.</td>
                                        // </tr>
                                        <tr key={index}>
                                            <td key={`class_name${index}`}
                                                className="px-6 py-4 align-middle whitespace-nowrap text-gray-500 leading-4">
                                                <p className="font-semibold">
                                                    {
                                                        classType.class_name.length > 25
                                                            ?
                                                            classType.class_name.slice(0, 22) + '...'
                                                            :
                                                            classType.class_name
                                                    }
                                                </p>
                                                <span className="text-sm">
                                                    {
                                                        numberSeries && numberSeries[index] + ' '
                                                    }
                                                    =
                                                    {' ' + classType.class_qty} lessons of {classType.class_duration} min. ({classType.class_qty * classType.class_duration} min.)
                                                </span>
                                            </td>
                                            <td key={`class_id${index}`}
                                                className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                                                {classType.class_id ? getClassTypeName(classType.class_id) : ''}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
            }
        </>

    )
}

export default LessonPlan