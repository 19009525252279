import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import {
    getSchoolInfo,
    updateSchool,
    updateSchoolPassword,
    updateSchoolProfileImage,
    updateStudent
} from '../../../services/schoolService';
import profile from './../../../assets/profile.png';

const UserDetails = () => {
    const [mySchoolData, setMySchoolData] = useState([]);
    const [tempFile, setTempFile] = useState(undefined);
    const basicInfo = useFormik({
        initialValues: {
            _id: '',
            name: '',
            email: '',
            phone: '',
            address: '',
            city: '',
            state: '',
            country: '',
        },
        onSubmit: async values => {
            try {
                const updatedSchoolResponse = await updateSchool(values);
                const mySchoolInfo = await getSchoolInfo();
                setMySchoolData(mySchoolInfo)
            } catch (error) {
                console.log(error);
            }
        },
    });

    const passwordInfo = useFormik({
        initialValues: {
            _id: '',
            password: '',
            confirm_password: ''
        },
        onSubmit: async values => {
            const updatedSchoolResponse = await updateSchoolPassword(values);
        },
    });
    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };
    const handleProfilePicture = async (event) => {
        const file = event.target.files[0];
        setTempFile(URL.createObjectURL(event.target.files[0]));
        const base64 = await convertToBase64(file);
        uploadProfile.setFieldValue("upload_picture", base64);
    }

    const uploadProfile = useFormik({
        initialValues: {
            upload_picture: null
        },
        onSubmit: async values => {
            const updatedSchoolresponse = await updateSchoolProfileImage(values)
        },
    });

    useEffect(() => {
        const fetchSchoolInfo = async () => {
            try {
                const mySchoolInfo = await getSchoolInfo();
                setMySchoolData(mySchoolInfo)
            } catch (error) {
                console.log(error)
            }
        };
        fetchSchoolInfo();
    }, []);
    useEffect(() => {
        if (mySchoolData) {
            const school = mySchoolData[0];
            basicInfo.setValues(school)
            setTempFile(school?.img);
        }
    }, [mySchoolData])

    return (
        <>
            <div className="space-y-4">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                        <h3 className="text-gray-600 lg:text-3xl text-2xl font-semibold">Edit Profile</h3>
                    </div>
                </div>
                <div className='grid grid-cols-12 gap-8'>
                    <div className="xl:col-span-8 col-span-12">
                        {
                            basicInfo.values &&
                            <div className="border rounded-md p-5 divide-y">
                                <h5 className="text-gray-600 text-xl font-semibold">General Information</h5>
                                <div className="grid grid-cols-2 items-center py-4">
                                    <label className="form-label">Name</label>
                                    <input id="name" name="name" type='text' className="form-input w-full"
                                        value={basicInfo.values.name}
                                        onChange={basicInfo.handleChange} />
                                </div>

                                <div className="grid grid-cols-2 items-center py-4">
                                    <label className="form-label">Email</label>
                                    <input className='form-input w-full' id="email" name="email" type='text'
                                        value={basicInfo.values.email} onChange={basicInfo.handleChange} disabled />
                                </div>

                                <div className="grid grid-cols-2 items-center py-4">
                                    <label className="form-label">Phone</label>
                                    <input id="phone" name="phone" type='text' className='form-input w-full'
                                        value={basicInfo.values.phone}
                                        onChange={basicInfo.handleChange} />
                                </div>

                                <div className="grid grid-cols-2 items-center py-4">
                                    <label className="form-label">Address</label>
                                    <input className="form-input w-full" id="address" name="address" type='text'
                                        value={basicInfo.values.address}
                                        onChange={basicInfo.handleChange} />
                                </div>

                                <div className="grid grid-cols-2 items-center py-4">
                                    <label className="form-label">City</label>
                                    <input className="form-input w-full" id="city" name="city" type='text'
                                        value={basicInfo.values.city}
                                        onChange={basicInfo.handleChange} />
                                </div>

                                <div className="grid grid-cols-2 items-center py-4">
                                    <label className="form-label">State</label>
                                    <input className="form-input w-full" id="state" name="state" type='text'
                                        value={basicInfo.values.state}
                                        onChange={basicInfo.handleChange} />
                                </div>

                                <div className="grid grid-cols-2 items-center py-4">
                                    <label className="form-label">Country</label>
                                    <input className="form-input w-full" id="country" name="country" type='text'
                                        value={basicInfo.values.country}
                                        onChange={basicInfo.handleChange} />
                                </div>

                                <div className="py-4">
                                    <button type='submit' className="btn btn-success" onClick={() => {
                                        basicInfo.handleSubmit();
                                    }}>
                                        Update
                                        <svg className="w-4 h-4 fill-white" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                d="M18.1716 1C18.702 1 19.2107 1.21071 19.5858 1.58579L22.4142 4.41421C22.7893 4.78929 23 5.29799 23 5.82843V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H18.1716ZM4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21L5 21L5 15C5 13.3431 6.34315 12 8 12L16 12C17.6569 12 19 13.3431 19 15V21H20C20.5523 21 21 20.5523 21 20V6.82843C21 6.29799 20.7893 5.78929 20.4142 5.41421L18.5858 3.58579C18.2107 3.21071 17.702 3 17.1716 3H17V5C17 6.65685 15.6569 8 14 8H10C8.34315 8 7 6.65685 7 5V3H4ZM17 21V15C17 14.4477 16.5523 14 16 14L8 14C7.44772 14 7 14.4477 7 15L7 21L17 21ZM9 3H15V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V3Z" />
                                        </svg>
                                    </button>
                                </div>

                            </div>
                        }
                    </div>
                    <div className="xl:col-span-4 col-span-12 space-y-4">
                        {/*<div className="border rounded-md p-5">
                            <div className="">
                                <label htmlFor="upload_picture" className="h-60 w-full border rounded-md relative flex flex-col items-center">
                                    <img src={tempFile ? tempFile : profile} alt=""
                                        className="h-full w-full rounded-md object-contain" />
                                    <div
                                        className="flex items-center justify-center h-12 w-12 rounded-full bg-blue-500 cursor-pointer absolute -bottom-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            strokeWidth="2"
                                            stroke="currentColor" className="w-7 h-7 stroke-white">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z" />
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z" />
                                        </svg>
                                    </div>
                                    <input className='invisible' id="upload_picture" name="upload_picture" type='file' accept="image/*" onChange={handleProfilePicture} />
                                </label>
                                <div className="mt-4 flex items-center justify-end">
                                    <button type='submit' className="btn btn-success" onClick={() => { uploadProfile.handleSubmit(); }}>
                                        Update
                                        <svg className="w-4 h-4 fill-white" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                d="M18.1716 1C18.702 1 19.2107 1.21071 19.5858 1.58579L22.4142 4.41421C22.7893 4.78929 23 5.29799 23 5.82843V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H18.1716ZM4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21L5 21L5 15C5 13.3431 6.34315 12 8 12L16 12C17.6569 12 19 13.3431 19 15V21H20C20.5523 21 21 20.5523 21 20V6.82843C21 6.29799 20.7893 5.78929 20.4142 5.41421L18.5858 3.58579C18.2107 3.21071 17.702 3 17.1716 3H17V5C17 6.65685 15.6569 8 14 8H10C8.34315 8 7 6.65685 7 5V3H4ZM17 21V15C17 14.4477 16.5523 14 16 14L8 14C7.44772 14 7 14.4477 7 15L7 21L17 21ZM9 3H15V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V3Z" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>*/}
                        <div className="border rounded-md p-5">
                            <h5 className="text-gray-600 text-xl font-semibold border-b">Personal Information</h5>

                            <div className="space-y-2 mt-4">
                                <div className="space-y-1">
                                    <label className="form-label">Password</label>
                                    <input className="form-input w-full" id="password" name="password"
                                        type='password'
                                        value={passwordInfo.values.password}
                                        onChange={passwordInfo.handleChange} />
                                </div>
                                <div className="space-y-1">
                                    <label className="form-label">Confirm Password</label>
                                    <input className="form-input w-full" id="confirm_password"
                                        name="confirm_password"
                                        type='password'
                                        value={passwordInfo.values.confirm_password}
                                        onChange={passwordInfo.handleChange} />
                                </div>
                                <div className="flex items-center justify-end">
                                    <button type='submit' className="btn btn-success" onClick={() => {
                                        passwordInfo.handleSubmit();
                                    }}>
                                        Update
                                        <svg className="w-4 h-4 fill-white" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd"
                                                d="M18.1716 1C18.702 1 19.2107 1.21071 19.5858 1.58579L22.4142 4.41421C22.7893 4.78929 23 5.29799 23 5.82843V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H18.1716ZM4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21L5 21L5 15C5 13.3431 6.34315 12 8 12L16 12C17.6569 12 19 13.3431 19 15V21H20C20.5523 21 21 20.5523 21 20V6.82843C21 6.29799 20.7893 5.78929 20.4142 5.41421L18.5858 3.58579C18.2107 3.21071 17.702 3 17.1716 3H17V5C17 6.65685 15.6569 8 14 8H10C8.34315 8 7 6.65685 7 5V3H4ZM17 21V15C17 14.4477 16.5523 14 16 14L8 14C7.44772 14 7 14.4477 7 15L7 21L17 21ZM9 3H15V5C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V3Z" />
                                        </svg>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default UserDetails