import moment from 'moment';
import React, { useEffect, useState } from 'react'
import Calendar from "../../../../components/calendar/calendar";
import AppointmentModal from "./../../appointment/appointmentModal";
import { useFormik } from "formik";
import notify from "../../../../helpers/raiseToast";
import {
  createAppointment,
  deleteAppointmentById,
  editAppointmentById,
  getClassTypeList,
  getSchoolInstructors,
  getStudentsWithLessonPlan,
  getAppointmentsByUserId
} from "../../../../services/schoolService";
import { useParams } from 'react-router-dom';


const Calender = (props) => {
  const params = useParams();
  const [events, setEvents] = useState([]);
  const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState('');
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [classTypes, setClassTypes] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [studentsToAdd, setStudentsToAdd] = useState([]);
  const [studentsToRemove, setStudentsToRemove] = useState([]);


  const formik = useFormik({
    initialValues: {
      name: '',
      school_id: '',
      instructor_id: '',
      instructor_name: '',
      start_time: '',
      end_time: '',
      date: [],
      address: '',
      has_student: false,
      total_seats: 0,
      class_id: '',
      class_qty: 0,
      student: [],
      cancel_description: '',
      hexCode: '',
    },
    onSubmit: async values => {
      if (isValidForm(values)) {
        setIsAppointmentModalOpen(false);
        if (!isEditMode) {
          const response = await createAppointment(values)
          if (response && response.data && response.data.appointment_record) {
            const allAppointments = await getAppointmentsByUserId('SCHOOL', params['Id']);
            props.setAppointmentStudents(allAppointments);
          }
        }
        else {
          if (formik.values.created_by !== 'SCHOOL') {
            notify('ERROR', 'top-right', 'Access Denied: You can only edit appointments you created!')
          }
          else if (moment(selectedDate).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD'))
            notify('ERROR', 'top-right', 'Cannot Edit: Past Appointments are not editable!')
          else {
            const body = {
              name: values.name,
              address: values.address,
              start_time: values.start_time,
              end_time: values.end_time,
              date: values.date,
              instructor_id: values.instructor_id,
              instructor_name: values.instructor_name,
              students_to_add: studentsToAdd,
              students_to_remove: studentsToRemove,
              hexCode: values.hexCode
            }
            const response = await editAppointmentById(body, selectedAppointmentId);
            if (response && response.data && response.data.updated_appointment) {
              const allAppointments = await getAppointmentsByUserId('SCHOOL', params['Id']);
              props.setAppointmentStudents(allAppointments);
            }
          }
        }
        closeModal();
      }
      else {
        notify('ERROR', 'top-right', 'Check the missing fields');
      }
    },
  });




  useEffect(() => {
    if (props.appointmentStudents && selectedAppointmentId.length > 0) {
      const findAppointment = async () => {
        const appointment = props.appointmentStudents.find((appointmentItem) => appointmentItem._id === selectedAppointmentId)
        formik.setValues(appointment);
      }
      findAppointment();
    }
  }, [selectedAppointmentId])

  useEffect(() => {
    const fetchClassesAndStudentsAndInstructors = async () => {
      try {
        const students = await getStudentsWithLessonPlan();
        setStudents(students);

        const instructors = await getSchoolInstructors();
        setInstructors(instructors);

        const classTypes = await getClassTypeList();
        setClassTypes(classTypes);
      } catch (error) { }
    }
    fetchClassesAndStudentsAndInstructors();
  }, [])

  useEffect(() => {
    if (formik.values && formik.values.class_id) {
      const filteredStudents = students.filter((student) => student.class_id && student.class_id.includes(formik.values.class_id));
      setFilteredStudents(filteredStudents);
    }
    else setFilteredStudents([]);
  }, [formik.values.class_id, students])

  const deleteAppointment = async (appointmentId) => {
    const payload = {
      cancel_description: formik.values.cancel_description
    }
    try {
      if (await deleteAppointmentById(appointmentId, payload)) {
        const updatedAppointments = props.appointmentStudents.filter((appointment) => appointment._id !== selectedAppointmentId);
        props.setAppointmentStudents(updatedAppointments);
      }
    }
    catch (error) { }
  }

  const isValidForm = (values) =>
  (values.name.length > 0
    && values.start_time.length > 0
    && values.end_time.length > 0
    && values.date.length > 0
    && values.address.length > 0
    && values.class_id.length > 0
    && values.instructor_id.length > 0
    && values.total_seats > 0
    && values.class_qty > 0
    && moment(values.start_time, 'HH:mm').diff(moment(values.end_time, 'HH:mm')) < 0
  )

  const closeModal = () => {
    setIsAppointmentModalOpen(false);
    setStudentsToAdd([]);
    setStudentsToRemove([]);
    setSelectedAppointmentId('');
    formik.resetForm();
  }
  useEffect(() => {
    let id = 0;
    setEvents(props?.appointmentStudents?.flatMap((appointment) => {
      id++;
      return {
        id: id,
        title: appointment.name,
        instructor_name: appointment.instructor_name,
        heading: appointment.heading,
        hexCode: appointment.hexCode,
        start: moment(`${appointment.date} ${appointment.start_time}`, 'YYYY-MM-DD HH:mm A').format('YYYY-MM-DD HH:mm'),
        end: moment(`${appointment.date} ${appointment.end_time}`, 'YYYY-MM-DD HH:mm A').format('YYYY-MM-DD HH:mm'),
        appointment_id: appointment._id,
        fullyBooked: appointment.seats_remaining === 0,
        display: ""
      }

    }));
  }, [props.appointmentStudents])
  return (
    <>
      <Calendar
        setIsAppointmentModalOpen={setIsAppointmentModalOpen}
        setSelectedAppointmentId={setSelectedAppointmentId}
        setSelectedDate={setSelectedDate}
        setIsEditMode={setIsEditMode}
        events={events}
        removeAddAppointment={true}
      />
      {isAppointmentModalOpen &&
        <AppointmentModal
          isStudent={true}
          isAppointmentModalOpen={isAppointmentModalOpen}
          setIsAppointmentModalOpen={setIsAppointmentModalOpen}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          formik={formik}
          students={filteredStudents}
          instructors={instructors}
          classTypes={classTypes}
          selectedAppointmentId={selectedAppointmentId}
          setSelectedAppointmentId={setSelectedAppointmentId}
          selectedDate={selectedDate}
          deleteAppointment={deleteAppointment}
          studentsToAdd={studentsToAdd}
          setStudentsToAdd={setStudentsToAdd}
          studentsToRemove={studentsToRemove}
          setStudentsToRemove={setStudentsToRemove}
          closeModal={closeModal}
        />
      }
    </>
    // <Calendar
    //   events={events}
    //   setIsEditMode={true}
    // />
  )
}

export default Calender