//Libraries
import React from 'react';
import {Routes, Route} from 'react-router-dom';

//Components
import PrivateRoute from '../components/auth/privateRoute';
import Notifications from '../components/notificationListing/Notifications';

//Pages
import InstructorDashboard from '../pages/instructor/dashboard/instructorDashboard';
import InstructorRequests from '../pages/instructor/requests/instructorRequests';
import RequestTabs from '../pages/instructor/requests/requestTabs';
import MySchools from '../pages/instructor/schools/mySchools';
import Appointment from '../pages/instructor/appointment/appointment';
import UserDetails from '../pages/instructor/user-details/userDetails';
import MyStudents from '../pages/instructor/students/myStudents';
import StudentInfo from '../pages/instructor/students/StudentDetail/studentInfo';
import SchoolInfo from '../pages/instructor/schools/schoolDetail/schoolInfo';
import TheoryIndex from '../pages/student/theory/index';
import TheoryTeaching from '../pages/student/theory/teaching';

const InstructorRoutes = () => {
    return (
        <Routes>
            <Route element={<PrivateRoute allowedUserType='instructor'/>}>
                <Route path="/home" element={<InstructorDashboard/>}/>
                <Route path='/appointment' element={<Appointment/>}/>
                <Route path="/requests" element={<RequestTabs/>}/>
                <Route path='/my-schools' element={<MySchools/>}/>
                <Route path='/my-schools/:Id' element={<SchoolInfo/>}/>
                <Route path='/my-students' element={<MyStudents/>}/>
                <Route path='/my-students/:Id' element={<StudentInfo/>}/>
                <Route path='/notifications' element={<Notifications/>}/>
                <Route path='/user-details' element={<UserDetails/>}/>
                <Route path='/theory' element={<TheoryIndex/>}/>
                <Route path='/theory-teaching/:id/:type' element={<TheoryTeaching />}/>
            </Route>
        </Routes>
    );
};
export default InstructorRoutes