//Libraries
import { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

//Layouts
import StudentLayout from './layouts/StudentLayout';
import InstructorLayout from './layouts/InstructorLayout';
import SchoolLayout from './layouts/SchoolLayout';

//Pages
import Login from './pages/auth/login/login';
import LandingPage from './pages/landing/landingPage';
import Register from './pages/auth/register/register';
import InvitationRegister from './pages/auth/invitationRegister/invitationRegister';
import SearchAccount from './pages/auth/forgotPassword/searchAccount';
import UpdatePassword from './pages/auth/forgotPassword/updatePassword';


function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userType, setUserType] = useState('student')
  const [isSideBar, setIsSideBar] = useState(false);
  useEffect(() => {
    // Check if the user is already logged in
    const token = localStorage.getItem('accessToken');
    const userType = localStorage.getItem('user_type')
    if (token) {
      setIsLoggedIn(true);
      setUserType(userType)
    }
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={isLoggedIn ? <Navigate to={`/${userType}/home`} /> : <Login />} />
        <Route path="/login" element={isLoggedIn ? <Navigate to={`/${userType}/home`} /> : <Login />} />
        <Route path="/invitation-signup" element={<InvitationRegister />} />
        <Route path="/register" element={isLoggedIn ? <Navigate to={`/${userType}/home`} /> : <Register />} />
        <Route path="/recover-account" element={isLoggedIn ? <Navigate to={`/${userType}/home`} /> : <SearchAccount />} />
        <Route path="/user/update-password" element={isLoggedIn ? <Navigate to={`/${userType}/home`} /> : <UpdatePassword />} />
        {/* Layouts */}
        <Route path="/student/*" element={<StudentLayout isSideBar={isSideBar} setIsSideBar={setIsSideBar} />} />
        <Route path="/school/*" element={<SchoolLayout isSideBar={isSideBar} setIsSideBar={setIsSideBar} />} />
        <Route path="/instructor/*" element={<InstructorLayout isSideBar={isSideBar} setIsSideBar={setIsSideBar} />} />
      </Routes>
    </div>
  );
}

export default App;
