//Libraries
import React, {useEffect, useState} from "react";

//Components
import Modal from "../../../components/modal/Modal";

//Helpers
import {checkScreenSize} from "../../../helpers/screenSize";

//Services
import {changeRequestStatus, getStudentRequests} from "../../../services/studentService";

//Constants
import {AcceptSvg, CrossSvg} from "../../../constants";

const StudentRequests = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedSchoolId, setSelectedSchoolId] = useState(null);
    const [schoolRequests, setSchoolRequests] = useState([]);
    const [requestStatus, setRequestStatus] = useState('YES');
    const [modalTitle, setModalTitle] = useState('Accept Request');
    const [modalMessage, setModalMessage] = useState('Are you sure you want to accept request!');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(checkScreenSize(768));
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchRequests = async () => {
            try {
                const schoolRequests = await getStudentRequests();
                setSchoolRequests(schoolRequests);
            } catch (error) {
                console.log(error);
            }
        };
        fetchRequests();
    }, []);

    const openModal = (school_id, status) => {
        if (status) {
            setModalTitle('Accept Request')
            setModalMessage('Are you sure you want to accept request!');
            setRequestStatus('YES');
        } else {
            setModalTitle('Cancel Request')
            setModalMessage('Are you sure you want to cancel request!');
            setRequestStatus('NO');
        }
        setSelectedSchoolId(school_id)
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const changeStatus = async () => {
        let body = {
            status: requestStatus
        }
        await changeRequestStatus(body);
        const updatedRequests = schoolRequests.filter(
            school => school._id !== selectedSchoolId
        );
        setSchoolRequests(updatedRequests);
        closeModal();
    }

    return (
        <div>
            {!isMobile ?
                <div className="space-y-4">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center gap-4">
                            <h3 className="text-gray-600 lg:text-3xl text-2xl font-semibold">Requests</h3>
                        </div>
                    </div>
                    <div className="border rounded-md overflow-hidden">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead>
                            <tr className="bg-gray-50">
                                <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                    Name
                                </td>
                                <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                    Email
                                </td>
                                <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                    Location
                                </td>
                                <td></td>
                            </tr>
                            </thead>
                            <tbody id="table-body"
                                   className="divide-y divide-gray-200">
                            {schoolRequests && schoolRequests.map((school, index) => {
                                return (
                                    // <tr>
                                    //     <td colSpan="4" className="px-6 py-3 text-center text-red-500">No Instructor Found.</td>
                                    // </tr>
                                    <tr>
                                        <td key={index}
                                            className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                            {school.name}
                                        </td>
                                        <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                            {school.email}
                                        </td>
                                        <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                            {school.city} - {school.state} - {school.country}
                                        </td>
                                        <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                            <div className="flex justify-end gap-8">
                                                <svg onClick={e => openModal(school._id, false)}
                                                     xmlns="http://www.w3.org/2000/svg" fill="none"
                                                     viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                                     className="w-6 h-6 stroke-red-600 cursor-pointer">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          d="M6 18L18 6M6 6l12 12"/>
                                                </svg>
                                                <svg onClick={e => openModal(school._id, true)}
                                                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                     strokeWidth="1.5" stroke="currentColor"
                                                     className="w-6 h-6 stroke-green-600 cursor-pointer">
                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                          d="M4.5 12.75l6 6 9-13.5"/>
                                                </svg>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
                :
                <div className="card-container">
                    {schoolRequests && schoolRequests.map((school, index) => {
                        return (
                            <div className="card" key={index}>
                                <div className="card-body">
                                    <div className="left-container-card">
                                        <h5 className="card-title"><b>{school.name}</b></h5>
                                        <h6 className="card-subtitle mb-2 text-muted">{school.email}</h6>
                                        <p className="card-text">{school.city} - {school.state} - {school.country}</p>
                                    </div>
                                    <div className="right-container-card" style={{display: 'flex', gap: '10px'}}>
                                        <button
                                            onClick={e => openModal(school._id, true)}
                                            className="deactivate-user-btn">
                                            <CrossSvg/>
                                        </button>
                                        <button
                                            onClick={e => openModal(school._id, false)}
                                            className="activate-user-btn">
                                            <AcceptSvg/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>}
            {modalOpen && (
                <Modal
                    isOpen={true}
                    closeModal={closeModal}
                    title={modalTitle}
                    message={modalMessage}
                    onConfirmClick={changeStatus}
                />
            )}
        </div>
    )
}

export default StudentRequests;