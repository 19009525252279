import React, { useEffect, useState } from "react";
import { getDashboardDataService } from "../../../services/instructorService";
import ReactApexChart from "react-apexcharts";
import { calaulateDayAndDate, calculateAgeInTime } from "../../../helpers/utils";
import { useNavigate } from "react-router-dom";

const InstructorDashboard = () => {
    const [dashboardData, setDashboardData] = useState([]);
    const [paymentChartOptions, setPaymentChartOptions] = useState();
    const [paymentChartSeries, setPaymentChartSeries] = useState();
    const navigate = useNavigate();
    useEffect(() => {
        if (dashboardData?.totalIncomeByMonth) {
            let month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            let x = [];
            for (let i = 1; i <= month?.length; i++) {
                let total = 0
                for (let k = 0; k < dashboardData?.totalIncomeByMonth.length; k++) {
                    if (dashboardData?.totalIncomeByMonth[k].Dates?.month === i) {
                        total = dashboardData?.totalIncomeByMonth[k]?.Amount;
                        break;
                    }
                }
                x.push(total)
            }
            setPaymentChartOptions({
                options: {
                    chart: {
                        height: '100%',
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                    xaxis: {
                        categories: month,
                        type: 'category'
                    },

                }
            });
            setPaymentChartSeries(
                [{
                    name: "Earning",
                    data: x,
                }]
            )
        }
    }, [dashboardData])
    useEffect(() => {
        const fetchDashboardData = async () => {
            try {
                const dashboardDataResponse = await getDashboardDataService();
                setDashboardData(dashboardDataResponse);
            } catch (error) {
                console.log(error);
            }
        }
        fetchDashboardData();
    }, [])
    return (
        <div>
            <div className="lg:hidden">
                <h3 className="text-gray-600 text-2xl font-semibold">Welcome</h3>
                <h2 className="text-gray-600 text-3xl font-semibold">{localStorage.getItem('name')}!</h2>
            </div>
            <div className="md:space-y-6 space-y-4 md:mb-0 mb-14">
                <h3 className="text-gray-600 lg:text-3xl text-2xl font-semibold lg:block hidden">Welcome, {localStorage.getItem('name')}!</h3>
                <div className="space-y-4 md:hidden">
                    {
                        dashboardData?.todayAppointments && dashboardData?.todayAppointments.length === 0 ?
                            <p className="text-sm text-red-500 italic font-semibold underline underline-offset-2">No Appointment Found</p>
                            :
                            <p className="text-sm text-gray-500 italic font-semibold underline underline-offset-2">Today's Appointment!</p>}
                    {dashboardData?.todayAppointments && dashboardData?.todayAppointments.map((item, key) => (
                        <div onClick={() => {
                            navigate('/instructor/appointment')
                        }} key={key} className="w-full shadow-lg rounded-lg px-5 py-3 flex items-center gap-4 text-base bg-gray-50 relative">
                            <div
                                className="w-16 h-16 bg-white shadow-md rounded-lg flex flex-col items-center justify-center">
                                <span className="text-xs text-green-500 font-semibold">{calaulateDayAndDate(item?.date)?.day}</span>
                                <h4 className="text-4xl text-gray-500 font-semibold">{calaulateDayAndDate(item?.date)?.formattedDate}</h4>
                            </div>
                            <div className="flex flex-col gap-1.5">
                                <span className="text-sm text-gray-500 font-semibold">Appointment Name:{item?.name}</span>
                                <span className="text-xs text-gray-500 font-semibold">Start Time: <span className="font-extrabold">{item?.start_time}</span> | End Time: <span className="font-extrabold">{item?.end_time}</span></span>
                            </div>
                            {/*<span className="absolute right-5 top-3 text-xs text-gray-400">{calculateAgeInTime(item?.date).hours + " hrs " + calculateAgeInTime(item?.date).minutes + " mins " + calculateAgeInTime(item?.date).seconds + " secs"}</span>*/}
                        </div>
                    ))}
                </div>
                <div className="px-16 py-2 md:hidden">
                    <div className="border-b-2 border-b-gray-300"></div>
                </div>
                <div className="grid grid-cols-3 gap-6">
                    <div className="xl:col-span-2 col-span-3 xl:order-1 order-2 border rounded-md w-full">
                        <div className="border-b p-4 bg-gray-50 rounded-t-md">
                            <h4 className="font-semibold text-xl text-gray-600">Payments <span className="text-blue-500">(Last 30 Days)</span></h4>
                        </div>
                        {paymentChartOptions && paymentChartSeries &&
                            <div className="w-full h-80">
                                <ReactApexChart options={paymentChartOptions?.options} series={paymentChartSeries} type="bar" width={'100%'} height={'100%'} />
                            </div>
                        }
                    </div>
                    <div className="xl:col-span-1 col-span-3 xl:order-2 order-1 space-y-6">
                        <div className="w-full border rounded-lg p-5 flex items-center gap-4 text-base">
                            <div className="flex items-center justify-center w-14 h-14 bg-green-100 rounded-md">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                    stroke="currentColor" className="w-8 h-8 stroke-green-600">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                </svg>
                            </div>
                            <div>
                                <h1 className="font-semibold text-3xl text-gray-600">{dashboardData?.monthAppointments ? dashboardData?.monthAppointments : 0}</h1>
                                <h5 className="font-semibold text-gray-600">Booked Appointments</h5>
                            </div>
                        </div>
                        <div className="w-full border rounded-lg p-5 flex items-center gap-4 text-base">
                            <div className="flex items-center justify-center w-14 h-14 bg-cyan-100 rounded-md">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                    stroke="currentColor" className="w-8 h-8 stroke-cyan-600">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </div>
                            <div>
                                <h1 className="font-semibold text-3xl text-gray-600">Kr {dashboardData?.totalIncome}</h1>
                                <h5 className="font-semibold text-gray-600">Total Income</h5>
                            </div>
                        </div>
                        <div className="w-full border rounded-lg p-5 flex items-center gap-4 text-base">
                            <div className="flex items-center justify-center w-14 h-14 bg-blue-100 rounded-md">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                    stroke="currentColor" className="w-8 h-8 stroke-blue-600">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                                </svg>
                            </div>
                            <div>
                                <h1 className="font-semibold text-3xl text-gray-600">{dashboardData?.totalStudents}</h1>
                                <h5 className="font-semibold text-gray-600">Total Students</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border rounded-lg md:block hidden">


                    <div className="border-b p-4 rounded-t-md">
                        <h4 className="font-semibold text-xl text-gray-600">Today's <span className="text-blue-500">Appointment</span></h4>
                    </div>

                    <div className="overflow-hidden">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead>
                                <tr className="bg-gray-50">
                                    <td className="px-6 py-3 text-left text-xs font-semibold text-gray-500">
                                        Appointment Name
                                    </td>
                                    <td className="px-6 py-3 text-left text-xs font-semibold text-gray-500">
                                        Start Time
                                    </td>
                                    <td className="px-6 py-3 text-left text-xs font-semibold text-gray-500">
                                        End Time
                                    </td>
                                </tr>
                            </thead>
                            <tbody id="table-body"
                                className="divide-y divide-gray-200">
                                {
                                    dashboardData?.todayAppointments && dashboardData?.todayAppointments.length === 0 ?
                                        <tr>
                                            <td colSpan="5" className="px-6 py-3 text-center text-red-500">
                                                No Appointment found.
                                            </td>
                                        </tr> :
                                        dashboardData?.todayAppointments && dashboardData?.todayAppointments.map((item, key) => (
                                            <tr key={key}>
                                                <td className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                                                    {item.name}
                                                </td>
                                                <td className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                                                    {item.start_time}
                                                </td>
                                                <td className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                                                    {item.end_time}
                                                </td>
                                            </tr>))

                                }

                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div >

    )
}

export default InstructorDashboard;