//Libraries
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Components
import Modal from "../../../../components/modal/Modal";
import AlertModalBody from "../../../../components/modal/AlertModalBody";

//Constants
import { EditSvg, TrashSvg } from "../../../../constants";

//Helpers
import { checkScreenSize } from "../../../../helpers/screenSize";

//Services
import { deletePackageById, getPackagesList } from "../../../../services/schoolService";

const Packages = ({ classTypesList }) => {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [packages, setPackages] = useState([]);
    const [filteredPackages, setFilteredPackages] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState({});
    const [searchPackage, setSearchPackage] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(checkScreenSize(768));
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchPackages = async () => {
            try {
                const packages = await getPackagesList();
                setPackages(packages);
            } catch (error) {
                console.log(error);
            }
        };
        fetchPackages();
    }, [])

    useEffect(() => {
        if (!searchPackage) {
            setFilteredPackages(packages);
        } else {
            const filteredData = packages.filter((packageItem) => {
                const name = packageItem.name.toLowerCase();
                const searchValue = searchPackage.toLowerCase();
                return name.includes(searchValue)
            });

            setFilteredPackages(filteredData)
        }
    }, [searchPackage, packages])

    /* Delete Modal Functions */
    const openDeleteModal = (classType) => {
        setSelectedPackage(classType);
        setIsDeleteModalOpen(true);
    };

    const deletePackage = async () => {
        await deletePackageById(selectedPackage._id);

        const updatedClassTypes = packages.filter(
            classType => classType._id !== selectedPackage._id
        );

        setPackages(updatedClassTypes);
        setIsDeleteModalOpen(false);
    }

    const DeleteModalComponent = () => {
        return (<AlertModalBody message={`Are you sure you want to remove this class type?`} />)
    }

    const closeDeleteModal = () => {
        setSelectedPackage({});
        setIsDeleteModalOpen(false);
    };
    /* Delete Modal Functions */

    const navigateToPackage = (packageId = undefined) => {
        if (packageId != undefined) navigate(`package/${packageId}`);
        else navigate('package');
    }

    return (
        <div>
            {!isMobile ?
                <div className="space-y-4">
                    <div className="flex items-center justify-between">
                        <div className="relative">
                            <input
                                id="search"
                                name="search"
                                type="text"
                                placeholder="Search package..."
                                onChange={event => setSearchPackage(event.target.value)}
                                value={searchPackage}
                                className="form-input-icon w-96"
                            />
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-5 h-5 stroke-gray-500 absolute inset-0 top-2.5 left-2">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                            </svg>
                        </div>
                        <button onClick={() => navigateToPackage()} type="button"
                            className="h-10 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm">
                            Create Package
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2"
                                stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>
                        </button>
                    </div>
                    <div className="border rounded-md overflow-hidden">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead>
                                <tr className="bg-gray-50">
                                    <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                                        Name
                                    </td>
                                    <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                        Price
                                    </td>
                                    <td className="px-6 py-3 text-center text-xs font-semibold text-gray-500">
                                        Classes
                                    </td>
                                    <td>
                                    </td>
                                </tr>
                            </thead>
                            <tbody key={filteredPackages && filteredPackages.length} id="table-body"
                                className="divide-y divide-gray-200">
                                {filteredPackages && filteredPackages.map((item, index) => {
                                    return (
                                        // <tr>
                                        //     <td colSpan="4" className="px-6 py-3 text-center text-red-500">No Instructor Found.</td>
                                        // </tr>
                                        <tr>
                                            <td key={index}
                                                className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                                                {item.name}
                                            </td>
                                            <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                                Kr {parseFloat(item.price_after_discount).toFixed(2)}
                                            </td>
                                            <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                                {item.classes.length && item.classes.reduce((total, num) => {
                                                    return total + parseInt(num.quantity)
                                                }, 0)}
                                            </td>
                                            <td className="px-6 py-4 text-center align-middle whitespace-nowrap text-gray-500">
                                                <div className="flex justify-end gap-8">
                                                    <svg onClick={() => navigateToPackage(item._id)}
                                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                        strokeWidth="1.5"
                                                        stroke="currentColor"
                                                        className="w-6 h-6 stroke-gray-500 cursor-pointer">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                                    </svg>
                                                    <svg onClick={e => openDeleteModal(item)}
                                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                        strokeWidth="1.5"
                                                        stroke="currentColor"
                                                        className="w-6 h-6 stroke-red-600 cursor-pointer">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                    </svg>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                :
                <div className="space-y-4">
                    <div className="flex items-center justify-between gap-4">
                        <div className="relative w-full">
                            <input
                                id="search"
                                name="search"
                                type="text"
                                placeholder="Search Package"
                                onChange={event => setSearchPackage(event.target.value)}
                                value={searchPackage}
                                className="form-input-icon w-full"
                            />
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-5 h-5 stroke-gray-500 absolute inset-0 top-2.5 left-2">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                            </svg>
                        </div>
                        <button onClick={() => navigateToPackage(undefined)} className="btn btn-primary">New
                            Package
                        </button>
                    </div>
                    {filteredPackages && filteredPackages.map((item, index) => {
                        return (
                            <div className="border rounded-md p-4" key={index}>
                                <div className="flex justify-between">
                                    <div className="space-y-0.5">
                                        <h5 className="leading-none text-base text-gray-500 font-semibold">Name: {item.name}</h5>
                                        <p className="text-sm text-gray-500">Price: ${item.price_after_discount}</p>
                                        <p className="text-xs text-gray-500">Classes: {item.classes.length}</p>
                                    </div>
                                    <div className="flex items-start gap-2">
                                        <button
                                            onClick={() => navigateToPackage(item._id)}
                                            className="activate-user-btn"
                                        >
                                            <EditSvg />
                                        </button>
                                        <button
                                            onClick={e => openDeleteModal(item)}
                                            className="deactivate-user-btn">
                                            <TrashSvg />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            }
            {isDeleteModalOpen && (
                <Modal
                    isOpen={isDeleteModalOpen}
                    closeModal={closeDeleteModal}
                    title={'Remove Package'}
                    component={DeleteModalComponent}
                    showComponent={true}
                    onConfirmClick={deletePackage}
                />
            )}
        </div>
    )
}

export default Packages;