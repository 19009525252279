//Libraries
import React, {useEffect, useState} from "react";
import moment from 'moment'

//Components
import Modal from "../../../components/modal/Modal";

//Services
import {
    assignStudentClass,
    assignStudentPackage,
    getClassTypeList,
    getPackagesList
} from "../../../services/schoolService";

const AssignStudentPackage = ({studentId, setStudentPackageModelOpen}) => {
    const [packagesList, setPackagesList] = useState([]);
    const [classTypesList, setClassTypesList] = useState([]);
    const [classPackageDropdownSelection, setClassPackageDropdownSelection] = useState('Class');
    const [selectedClassId, setSelectedClassId] = useState('');
    const [selectedPackageId, setSelectedPackageId] = useState('');
    const [numberOfClasses, setNumberOfClasses] = useState(1);
    const classPackageDropdown = [
        'Class',
        'Package'
    ]

    useEffect(() => {
        const fetchPackages = async () => {
            try {
                const packagesList = await getPackagesList();
                setPackagesList(packagesList);
            } catch (error) {
                console.log(error);
            }
        };
        fetchPackages();
    }, []);

    useEffect(() => {
        const fetchClassTypesList = async () => {
            try {
                const classTypes = await getClassTypeList();
                setClassTypesList(classTypes);
            } catch (error) {
                console.log(error);
            }
        };
        fetchClassTypesList();
    }, []);

    const isValidForm = () => {
        let isValid = true;

        if ((classPackageDropdownSelection === 'Class' && (selectedClassId === '' || numberOfClasses < 1)) ||
            (classPackageDropdownSelection === 'Package' && selectedPackageId === '')) isValid = false;

        return isValid;
    }

    const assignPackage = async () => {
        if (isValidForm()) {
            if (classPackageDropdownSelection === 'Class') {
                const body = {
                    class_id: selectedClassId,
                    student_id: studentId,
                    qty: numberOfClasses
                }

                await assignStudentClass(body)
            } else {
                const filteredPackage = packagesList.find((packageItem) => packageItem._id === selectedPackageId);

                const body = {
                    package_id: selectedPackageId,
                    has_package: true,
                    student_id: studentId,
                    classes: []
                }

                filteredPackage.classes.forEach((classItem) => {
                    const filteredClass = classTypesList.find((classTypeItem) => classTypeItem._id === classItem.class_id)
                    body.classes.push({
                        class_id: classItem.class_id,
                        class_name: filteredClass.name,
                        is_unlimited: classItem.is_unlimited,
                        is_unlimited_expiry: classItem.is_unlimited ? moment().add(1, 'year') : null,
                        unlimited_class_remaining: classItem.is_unlimited ? classItem.quantity : 0,
                        last_free_class: false,
                        unlimited_class_finished: false
                    })
                })

                await assignStudentPackage(body);
            }
            closeModal();
        }
    }

    const closeModal = () => {
        setSelectedClassId('');
        setSelectedPackageId('');
        setClassPackageDropdownSelection('Class');
        setNumberOfClasses(1);
        setStudentPackageModelOpen(false);
    }

    const assignPackageComponent = () => <>
        <div className="space-y-4">
            <div className="space-y-1">
                <label className="form-label">Select Class/Package</label>
                <select
                    id='class_id'
                    name='class_id'
                    className="form-select w-1/2"
                    onChange={(event) => setClassPackageDropdownSelection(event.target.value)}
                    value={classPackageDropdownSelection}
                >
                    <option disabled value="">
                        - Select -
                    </option>
                    {
                        classPackageDropdown && classPackageDropdown.map((dropdownItem) => <option key={dropdownItem}
                                                                                                   value={dropdownItem}>{dropdownItem}</option>)
                    }
                </select>
            </div>
            {classPackageDropdownSelection === 'Class' &&
                <div className="grid grid-cols-2 gap-4">
                    <div className="space-y-1">
                        <label className="form-label">Select Class Type</label>
                        <select
                            id='class_id'
                            name='class_id'
                            className="form-select w-full"
                            value={selectedClassId}
                            onChange={event => setSelectedClassId(event.target.value)}
                        >
                            <option disabled value="">
                                - Select Class -
                            </option>
                            {
                                classTypesList && classTypesList.map((classTypeItem) => <option key={classTypeItem._id}
                                                                                                value={classTypeItem._id}>{classTypeItem.name}</option>)
                            }
                        </select>
                    </div>
                    <div className="space-y-1">
                        <label className="form-label">No. of Classes</label>
                        <input
                            id="quantity"
                            name="quantity"
                            type="number"
                            className="form-input w-full"
                            value={numberOfClasses}
                            onChange={(event) => setNumberOfClasses(parseInt(event.target.value))}
                        />
                    </div>
                </div>}
            {classPackageDropdownSelection === 'Package' &&
                <div className="space-y-1">
                    <label className="form-label">Package</label>
                    <select
                        id='package'
                        name='package'
                        className="form-select w-full"
                        value={selectedPackageId}
                        onChange={event => setSelectedPackageId(event.target.value)}
                    >
                        <option disabled value="">
                            - Select Package -
                        </option>
                        {
                            packagesList && packagesList.map((packageItem) => <option key={packageItem._id}
                                                                                      value={packageItem._id}>{packageItem.name}</option>)
                        }
                    </select>
                </div>}
        </div>

    </>

    return <>
        <Modal
            isOpen={true}
            closeModal={closeModal}
            title={'Assign Class/Package'}
            component={assignPackageComponent}
            showComponent={true}
            onConfirmClick={assignPackage}
        />
    </>
}

export default AssignStudentPackage