//Libraries
import React, { useState, createContext, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

//Routes
import StudentRoutes from '../routes/studentRoutes';

//Components
import Sidebar from '../components/sidebar/Sidebar';
import Navbar from '../components/navbars/Navbar';

//Constants
import { STUDENT_SIDEBAR_OPTIONS } from '../constants';
import { getCreditHistoryDataOnUserId } from '../services/studentService';

export const StudentContext = createContext("");


const StudentLayout = ({ isSideBar, setIsSideBar }) => {
    const [creditAvailable, setCreditAvailable] = useState(0);
    useEffect(() => {
        async function fetchCreditHistory() {
            try {
                const studentCredit = await getCreditHistoryDataOnUserId();
                if (studentCredit.length > 0) {
                    setCreditAvailable(Math.abs(studentCredit[0]?.total_remaining_credit))
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchCreditHistory();
    }, [creditAvailable])
    return (
        <>
            <StudentContext.Provider value={{ creditAvailable, setCreditAvailable }}>
                <div className="flex flex-row">
                    <Sidebar isSideBar={isSideBar} setIsSideBar={setIsSideBar} sidebarOptions={STUDENT_SIDEBAR_OPTIONS} />
                    <div className="flex-1 min-h-screen">
                        <Navbar isSideBar={isSideBar} setIsSideBar={setIsSideBar} />
                        <div className="container py-5 mb-16">
                            <StudentRoutes />
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </StudentContext.Provider>
        </>
    );
};

export default StudentLayout;
