//Libraries
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { PDFDownloadLink } from "@react-pdf/renderer";

//Pages
import DesktopLessonDesign from "./lesson-desktop-view";
import MobileLessonDesign from "./lesson-mobile-view";
import { LessonPlanDocument } from "./lessonPlanPDF";

//Components
import SignaturePad from "../../../../components/signature/signature";
import Modal from "../../../../components/modal/Modal";
import notify from './../../../../helpers/raiseToast';

//Services
import {
    SignLessonForMultipleStudents,
    getAllClassTypesInstructor,
    getQuestionaireOnId,
    getLessonPlanStudents
} from "../../../../services/instructorService";

//Helpers
import { checkScreenSize } from "../../../../helpers/screenSize";

//Constants
import PdfSvg from '../../../../assets/Pdf.svg'
import { getLessonByInstructorId } from "../../../../services/instructorService";

const LessonPlan = (props) => {
    const params = useParams();

    const [ActiveLesson, setActiveLession] = useState(null);
    const [questionRating, setQuestionRating] = useState({});
    const [instructorDate, setInstructorDate] = useState('');
    const [stars, setStars] = useState([1, 2, 3, 4, 5]);
    const handleChangeRatingInput = (_id, e) => {

        setQuestionRating(prev => ({ ...prev, [_id]: e }));
    }
    const [lessonPlan, setLessonPlan] = useState({
        classes: []
    });
    const [isMobile, setIsMobile] = useState(false);
    const [classTypeList, setClassTypeList] = useState([]);
    const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);
    const [selectedClass, setSelectedClass] = useState({});
    const sigCanvasRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(checkScreenSize(768));
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const [questionaireData, setQuestionaireData] = useState();
    useEffect(() => {
        const fetchQuestionaire = async () => {
            try {
                if (ActiveLesson?.class_id) {
                    const questionaire = await getQuestionaireOnId(ActiveLesson?.class_id);
                    setQuestionaireData(questionaire);
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchQuestionaire();
    }, [ActiveLesson])


    useEffect(() => {
        const fetchLesson = async () => {
            try {
                const lessonPlan = await getLessonByInstructorId();
                if (Object.keys(lessonPlan).length > 0) {
                    let classesTemp = [];
                    for (let i = 0; i < lessonPlan.classes.length; i++) {
                        let tempClass = lessonPlan.classes[i];
                        if (!tempClass.signedKey) {
                            classesTemp.push(tempClass);
                        }
                    }
                    setLessonPlan({ classes: classesTemp });
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchLesson();
    }, [])

    useEffect(() => {
        const fetchClassTypes = async () => {
            try {
                const classTypes = await getAllClassTypesInstructor();
                setClassTypeList(classTypes);
            } catch (error) {
                console.log(error);
            }
        };
        fetchClassTypes();
    }, [])
    const [students, setStudents] = useState([]);
    const [classId, setClassId] = useState('');
    useEffect(() => {
        if (classId !== '') {
            const getLessonPlanStudentsFunction = async () => {
                try {
                    const students = await getLessonPlanStudents(classId);
                    let studentOptions = students.map((item) => ({
                        id: item.student_id,
                        name: item.student_name,
                        isChecked: false
                    }))
                    setStudents(studentOptions);
                } catch (error) {
                    console.log(error);
                }
            };
            getLessonPlanStudentsFunction();
        }
    }, [classId])
    const handleSelectAllChange = (event) => {
        if (event.target.checked) {
            let tempStudents = [...students];
            tempStudents = tempStudents.map((item) => ({ ...item, isChecked: true }));
            setStudents(tempStudents);
        } else {
            let tempStudents = [...students];
            tempStudents = tempStudents.map((item) => ({ ...item, isChecked: false }));
            setStudents(tempStudents);
        }
    }
    const handleCheckboxChange = (event, option) => {
        if (event.target.checked) {
            let tempStudents = [...students];
            tempStudents = tempStudents.map((item) => {
                if (option.id === item.id) {
                    return { ...item, isChecked: true }
                } else {
                    return { ...item }
                }
            })

            setStudents(tempStudents);
        } else {
            let tempStudents = [...students];
            tempStudents = tempStudents.map((item) => {
                if (option.id === item.id) {
                    return { ...item, isChecked: false }
                } else {
                    return { ...item }
                }
            })
            setStudents(tempStudents);
        }

    }
    const checkAllSelected = () => {
        return students.every(item => item.isChecked === true);

    }

    const signaturePadComponent = () => {
        return <SignaturePad
            topComponent={
                <>
                    <div className="space-y-4">

                        <div className="flex items-center gap-2">
                            <input type="checkbox" value={checkAllSelected} onChange={(event) => {
                                handleSelectAllChange(event)
                            }} />
                            <label className="form-label">
                                Select All
                            </label>
                        </div>
                        <div className="grid grid-cols-3 gap-y-2 border py-2 bg-gray-50 px-4">
                            {students.length > 0 ? students.map(option => (
                                <div className="flex items-center gap-2">
                                    <input type="checkbox" checked={option.isChecked} onChange={(event) => {
                                        handleCheckboxChange(event, option)
                                    }} />
                                    <label className="form-label" key={option}>
                                        {option.name}
                                    </label>
                                </div>
                            )) : 'No Student Found'}
                        </div>

                    </div>
                    <div className="space-y-1">
                        <label className="form-label">Select Date:</label>
                        <input type="date" className="form-input w-full" value={instructorDate} onChange={(e) => {
                            setInstructorDate(e.target.value)
                        }} />
                    </div>
                </>
            }
            questionareComponent={
                <>
                    {
                        questionaireData && questionaireData.length === 0 ? "" :
                            <>

                                <div className="space-y-1">
                                    <label className="text-sm font-semibold text-gray-600">Feedback: </label>
                                    <ul className="px-2 space-y-2">
                                        {questionaireData && questionaireData.map((item, key) => {
                                            return (
                                                <li key={key} className="flex items-center justify-between">
                                                    <div className="text-sm">{key + 1}. {item.question} </div>
                                                    <div className="flex items-center gap-0.5">
                                                        {
                                                            stars.map((item1, key1) => (
                                                                <svg key={key1} xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none" viewBox="0 0 24 24" strokeWidth="1"
                                                                    stroke="currentColor"
                                                                    className={`w-5 h-5 cursor-pointer ${(questionRating[item.question] > key1) ? 'fill-yellow-600 stroke-white' : ''}`}
                                                                    onClick={(e) => handleChangeRatingInput(item.question, item1)}>
                                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                                        d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                                                                </svg>
                                                            ))
                                                        }
                                                    </div>
                                                </li>
                                            )
                                        })}

                                    </ul>
                                </div>
                            </>
                    }
                </>
            }
            sigCanvasRef={sigCanvasRef}
        />
    }
    const isValidate = (payload) => {
        if (payload.student_lesson_class_id === '') {
            notify('ERROR', 'top-right', 'class missing');
            return true;
        }
        if (Object.keys(payload.questionRating).length === 0) {
            notify('ERROR', 'top-right', 'question rating missing');
            return true;
        }
        if (sigCanvasRef.current.isEmpty()) {
            notify('ERROR', 'top-right', 'signature missing');
            return true;
        }
        if (payload.instructor_signature_date === '') {
            notify('ERROR', 'top-right', 'instructor date missing');
            return true;
        }
        if (payload.student_ids.length === 0) {
            notify('ERROR', 'top-right', 'select atleast one student');
            return true;
        }
        return false;
    }
    const saveSignature = async () => {
        const signatureImage = sigCanvasRef.current.toDataURL();
        const body = {
            student_lesson_class_id: selectedClass._id,
            instructor_signature: signatureImage,
            questionRating: questionRating,
            instructor_signature_date: instructorDate,
            student_ids: students.map((item) => {
                if (item.isChecked) {
                    return item.id
                }
            })
        }
        if (isValidate(body)) { return }
        if (await SignLessonForMultipleStudents(body)) {
            const fetchLesson = async () => {
                try {
                    const lessonPlan = await getLessonByInstructorId();
                    if (Object.keys(lessonPlan).length > 0)
                        setLessonPlan(lessonPlan);
                } catch (error) {
                    console.log(error);
                }
            };
            fetchLesson();
        }

        closeModal();
    };
    const closeModal = () => {
        setIsSignatureModalOpen(false);
        setSelectedClass({});
        sigCanvasRef.current.clear();
    }

    return (
        <>
            {
                isSignatureModalOpen &&
                <Modal
                    isOpen={isSignatureModalOpen}
                    closeModal={closeModal}
                    title={ActiveLesson?.class_name}
                    component={signaturePadComponent}
                    showComponent={true}
                    onConfirmClick={saveSignature}
                />
            }
            <div className="space-y-4">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                        <h4 className="text-gray-600 text-2xl font-semibold">Lesson Plan</h4>
                    </div>
                    {
                        Object.keys(lessonPlan).length > 1 ?
                            <PDFDownloadLink
                                document={
                                    <LessonPlanDocument lessonPlan={lessonPlan} />
                                }
                                fileName={`${lessonPlan ? lessonPlan.lesson_id : ''}.pdf`}
                            >
                                {({ blob, url, loading, error }) => (loading ? 'Loading document...' :
                                    <img className="w-8 h-8" src={PdfSvg} alt="pdf-svg" />)}
                            </PDFDownloadLink>
                            : ""
                    }
                </div>
                {!isMobile
                    ?
                    <DesktopLessonDesign
                        setActiveLession={setActiveLession}
                        questionaireData={questionaireData}
                        lessonPlan={lessonPlan}
                        classTypeList={classTypeList}
                        setIsSignatureModalOpen={setIsSignatureModalOpen}
                        setSelectedClass={setSelectedClass}
                        setClassId={setClassId}
                    />
                    :
                    <MobileLessonDesign
                        lessonPlan={lessonPlan}
                        classTypeList={classTypeList}
                        setIsSignatureModalOpen={setIsSignatureModalOpen}
                        setSelectedClass={setSelectedClass}
                        setClassId={setClassId}
                    />
                }
            </div>
        </>
    )
}

export default LessonPlan;