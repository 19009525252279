import React, { useEffect, useState } from 'react'

import formatDateTime from '../../../../helpers/utils';

const GeneralInfo = (props) => {
  return (
    <>
      <div className="">
        <div className="divide-y xl:w-1/2 border-b">
          <div className="grid grid-cols-2 py-4">
            <label className="form-label" htmlFor="">Name:</label>
            <p className="text-gray-500">{props.schoolData?.name}</p>
          </div>
          <div className="grid grid-cols-2 py-4">
            <label className="form-label" htmlFor="">Email:</label>
            <p className="text-gray-500">{props.schoolData?.email}</p>
          </div>
          <div className="grid grid-cols-2 py-4">
            <label className="form-label" htmlFor="">Phone:</label>
            <p className="text-gray-500">{props.schoolData?.phone}</p>
          </div>
          <div className="grid grid-cols-2 py-4">
            <label className="form-label" htmlFor="">Address:</label>
            <p className="text-gray-500">{props.schoolData?.address}</p>
          </div>
          <div className="grid grid-cols-2 py-4">
            <label className="form-label" htmlFor="">Joined On:</label>
            <p className="text-gray-500">{formatDateTime(props.schoolData?.createdAt)}</p>
          </div>
          <div className="grid grid-cols-2 py-4">
            <label className="form-label" htmlFor="">Status:</label>
            {
              props.schoolData?.is_active ?
                <span className="text-xs px-4 py-0.5 bg-green-500 text-white w-fit rounded-full">Active</span> :
                <span className="text-xs px-4 py-0.5 bg-red-500 text-white w-fit rounded-full">Inactive</span>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default GeneralInfo