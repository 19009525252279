//Libraries
import React from 'react';
import { Routes, Route } from 'react-router-dom';

//Components
import PrivateRoute from '../components/auth/privateRoute';
import Notifications from '../components/notificationListing/Notifications';

//Pages
import SchoolDashboard from '../pages/school/dashboard/schoolDashboard';
import Instructors from '../pages/school/instructors/instructors';
import Students from '../pages/school/students/student';
import ClassesAndPackages from '../pages/school/classes-and-packages/main';
import AddClassType from '../pages/school/classes-and-packages/class-types/addClassTypeModal';
import Package from '../pages/school/classes-and-packages/packages/package';
import LessonPlan from '../pages/school/lessonPlan/lessonPlan';
import LessonPlans from '../pages/school/lessonPlan/lessonPlans';
import Appointment from '../pages/school/appointment/appointment';
import UserDetails from '../pages/school/user-details/userDetails';
import StudentInfo from '../pages/school/students/StudentDetail/studentInfo';
import InstructorInfo from '../pages/school/instructors/instructorDetail/instructorInfo';
import Questionaire from '../pages/school/classes-and-packages/class-types/questionaire';
import TheoryIndex from '../pages/student/theory/index';
import TheoryTeaching from '../pages/student/theory/teaching';

const SchoolRoutes = () => {
  return (
    <Routes>
      <Route element={<PrivateRoute allowedUserType='school' />}>
        <Route path="/home" element={<SchoolDashboard />} />
        <Route path='/appointment' element={<Appointment />} />
        <Route path='/instructors' element={<Instructors />} />
        <Route path='/instructors/:Id' element={<InstructorInfo />} />
        <Route path='/students' element={<Students />} />
        <Route path='/students/:Id' element={<StudentInfo />} />
        <Route path='/classes-and-packages' element={<ClassesAndPackages />} />
        <Route path='/classes-and-packages/class-type/:classTypeId' element={<AddClassType />} />
        <Route path='/classes-and-packages/package' element={<Package />} />
        <Route path='/classes-and-packages/package/:packageId' element={<Package />} />
        <Route path='/lesson-plans' element={<LessonPlans />} />
        <Route path='/lesson-plans/plan' element={<LessonPlan />} />
        <Route path='/lesson-plans/plan/:lessonPlanId' element={<LessonPlan />} />
        <Route path='/notifications' element={<Notifications />} />
        <Route path='/user-details' element={<UserDetails />} />
        <Route path='/questionaire/:Id' element={<Questionaire />} />
        <Route path='/theory' element={<TheoryIndex />} />
        <Route path='/theory-teaching/:id/:type' element={<TheoryTeaching />} />
      </Route>
    </Routes>
  );
};
export default SchoolRoutes