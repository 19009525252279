//Libraries
import React, { Fragment, useEffect, useState } from "react";
import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import { mainStyle, rowStyle, titleStyle } from "./lessonPlanPdfStyle";
import moment from "moment";

//drivePro
// import drivePro from '../../../assets/logo192.png'
import drivePro from '../../../assets/drivePro1.jpeg';

export const LessonPlanDocument = ({ lessonPlan }) => {
    const [numberSeries, setNumberSeries] = useState([]);
    const [schoolData, setSchoolData] = useState([]);
    const [studentData, setStudentData] = useState([]);
    useEffect(() => {
        if (Object.keys(lessonPlan).length > 1) {
            let startingNumber = 1;
            lessonPlan.classes.forEach((classItem) => {
                const numbers = [];
                for (let i = 0; i < classItem.class_qty; i++) {
                    numbers.push(startingNumber)
                    startingNumber++;
                }
                const series = numbers.join(' + ');
                const updatedSeries = numberSeries;
                updatedSeries.push(series)
                setNumberSeries(updatedSeries);
            })
            setStudentData(lessonPlan?.student_data[0])
            setSchoolData(lessonPlan?.school_data[0])
        }
    }, [lessonPlan])

    return (
        <Document>
            <Page size="A3" style={{
                padding: 20,
                fontSize: '10',
                color: '#3F3F46',
                fontFamily: 'Helvetica'
            }}>
                <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    border: 5,
                    borderColor: '#F4F4F5',
                    padding: 10
                }}>
                    <View style={{ width: '33%', lineHeight: 1.25, marginTop: 23 }}>
                        <Text style={{ fontSize: 14, fontFamily: 'Helvetica-Bold' }}>{studentData?.name}</Text>
                        <Text style={{ fontSize: 12, fontFamily: 'Helvetica-Bold' }}>{studentData?.email}</Text>
                        <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold' }}>{studentData?.phone}</Text>
                        <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold' }}>{studentData?.address}</Text>
                    </View>
                    <View style={{ width: '33%', maxHeight: 80 }}>
                        <Image src={drivePro}
                            style={{ objectFit: 'contain' }} />
                    </View>
                    <View style={{ width: '33%', lineHeight: 1.25, marginTop: 23, textAlign: 'right' }}>
                        <Text style={{ fontSize: 14, fontFamily: 'Helvetica-Bold' }}>{schoolData?.name}</Text>
                        <Text style={{ fontSize: 12, fontFamily: 'Helvetica-Bold' }}>{schoolData?.email}</Text>
                        <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold' }}>{schoolData?.phone}</Text>
                        <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold' }}>{schoolData?.address}</Text>
                    </View>
                </View>

                <View style={{ fontSize: 10, color: '#71717A', marginTop: 20 }}>
                    <Text style={{ marginBottom: 10 }}>
                        This lesson plan is your documentation of the lessons you have completed. The teaching driving
                        instructor's digital signature appears under the "Driving instructor" section of each module,
                        the dates of the lesson
                        it is confirmed (signature date) by you and the driving instructors, and these must be
                        consistent with each other. The lesson plan is divided according to the minimum requirements of
                        the law, which is a minimum: 29 theory lessons a 45
                        minutes duration, 4 lessons of 45 minutes duration on maneuvering track, 16 lessons of 45
                        minutes duration for practical driving on the road and 4 lessons of 45 minutes duration on
                        driving technical equipment. Everything
                        the above must extend over a minimum of 14 teaching days. It is the learner's individual
                        circumstances and ability that are decisive for how many lessons in addition to the stated
                        mandatory
                        minimum number of lessons the learner driver must have before the practical test can be taken.
                    </Text>
                    <Text>
                        The lesson plan must be signed after each lesson by you and your driving instructor, and
                        presented to the relevant authorities on request. It must also be used for the theoretical and
                        practical driving test
                        present if a random check is carried out. If the lesson plan is not sufficiently completed when
                        the theoretical or practical driving test is held, you may be rejected. If you are rejected
                        there must be
                        a new fee of DKK 1,000 must be paid to the Swedish Transport Agency before a new test can be
                        ordered, and the missing lessons or errors in the lesson plan must be completed/rectified.
                    </Text>
                </View>

                <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 20, gap: 5 }}>
                    {lessonPlan &&
                        lessonPlan.classes.map(item => (item?.instructor_name &&
                            <View style={{ width: '32.5%', textAlign: 'center', border: 1, borderColor: '#A1A1AA' }}>
                                <View style={{
                                    borderBottom: 1,
                                    borderBottomColor: '#A1A1AA',
                                    padding: 8,
                                    backgroundColor: '#FAFAFA'
                                }}>
                                    <Text>Teaching Instructor</Text>
                                </View>
                                <View style={{ padding: 10, lineHeight: 1.25 }}>
                                    <Text
                                        style={{ fontSize: 14 }}> {item?.instructor_name ? item?.instructor_name : 'No Name'} </Text>
                                    <Text>{item?._doc?.name ? item?._doc?.name : '-'}</Text>
                                    <Text>{item?.doc?.address}</Text>
                                    <View style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        borderRadius: 5,
                                        backgroundColor: '#F4F4F5',
                                        maxHeight: 30,
                                        marginTop: 5
                                    }}>
                                        <Image
                                            src={item.instructor_signature ? item.instructor_signature : 'placeholder-image-url'} />
                                    </View>
                                    <Text style={{ fontSize: 8, marginTop: 2.5 }}>
                                        Date: {item.instructor_signature_date ? moment(item.instructor_signature_date).format('DD-MM-YYYY') : '-'}
                                    </Text>
                                </View>
                            </View>
                        ))}
                </View>
            </Page>

            <Page size="A3" style={{
                padding: 20,
                fontSize: '10',
                color: '#3F3F46',
                fontFamily: 'Helvetica'
            }}>
                <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    border: 5,
                    borderColor: '#F4F4F5',
                    padding: 10
                }}>
                    <View style={{ width: '33%', lineHeight: 1.25, marginTop: 23 }}>
                        <Text style={{ fontSize: 14, fontFamily: 'Helvetica-Bold' }}>{studentData?.name}</Text>
                        <Text style={{ fontSize: 12, fontFamily: 'Helvetica-Bold' }}>{studentData?.email}</Text>
                        <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold' }}>{studentData?.phone}</Text>
                        <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold' }}>{studentData?.address}</Text>
                    </View>
                    <View style={{ width: '33%', maxHeight: 80 }}>
                        <Image src={drivePro}
                            style={{ objectFit: 'contain' }} />
                    </View>
                    <View style={{ width: '33%', lineHeight: 1.25, marginTop: 23, textAlign: 'right' }}>
                        <Text style={{ fontSize: 14, fontFamily: 'Helvetica-Bold' }}>{schoolData?.name}</Text>
                        <Text style={{ fontSize: 12, fontFamily: 'Helvetica-Bold' }}>{schoolData?.email}</Text>
                        <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold' }}>{schoolData?.phone}</Text>
                        <Text style={{ fontSize: 10, fontFamily: 'Helvetica-Bold' }}>{schoolData?.address}</Text>
                    </View>
                </View>

                <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 20,
                    border: 1,
                    borderColor: '#A1A1AA',
                    backgroundColor: '#FAFAFA'
                }}>
                    <View style={{
                        width: '15%',
                        fontSize: 10,
                        textAlign: 'left',
                        fontFamily: 'Helvetica-Bold',
                        padding: 8,
                        borderRight: 1,
                        borderColor: '#A1A1AA'
                    }}>
                        <Text>Lesson No.</Text>
                    </View>
                    <View style={{
                        width: '30%',
                        fontSize: 10,
                        textAlign: 'left',
                        fontFamily: 'Helvetica-Bold',
                        padding: 8,
                        borderRight: 1,
                        borderColor: '#A1A1AA'
                    }}>
                        <Text>Description</Text>
                    </View>
                    <View style={{
                        width: '15%',
                        fontSize: 10,
                        textAlign: 'center',
                        fontFamily: 'Helvetica-Bold',
                        padding: 8,
                        borderRight: 1,
                        borderColor: '#A1A1AA'
                    }}>
                        <Text>Module</Text>
                    </View>
                    <View style={{
                        width: '20%',
                        fontSize: 10,
                        textAlign: 'center',
                        fontFamily: 'Helvetica-Bold',
                        padding: 8,
                        borderRight: 1,
                        borderColor: '#A1A1AA'
                    }}>
                        <Text>Instructor</Text>
                    </View>
                    <View style={{
                        width: '15%',
                        fontSize: 10,
                        textAlign: 'center',
                        fontFamily: 'Helvetica-Bold',
                        padding: 8,
                        borderRight: 1,
                        borderColor: '#A1A1AA'
                    }}>
                        <Text>Student Sign.</Text>
                    </View>
                    <View style={{
                        width: '15%',
                        fontSize: 10,
                        textAlign: 'center',
                        fontFamily: 'Helvetica-Bold',
                        padding: 8
                    }}>
                        <Text>Instructor Sign.</Text>
                    </View>
                </View>


                {lessonPlan &&
                    lessonPlan.classes.map((item, index) => (
                        <View style={{
                            display: 'flex',
                            flexDirection: 'row',
                            borderBottom: 1,
                            borderLeft: 1,
                            borderRight: 1,
                            borderColor: '#A1A1AA'
                        }}>
                            <View style={{
                                width: '15%',
                                fontSize: 10,
                                textAlign: 'left',
                                padding: 5,
                                borderRight: 1,
                                borderColor: '#A1A1AA'
                            }}>
                                <Text>
                                    {numberSeries[index]} = {item.class_qty} lessons
                                    of {item.class_duration + ' min.'} ({item.class_duration * item.class_qty} min.)
                                </Text>
                            </View>
                            <View style={{
                                width: '30%',
                                fontSize: 10,
                                textAlign: 'left',
                                padding: 5,
                                borderRight: 1,
                                borderColor: '#A1A1AA'
                            }}>
                                <Text>{item.description ? item.description : '-'}</Text>
                            </View>
                            <View style={{
                                width: '15%',
                                fontSize: 10,
                                textAlign: 'center',
                                padding: 5,
                                borderRight: 1,
                                borderColor: '#A1A1AA'
                            }}>
                                <Text>{item.class_name}</Text>
                            </View>
                            <View style={{
                                width: '20%',
                                fontSize: 10,
                                textAlign: 'center',
                                padding: 5,
                                borderRight: 1,
                                borderColor: '#A1A1AA'
                            }}>
                                <Text>{item?._doc?.name ? item?._doc?.name : '-'}</Text>
                            </View>
                            <View style={{
                                width: '15%',
                                fontSize: 10,
                                textAlign: 'center',
                                padding: 5,
                                borderRight: 1,
                                borderColor: '#A1A1AA'
                            }}>
                                <View style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    borderRadius: 5,
                                    backgroundColor: '#F4F4F5',
                                    maxHeight: 30,
                                }}>
                                    <Image
                                        src={item.student_signature ? item.student_signature : 'placeholder-image-url'} />
                                </View>
                                <Text style={{ fontSize: 8, marginTop: 2.5 }}>
                                    Date: {item.student_signature_date && item.student_signature_date !== null ? moment(item.student_signature_date).format('DD-MM-YYYY') : '-'}
                                </Text>
                            </View>
                            <View style={{
                                width: '15%',
                                fontSize: 10,
                                textAlign: 'center',
                                padding: 5,
                            }}>
                                <View style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    borderRadius: 5,
                                    backgroundColor: '#F4F4F5',
                                    maxHeight: 30,
                                }}>
                                    <Image
                                        src={item.instructor_signature ? item.instructor_signature : 'placeholder-image-url'} />
                                </View>
                                <Text style={{ fontSize: 8, marginTop: 2.5 }}>
                                    Date: {item.instructor_signature_date && item.instructor_signature_date !== null ? moment(item.instructor_signature_date).format('DD-MM-YYYY') : '-'}
                                </Text>
                            </View>
                        </View>
                    ))}
            </Page>
        </Document>
    );
}