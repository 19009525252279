import React, { useEffect, useState } from 'react'
import { getStudentCreditHistory } from '../../../../services/schoolService';
import formatDateTime from '../../../../helpers/utils';

const CreditHistory = (props) => {

  return (
    <>
      <div className="border rounded-md overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr className="bg-gray-50">
              <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                #
              </td>
              <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                Amount
              </td>
              <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                Status
              </td>
              <td className="px-6 py-3 text-xs font-semibold text-gray-500">
                Date
              </td>
            </tr>
          </thead>
          <tbody id="table-body"
            className="divide-y divide-gray-200">
            {
              props.CreditHistoryData && props.CreditHistoryData.length === 0 ?
                <tr>
                  <td colSpan="4" className="px-6 py-3 text-center text-red-500">No history found.</td>
                </tr>
                :
                props.CreditHistoryData && props.CreditHistoryData.map((item, key) => (
                  <tr>
                    <td className="px-6 py-4 align-middle whitespace-nowrap text-gray-500 cursor-pointer hover:underline">
                      {key + 1}
                    </td>
                    <td className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                      Kr {parseFloat(item?.amount).toFixed(2)}
                    </td>
                    <td className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                      <span className={item?.convention === 'credit' ?
                        'bg-green-500 text-white text-sm px-4 py-1 rounded-full capitalize' :
                        'bg-red-500 text-white text-sm px-4 py-1 rounded-full capitalize'
                      }>
                        {item?.convention}
                      </span>
                    </td>
                    <td className="px-6 py-4 align-middle whitespace-nowrap text-gray-500">
                      {formatDateTime(item?.createdAt)}
                    </td>
                  </tr>
                )
                )}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default CreditHistory