//Libraries
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { PDFDownloadLink } from "@react-pdf/renderer";
import moment from "moment";

//Pages
import DesktopLessonDesign from "./lesson-desktop-view";
import MobileLessonDesign from "./lesson-mobile-view";
import { LessonPlanDocument } from "./lessonPlanPDF";

//Components
import SignaturePad from "../../../components/signature/signature";
import Modal from "../../../components/modal/Modal";

//Services
import { SignLesson, getAllClassTypes, getStudentLessonPlans } from "../../../services/studentService";

//Helpers
import { checkScreenSize } from "../../../helpers/screenSize";

//Constants
import PdfSvg from '../../../assets/Pdf.svg'

const LessonPlan = () => {
    const routeParams = useParams();

    const [lessonPlan, setLessonPlan] = useState({
        classes: []
    });
    const [isMobile, setIsMobile] = useState(false);
    const [classTypeList, setClassTypeList] = useState([]);
    const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);
    const [selectedClass, setSelectedClass] = useState({});
    const sigCanvasRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(checkScreenSize(768));
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchLesson = async () => {
            try {
                const lessonPlan = await getStudentLessonPlans();
                if (Object.keys(lessonPlan).length > 0)
                    setLessonPlan(lessonPlan);
            } catch (error) {
                console.log(error);
            }
        };
        fetchLesson();
    }, [])

    useEffect(() => {
        const fetchClassTypes = async () => {
            try {
                const classTypes = await getAllClassTypes();
                setClassTypeList(classTypes);
            } catch (error) {
                console.log(error);
            }
        };
        fetchClassTypes();
    }, [])

    const signaturePadComponent = () => {
        return <SignaturePad sigCanvasRef={sigCanvasRef} />
    }

    const saveSignature = async () => {
        if (!sigCanvasRef.current.isEmpty()) {
            const signatureImage = sigCanvasRef.current.toDataURL();
            const body = {
                student_lesson_class_id: selectedClass._id,
                student_signature: signatureImage,
            }
            if (await SignLesson(lessonPlan._id, body)) {
                const updated_classes = lessonPlan.classes.map((classItem) => {
                    if (classItem._id === selectedClass._id) return {
                        ...classItem,
                        student_signature: body.student_signature,
                    }
                    else return classItem;
                })
                setLessonPlan({ ...lessonPlan, classes: updated_classes });
            }
        }
        closeModal();
    };
    const closeModal = () => {
        setIsSignatureModalOpen(false);
        setSelectedClass({});
        sigCanvasRef.current.clear();
    }


    return (
        <>
            <div className="space-y-4">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                        <h3 className="text-gray-600 lg:text-3xl text-2xl font-semibold">Lesson Plan</h3>
                    </div>
                    {
                        Object.keys(lessonPlan).length > 1 ?
                            <PDFDownloadLink
                                document={
                                    <LessonPlanDocument lessonPlan={lessonPlan} />
                                }
                                fileName={`${lessonPlan ? lessonPlan.lesson_id : ''}.pdf`}
                            >
                                {({ blob, url, loading, error }) => (loading ? 'Loading document...' :
                                    <img className="w-8 h-8" src={PdfSvg} alt="pdf-svg" />)}
                            </PDFDownloadLink> :
                            ""
                    }
                </div>
                {!isMobile
                    ?
                    <DesktopLessonDesign
                        lessonPlan={lessonPlan}
                        classTypeList={classTypeList}
                        setIsSignatureModalOpen={setIsSignatureModalOpen}
                        setSelectedClass={setSelectedClass}
                    />
                    :
                    <MobileLessonDesign
                        lessonPlan={lessonPlan}
                        classTypeList={classTypeList}
                        setIsSignatureModalOpen={setIsSignatureModalOpen}
                        setSelectedClass={setSelectedClass}
                    />
                }
            </div>
            {
                isSignatureModalOpen &&
                <Modal
                    isOpen={isSignatureModalOpen}
                    closeModal={closeModal}
                    title={`Driving Test`}
                    component={signaturePadComponent}
                    showComponent={true}
                    onConfirmClick={saveSignature}
                />
            }
        </>
    )
}

export default LessonPlan;