import React from "react"

const MobileLessonDesign = ({ lessonPlan, classTypeList, setIsSignatureModalOpen, setSelectedClass }) => {
    const getClassTypeName = (classId) => {
        if(classTypeList.length > 0){
            const classType = classTypeList.find((classItem) => classItem._id === classId)
            return classType.name;
        }
        else return '';
    }

    return (
        lessonPlan && lessonPlan.classes.map((classType) => (
            <div className="border divide-y px-3 py-2 rounded-md space-y-1 shadow" key={classType._id}>
                <div className="flex items-center justify-between py-2">
                    <span className="text-xs font-semibold text-gray-600">Lesson Name: </span>
                    <span className="text-sm text-gray-600">{ classType.class_name.length > 20 ? classType.class_name.slice(0, 17) + '...' : classType.class_name }</span>
                </div>
                <div className="flex items-center justify-between py-2">
                    <span className="text-xs font-semibold text-gray-600">Class Type: </span>
                    <span className="text-sm text-gray-600">{classType.class_id ? getClassTypeName(classType.class_id) : ''}</span>
                </div>
                <div className="flex items-center justify-between py-2">
                    <span className="text-xs font-semibold text-gray-600">Lessons: </span>
                    <span className="text-sm text-gray-600">
                        { classType.class_qty + ' '}
                        lessons of
                        {' ' +  classType.class_duration } min.
                                ({ classType.class_qty * classType.class_duration } min.)
                    </span>
                </div>
                <div className="grid grid-cols-2 gap-4 py-2">
                    <div className="flex flex-col items-center gap-1">
                        <span className="text-xs font-semibold text-gray-600">Student Signature: </span>
                        <button
                            onClick={(event) => {
                                event.preventDefault();
                                setIsSignatureModalOpen(true);
                                setSelectedClass(classType);
                            }}
                            className={classType.student_signature ? 'btn btn-success btn-sm' : 'btn btn-primary btn-sm'}
                            disabled={classType.class_taken < classType.class_qty || classType.student_signature}
                        >
                            {classType.student_signature ? 'Signed' : 'Unsigned'}
                        </button>
                    </div>
                    <div className="flex flex-col items-center gap-1">
                        <span className="text-xs font-semibold text-gray-600">Instructor Signature: </span>
                        <button
                            onClick={(event) => {
                                event.preventDefault();
                                setIsSignatureModalOpen(true);
                                setSelectedClass(classType);
                            }}
                            className={classType.instructor_signature ? 'btn btn-success btn-sm' : 'btn btn-primary btn-sm'}
                            disabled={true}
                        >
                            {classType.instructor_signature ? 'Signed' : 'Unsigned'}
                        </button>
                    </div>
                </div>
            </div>
        ))
    )
}

export default MobileLessonDesign;