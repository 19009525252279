
export const AddNewClass = ({ formik, lessonPlanFormChange, classTypeList, classIndex }) => {
    return <>
        <div className="space-y-4">
            <div className="space-y-1">
                <label className="form-label">Lesson Name</label>
                <input
                    id="class name"
                    name="class_name"
                    type="text"
                    placeholder="Enter Lesson Name"
                    onChange={event => lessonPlanFormChange(classIndex, event.target.name, event.target.value)} 
                    value={formik.values.classes[classIndex].class_name}
                    className="form-input w-full"
                />
            </div>
            <div className="grid grid-cols-2 gap-4">
                <div className="space-y-1">
                    <label className="form-label">Class Type</label>
                    <select
                        id='class_id'
                        name='class_id'
                        className="form-select w-full"
                        onChange={event => lessonPlanFormChange(classIndex, event.target.name, event.target.value)}
                        value={formik.values.classes[classIndex].class_id}
                    >
                        <option disabled value="">
                            - Select Class Type -
                        </option>
                        {
                            classTypeList.map((classTypeItem) => <option key={classTypeItem._id} value={classTypeItem._id}>{classTypeItem.name}</option>)
                        }
                    </select>
                </div>
                <div className="space-y-1">
                    <label className="form-label">No. of Classes</label>
                    <input
                        id="class_qty"
                        name="class_qty"
                        type="number"
                        onChange={event => lessonPlanFormChange(classIndex, event.target.name, event.target.value)}
                        value={formik.values.classes[classIndex].class_qty}
                        className="form-input w-full"
                    />
                </div>
            </div>
            <div className="space-y-1">
                <label className="form-label">Description</label>
                <textarea
                    id="description"
                    name="description"
                    rows="5"
                    placeholder="Write few lines here about the plan..."
                    onChange={event => lessonPlanFormChange(classIndex, event.target.name, event.target.value)} 
                    value={formik.values.classes[classIndex].description}
                    className="form-textarea w-full"
                />
            </div>
        </div>
    </>
}