import React, { useEffect, useState } from 'react';
import { Document, Page, View, Text, Image } from '@react-pdf/renderer';
import drivePro from './../../../../assets/drivePro.jpeg';
import { TrimId, formatDateOnly } from '../../../../helpers/utils';
import { getClassDataOnIds } from '../../../../services/schoolService';

const InvoiceTemplate = (props) => {
    const [school_data] = useState(props?.studentTransaction?.school_data[0]);
    const [student_data] = useState(props?.studentTransaction?.student_data[0]);
    const [package_data] = useState(props?.studentTransaction?.package_data[0]);
    const [trasaction_data] = useState(props?.studentTransaction);
    const [classesData, setClassesData] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [discountPercentage, setDiscountPercentage] = useState(0);
    const [percentagePrice, setPercentagePrice] = useState(0.00);
    useEffect(() => {
        async function fetchclasses() {
            let payload;
            if (trasaction_data?.class_id) {
                payload = {
                    class_id: trasaction_data?.class_id
                }
                const classesResponse = await getClassDataOnIds(payload);
                setPercentagePrice((trasaction_data?.amount * 0.25)?.toFixed(2))
                setTotalPrice(trasaction_data?.amount?.toFixed(2))
                setClassesData([{ quantity: trasaction_data?.quantity, ...classesResponse[0] }]);
            } else {
                payload = {
                    package_id: trasaction_data?.package_id
                }
                const classesResponse = await getClassDataOnIds(payload);
                setDiscountPercentage(package_data?.discount_on_package)
                setPercentagePrice((trasaction_data?.amount * 0.25)?.toFixed(2))
                setTotalPrice(trasaction_data?.amount?.toFixed(2))
                setClassesData(classesResponse);
            }
        }

        fetchclasses();
    }, [])

    return (
        <Document>
            <Page size="A4" style={{
                paddingHorizontal: 30,
                paddingVertical: 20,
                fontSize: '10',
                color: '#3F3F46',
                fontFamily: 'Helvetica'
            }}>

                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View style={{ width: '50%' }}>
                        <View>
                            <View style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: "space-between",
                                borderBottom: 0.5,
                                borderBottomColor: '#D4D4D8',
                                padding: 5
                            }}>
                                <Text style={{ fontSize: 20, fontFamily: 'Helvetica-Bold' }}>Drive <Text
                                    style={{ color: '#3B82F6' }}>Pro</Text></Text>
                            </View>
                            <View style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: "space-between",
                                borderBottom: 0.5,
                                borderBottomColor: '#D4D4D8',
                                padding: 5
                            }}>
                                <Text>Invoice Number</Text>
                                <Text>#{TrimId(trasaction_data?._id)}</Text>
                            </View>

                            <View style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: "space-between",
                                borderBottom: 0.5,
                                borderBottomColor: '#D4D4D8',
                                padding: 5
                            }}>
                                <Text>Invoice Date</Text>
                                <Text>{formatDateOnly(trasaction_data?.createdAt)}</Text>
                            </View>

                            <View style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: "space-between",
                                borderBottom: 0.5,
                                borderBottomColor: '#D4D4D8',
                                padding: 5
                            }}>
                                <Text>Address</Text>
                                <Text>{school_data?.address}</Text>
                            </View>

                            <View style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: "space-between",
                                borderBottom: 0.5,
                                borderBottomColor: '#D4D4D8',
                                padding: 5
                            }}>
                                <Text>Email</Text>
                                <Text>{student_data?.email}</Text>
                            </View>

                        </View>
                    </View>
                    <View style={{ width: '50%' }}>
                        <View style={{ maxHeight: 180 }}>
                            <Image src={drivePro}
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </View>
                    </View>
                </View>

                <View style={{
                    backgroundColor: '#3B82F6',
                    color: 'white',
                    display: 'flex',
                    paddingHorizontal: 6,
                    paddingVertical: 8,
                    flexDirection: 'row',
                    marginTop: -30
                }}>
                    <Text style={{ width: '40%', fontFamily: 'Helvetica-Bold' }}>Lessons</Text>
                    <Text style={{ width: '20%', textAlign: 'center', fontFamily: 'Helvetica-Bold' }}>Quantity</Text>
                    <Text style={{ width: '20%', textAlign: 'center', fontFamily: 'Helvetica-Bold' }}>Price</Text>
                    <Text style={{ width: '20%', textAlign: 'center', fontFamily: 'Helvetica-Bold' }}>Total</Text>
                </View>
                {classesData && classesData?.map((item, key) => (
                    <View key={key} style={{
                        display: 'flex',
                        flexDirection: 'row',
                        borderBottom: 0.5,
                        borderBottomColor: '#D4D4D8',
                        paddingHorizontal: 6,
                        paddingVertical: 8,
                    }}>
                        <Text style={{ width: '40%' }}>{item?.name}</Text>
                        <Text style={{ width: '20%', textAlign: 'center' }}>{item?.quantity}</Text>
                        <Text style={{ width: '20%', textAlign: 'center' }}>DKK {parseFloat(item?.price).toFixed(2)}</Text>
                        <Text
                            style={{
                                width: '20%',
                                textAlign: 'center'
                            }}>DKK {parseFloat((item?.quantity ? item?.quantity : 0) * item?.price).toFixed(2)}</Text>
                    </View>
                ))}

                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 30 }}>
                    <View style={{ width: '50%' }}>
                        <View style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: "space-between",
                            borderBottom: 0.5,
                            borderBottomColor: '#D4D4D8',
                            paddingHorizontal: 6,
                            paddingVertical: 8,
                        }}>
                            <Text>Amount excl. VAT</Text>
                            <Text>DKK {parseFloat(totalPrice - percentagePrice).toFixed(2)}</Text>
                        </View>

                        <View style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: "space-between",
                            borderBottom: 0.5,
                            borderBottomColor: '#D4D4D8',
                            paddingHorizontal: 6,
                            paddingVertical: 8,
                        }}>
                            <Text>25% moms</Text>
                            <Text>DKK {parseFloat(percentagePrice).toFixed(2)}</Text>
                        </View>

                        <View style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: "space-between",
                            backgroundColor: '#3B82F6',
                            color: 'white',
                            paddingHorizontal: 6,
                            paddingVertical: 8,
                            fontFamily: 'Helvetica-Bold'
                        }}>
                            <Text>Total</Text>
                            <Text>DKK {parseFloat(totalPrice).toFixed(2)}</Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default InvoiceTemplate;
