import React, { useState, useEffect } from "react";
import drivePro from '../../../assets/drivePro1.jpeg';
import { theoryMotorCylcleList, theoryCarList } from '../../../helpers/theoryTeachingList';
import { Link } from "react-router-dom";

const Theory = (props) => {
    const [userType, setUserType] = useState('student');
    useEffect(() => {
        // Check if the user is already logged in
        const token = localStorage.getItem('accessToken');
        const userType = localStorage.getItem('user_type')
        if (token) {
            setUserType(userType)
        }
    }, []);
    return (
        <>
            <div className="space-y-4">
                {/* <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                        <h3 className="text-gray-600 lg:text-2xl text-xl font-semibold">Theory Teaching (Car)</h3>
                    </div>
                </div> */}
                {/* <div className="p-5 border bg-white shadow-sm font-sans flex items-center justify-between">
                    <div>
                        <p className="text-gray-500 font-semibold">
                            The material in theory lessons 1 to 8 has been prepared in collaboration with Greater Copenhagen Driving School / Drive Pro. Click on the logo to see more.
                        </p>
                        <p className="mt-1 text-xs text-gray-400 font-semibold">Greater Copenhagen's Driving Instructor School is approved by Swedish Transport Agency as a continuing education centre.</p>
                    </div>
                    <img src={drivePro} alt="" className="w-28 h-full"/>
                </div> */}
                {props.type === 'car' ?
                    <div className="border divide-y bg-white shadow-sm font-sans">
                        {
                            theoryCarList && theoryCarList.map((item, key) => (
                                <div className="flex items-center justify-between p-3" key={key}>
                                    <p className="text-gray-500 font-semibold">{item.name}</p>
                                    <Link to={`/${userType}/theory-teaching/${item.id}/car`}><button className="btn btn-success btn-sm" >Start</button></Link>
                                </div>
                            ))
                        }
                    </div>
                    :
                    props.type === 'bike' ?
                        <div className="border divide-y bg-white shadow-sm font-sans">
                            {
                                theoryMotorCylcleList && theoryMotorCylcleList.map((item, key) => (
                                    <div className="flex items-center justify-between p-3" key={key}>
                                        <p className="text-gray-500 font-semibold">{item.name}</p>
                                        <Link to={`/${userType}/theory-teaching/${item.id}/bike`}><button className="btn btn-success btn-sm" >Start</button></Link>
                                    </div>
                                ))
                            }
                        </div>
                        :
                        ""
                }
            </div>
        </>
    )
}

export default Theory;