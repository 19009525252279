import React, { useEffect, useState } from 'react'

const GeneralInfo = (props) => {
  const handleHrRate = (rate) => {
    props.setHr_rate(rate);
  };

  return (
    <>
      {
        props?.instructorData?.length > 0 ?
          <div className="">
            <div className="divide-y xl:w-1/2 border-b">
              <div className="grid grid-cols-2 py-4">
                <label className="form-label" htmlFor="">Name:</label>
                <p className="text-gray-500">{props?.instructorData[0]?.name}</p>
              </div>
              <div className="grid grid-cols-2 py-4">
                <label className="form-label" htmlFor="">Email:</label>
                <p className="text-gray-500">{props.instructorData[0]?.email}</p>
              </div>
              <div className="grid grid-cols-2 py-4">
                <label className="form-label" htmlFor="">Phone:</label>
                <p className="text-gray-500">{props.instructorData[0]?.phone}</p>
              </div>
              <div className="grid grid-cols-2 py-4">
                <label className="form-label" htmlFor="">Address:</label>
                <p className="text-gray-500">{props.instructorData[0]?.address}</p>
              </div>
              <div className="grid grid-cols-2 py-4">
                <label className="form-label" htmlFor="">Joined On:</label>
                <p className="text-gray-500">{props.instructorData[0]?.createdAt}</p>
              </div>
              <div className="grid grid-cols-2 py-4">
                <label className="form-label" htmlFor="">Status:</label>
                {
                  props.instructorData[0]?.is_active ?
                    <span className="text-xs px-4 py-0.5 bg-green-500 text-white w-fit rounded-full">Active</span> :
                    <span className="text-xs px-4 py-0.5 bg-red-500 text-white w-fit rounded-full">Inactive</span>
                }
              </div>
              <div className="grid grid-cols-2 py-4">
                <label className="form-label" htmlFor="">Hrs Rate</label>
                <div className='flex gap-2'>
                  <input
                    id="hr_rate"
                    name="hr_rate"
                    type="hr_rate"
                    placeholder="Enter instructor hourly rate..."
                    onChange={event => handleHrRate(event.target.value)}
                    value={props.hr_rate}
                    required
                    className="form-input w-full"
                  />

                  <button onClick={props.updateHr_rate} type="button"
                    className="h-10 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-green-500 text-white hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all text-sm">
                    {'Update'}
                  </button>
                </div>


              </div>
            </div>
          </div> : ""
      }
    </>
  )
}

export default GeneralInfo