import React, { useEffect, useState } from "react";
import { getDashboardDataService } from "../../../services/schoolService";
import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import { calaulateDayAndDate, calculateAgeInTime } from "../../../helpers/utils";

const SchoolDashboard = () => {
    const navigate = useNavigate();
    const [dashboardData, setDashboardData] = useState([]);

    const [chartOptions, setChartOptions] = useState({
        options: {
            chart: {
                height: '100%',
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            xaxis: {
                categories: [],
                type: 'category',
            },

        }
    });
    const [chartSeries, setChartSeries] = useState([]);

    const [paymentChartOptions, setPaymentChartOptions] = useState({
        options: {
            chart: {
                height: '100%',
                type: 'bar',
                zoom: {
                    enabled: false
                }
            },
            xaxis: {
                categories: [],
                type: 'category',
            },

        }
    });
    const [paymentChartSeries, setPaymentChartSeries] = useState([]);
    useEffect(() => {
        if (dashboardData?.studentGraph) {
            let month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            let x = [];
            for (let i = 1; i <= month?.length; i++) {
                let total = 0
                for (let k = 0; k < dashboardData?.studentGraph.length; k++) {
                    if (dashboardData?.studentGraph[k]._id?.month === i) {
                        total = parseFloat(dashboardData?.studentGraph[k]?.total).toFixed(2);
                        break;
                    }
                }
                x.push(total)

            }
            setChartOptions({
                options: {
                    chart: {
                        height: '100%',
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                    xaxis: {
                        categories: month,
                        type: 'category',
                    },

                }
            });
            setChartSeries(
                [{
                    name: "Student Count",
                    data: x,
                }]
            )
        }
    }, [dashboardData])

    useEffect(() => {
        if (dashboardData?.transactionPerMonth) {
            let month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            let x = [];
            for (let i = 1; i <= month?.length; i++) {
                let total = 0
                for (let k = 0; k < dashboardData?.transactionPerMonth.length; k++) {
                    if (dashboardData?.transactionPerMonth[k]._id?.month === i) {
                        total = parseFloat(dashboardData?.transactionPerMonth[k]?.total).toFixed(2);
                        break;
                    }
                }
                x.push(total)
            }
            setPaymentChartOptions({
                options: {
                    chart: {
                        height: '100%',
                        type: 'bar',
                        zoom: {
                            enabled: false
                        }
                    },
                    xaxis: {
                        categories: month,
                        type: 'category',
                    },

                }
            });
            setPaymentChartSeries(
                [{
                    name: "Transaction Amount",
                    data: x,
                }]
            )
        }

    }, [dashboardData])
    useEffect(() => {
        const fetchDashboardData = async () => {
            try {
                const dashboardDataResponse = await getDashboardDataService();
                setDashboardData(dashboardDataResponse);
            } catch (error) {
                console.log(error);
            }
        }
        fetchDashboardData();
    }, [])
    return (
        <div>
            <div className="lg:hidden">
                <h3 className="text-gray-600 text-2xl font-semibold">Welcome</h3>
                <h2 className="text-gray-600 text-3xl font-semibold">{localStorage.getItem('name')}</h2>
            </div>
            <div className="md:space-y-6 space-y-4 md:mb-0 mb-14">
                <h3 className="text-gray-600 lg:text-3xl text-2xl font-semibold lg:block hidden">Welcome, {localStorage.getItem('name')}!</h3>
                <div className=" md:hidden">
                    <div className="border-b-2 border-b-gray-300"></div>
                </div>
                <div className="grid xl:grid-cols-4 md:grid-cols-2 grid-cols-1 md:gap-6 gap-4">
                    <div className="w-full border rounded-lg p-5 flex items-center gap-4 text-base">
                        <div className="flex items-center justify-center w-14 h-14 bg-blue-100 rounded-md">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                stroke="currentColor" className="w-8 h-8 stroke-blue-600">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                    d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                            </svg>
                        </div>
                        <div>
                            <h1 className="font-semibold text-3xl text-gray-600">{dashboardData?.studentCount}</h1>
                            <h5 className="font-semibold text-gray-600">Active Students</h5>
                        </div>
                    </div>
                    <div className="w-full border rounded-lg p-5 flex items-center gap-4 text-base">
                        <div className="flex items-center justify-center w-14 h-14 bg-green-100 rounded-md">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                stroke="currentColor" className="w-8 h-8 stroke-green-600">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                            </svg>
                        </div>
                        <div>
                            <h1 className="font-semibold text-3xl text-gray-600">{dashboardData?.totalAppointmentMonth}</h1>
                            <h5 className="font-semibold text-gray-600">Booked Appointments</h5>
                        </div>
                    </div>
                    <div className="w-full border rounded-lg p-5 flex items-center gap-4 text-base">
                        <div className="flex items-center justify-center w-14 h-14 bg-cyan-100 rounded-md">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                stroke="currentColor" className="w-8 h-8 stroke-cyan-600">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                    d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </div>
                        <div>
                            <h1 className="font-semibold text-3xl text-gray-600">Kr {parseFloat(dashboardData?.totalIncome).toFixed(2)}</h1>
                            <h5 className="font-semibold text-gray-600">Total Income</h5>
                        </div>
                    </div>
                    <div className="w-full border rounded-lg p-5 flex items-center gap-4 text-base">
                        <div className="flex items-center justify-center w-14 h-14 bg-orange-100 rounded-md">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                stroke="currentColor" className="w-8 h-8 stroke-orange-600">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                    d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                            </svg>
                        </div>
                        <div>
                            <h1 className="font-semibold text-3xl text-gray-600">{dashboardData?.instructorCount}</h1>
                            <h5 className="font-semibold text-gray-600">Active Instructors</h5>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-3 border rounded-lg">
                    <div className="xl:col-span-1 col-span-3 flex flex-col justify-center xl:items-center">
                        <div className="flex xl:flex-col flex-row xl:items-start items-end gap-2 py-2 px-2">
                            <h1 className="font-semibold text-5xl text-gray-600">{dashboardData?.studentCount}</h1>
                            <h4 className="font-semibold text-xl text-gray-600">New Student <span
                                className="text-blue-500">(Last 30 Days)</span></h4>
                        </div>
                    </div>
                    <div className="xl:col-span-2 col-span-3 w-full h-80">
                        <ReactApexChart options={chartOptions?.options} series={chartSeries} type="line" width={'100%'}
                            height={'100%'} />
                    </div>
                </div>
                <div className="border rounded-lg">
                    <div className="border-b p-4 bg-gray-50 rounded-t-md">
                        <h4 className="font-semibold text-xl text-gray-600">Payments <span className="text-blue-500">(Last 30 Days)</span>
                        </h4>
                    </div>
                    <div className="w-full h-80">
                        <ReactApexChart options={paymentChartOptions?.options} series={paymentChartSeries} type="bar"
                            width={'100%'} height={'100%'} />
                    </div>
                </div>
            </div>
        </div>
    )

}


export default SchoolDashboard;