//Libraries
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useFormik } from "formik";

//Pages
import DesktopLessonDesign from "./lesson-desktop-view";
import MobileLessonDesign from "./lesson-mobile-view";
import { AddNewClass } from "./add-new-class";

//Components
import Modal from "../../../components/modal/Modal";

//Services
import { createLessonPlan, getClassTypeList, getLessonPlanById, updateLessonPlan } from "../../../services/schoolService";

//Helpers
import { checkScreenSize } from "../../../helpers/screenSize";

//Constants
import { BackArrowSvg } from "../../../constants";
import notify from "../../../helpers/raiseToast";

const LessonPlan = () => {
    const routeParams = useParams();
    const navigate = useNavigate();

    const [classTypeList, setClassTypeList] = useState([]);
    const [isMobile, setIsMobile] = useState(false);
    const [isAddClassModalOpen, setIsAddClassModalOpen] = useState(false);
    const [classIndex, setClassIndex] = useState(0);
    const [isEditClass, setIsEditClass] = useState(false);
    const listingContainerRef = useRef(null);

    const formik = useFormik({
        initialValues: {
            name: '',
            classes: []
        },
    });

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(checkScreenSize(768));
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (routeParams.lessonPlanId) {
            const fetchLessonPlanById = async () => {
                try {
                    const lessonPlanDetails = await getLessonPlanById(routeParams.lessonPlanId);
                    formik.setValues(lessonPlanDetails);
                } catch (error) {
                    console.log(error);
                }
            };
            fetchLessonPlanById();
        }
    }, [routeParams])

    useEffect(() => {
        const fetchClassTypes = async () => {
            try {
                const classTypes = await getClassTypeList();
                setClassTypeList(classTypes);
            } catch (error) {
                console.log(error);
            }
        };
        fetchClassTypes();
    }, [routeParams])

    const scrollToBottom = () => {
        if (listingContainerRef.current) {
            listingContainerRef.current.scrollTo({
                top: listingContainerRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }
    };

    const removeClass = (indexToRemove) => {
        if (formik.values.classes.length > 0) {
            const updatedClasses = formik.values.classes.filter(
                (_, index) => index !== indexToRemove
            );

            formik.setValues({
                ...formik.values,
                classes: updatedClasses,
            });
        }
    }

    const lessonPlanFormChange = (index, fieldName, inputValue) => {
        const updatedClasses = [...formik.values.classes];
        updatedClasses[index][fieldName] = inputValue;

        formik.setValues({
            ...formik.values,
            classes: updatedClasses,
        });
    }

    const isLessonPlanFormValid = () => {
        let isValid = true;

        if (formik.values.classes.length === 0 ||
            formik.values.classes.find((classType) => classType.class_name === '' || classType.class_id === '' || classType.class_qty === '' || classType.class_qty < 1)) {
            isValid = false;
            notify('ERROR', 'top-right', 'Correct input fields')
        }
        return isValid;
    }

    const saveLessonPlan = async (e) => {
        e.preventDefault();
        if (isLessonPlanFormValid() && formik.values.name !== '') {
            const body = {
                ...formik.values
            }
            if (!routeParams.lessonPlanId) {
                await createLessonPlan(body);
                navigateBackToLessonPlans();
            } else {
                await updateLessonPlan(routeParams.lessonPlanId, body);
                navigateBackToLessonPlans();
            }
        }
        else {
            notify('ERROR', 'top-right', 'Enter lesson name')
        }
    }

    const navigateBackToLessonPlans = () => {
        navigate('/school/lesson-plans');
    }

    const openAddClassModal = async () => {
        const newClasses = [...formik.values.classes, {
            class_name: '',
            class_id: '',
            class_qty: 1,
            description: ''
        }];

        setClassIndex(newClasses.length - 1);

        await formik.setValues({
            ...formik.values,
            classes: newClasses,
        });

        setIsAddClassModalOpen(true);
    }

    const addClass = () => {
        setClassIndex(formik.values.classes.length - 1);
        if (isLessonPlanFormValid()) setIsAddClassModalOpen(false);
    }

    const closeAddClassModal = () => {
        if (!isEditClass) removeClass(formik.values.classes.length - 1);
        setIsAddClassModalOpen(false);
        scrollToBottom();
        setClassIndex(0);
        setIsEditClass(false);
    }

    const openEditClassModal = (classIndex) => {
        setClassIndex(classIndex)
        setIsEditClass(true);
        setIsAddClassModalOpen(true);
    }

    const AddNewClassComponent = () => {
        return <AddNewClass
            formik={formik}
            classTypeList={classTypeList}
            lessonPlanFormChange={lessonPlanFormChange}
            classIndex={classIndex}
        />
    }
    return (
        <>
            <div className="space-y-4">
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                        <div
                            onClick={navigateBackToLessonPlans}
                            className="h-10 w-10 border rounded-md flex items-center justify-center hover:bg-gray-50 duration-300 cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                stroke="currentColor" className="w-5 h-5 stroke-gray-500">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                    d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                            </svg>
                        </div>
                        <h3 className="text-gray-600 lg:text-3xl text-2xl font-semibold">{routeParams.lessonPlanId ? 'Edit' : 'Create New'} Lesson</h3>
                    </div>
                </div>
                {!isMobile
                    ?
                    <DesktopLessonDesign
                        formik={formik}
                        classTypeList={classTypeList}
                        addClass={addClass}
                        removeClass={removeClass}
                        openAddClassModal={openAddClassModal}
                        openEditClassModal={openEditClassModal}
                        listingContainerRef={listingContainerRef}
                        saveLessonPlan={saveLessonPlan}
                    />
                    :
                    <MobileLessonDesign
                        formik={formik}
                        classTypeList={classTypeList}
                        addClass={addClass}
                        removeClass={removeClass}
                        openAddClassModal={openAddClassModal}
                        openEditClassModal={openEditClassModal}
                        saveLessonPlan={saveLessonPlan}
                    />
                }
            </div>
            {isAddClassModalOpen &&
                <Modal
                    isOpen={isAddClassModalOpen}
                    closeModal={closeAddClassModal}
                    title='Add Lesson'
                    component={AddNewClassComponent}
                    showComponent={true}
                    onConfirmClick={addClass}
                    confirmButtonText='Add'
                />
            }
        </>
    )
}

export default LessonPlan;