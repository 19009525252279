//Libraries
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import moment from "moment";

//Components
import Calendar from "../../../components/calendar/calendar";

//Helpers
import notify from "../../../helpers/raiseToast";

//Pages
import AppointmentModal from "./appointmentModal";

//Services
import { createAppointment, deleteAppointmentById, editAppointmentById, getClassTypesBySchoolId, getStudentsWithLessonPlan, viewAllAppointments, viewMySchools } from "../../../services/instructorService";

const Appointment = () => {
  const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [schools, setSchools] = useState([]);
  const [classTypes, setClassTypes] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [events, setEvents] = useState([]);
  const [studentsToAdd, setStudentsToAdd] = useState([]);
  const [studentsToRemove, setStudentsToRemove] = useState([]);

  const formik = useFormik({
    initialValues: {
      name: '',
      school_id: '',
      start_time: '',
      end_time: '',
      date: [],
      address: '',
      has_student: false,
      total_seats: 0,
      class_id: '',
      class_qty: 0,
      student: [],
      cancel_description: '',
      hexCode: '',
    },
    onSubmit: async values => {
      setIsAppointmentModalOpen(false);
      if (isEditMode) {
        if (formik.values.created_by !== 'INSTRUCTOR') {
          notify('ERROR', 'top-right', 'Access Denied: You can only edit appointments you created!')
        }
        else if (moment(selectedDate).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD'))
          notify('ERROR', 'top-right', 'Cannot Edit: Past Appointments are not editable!')
        else {
          const body = {
            name: values.name,
            address: values.address,
            start_time: values.start_time,
            end_time: values.end_time,
            date: values.date,
            students_to_add: studentsToAdd,
            students_to_remove: studentsToRemove,
            hexCode: values.hexCode,
          }
          const response = await editAppointmentById(body, selectedAppointmentId);
          if (response && response.data && response.data.updated_appointment) {
            const allAppointments = await viewAllAppointments();
            setAppointments(allAppointments);

          }
        }
      }

    },
  });

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const mySchools = await viewMySchools();
        setSchools(mySchools);
      } catch (error) {
        console.log(error);
      }
    };
    fetchSchools();
  }, []);

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const allAppointments = await viewAllAppointments();
        setAppointments(allAppointments);
      } catch (error) { }
    }
    fetchAppointments();
  }, [])

  useEffect(() => {
    let id = 0;
    setEvents(appointments.flatMap((appointment) => {
      id++;
      return {
        id: id,
        title: appointment.name,
        instructor_name: appointment.instructor_name,
        heading: appointment.heading,
        hexCode: appointment.hexCode,
        start: moment(`${appointment.date} ${appointment.start_time}`, 'YYYY-MM-DD HH:mm A').format('YYYY-MM-DD HH:mm'),
        end: moment(`${appointment.date} ${appointment.end_time}`, 'YYYY-MM-DD HH:mm A').format('YYYY-MM-DD HH:mm'),
        appointment_id: appointment._id,
        fullyBooked: appointment.seats_remaining === 0,
        backgroundColor: "#ffffff"
      }
    }));
  }, [appointments])

  useEffect(() => {
    if (appointments && selectedAppointmentId.length > 0) {
      const findAppointment = async () => {
        const appointment = appointments.find((appointmentItem) => appointmentItem._id === selectedAppointmentId)
        formik.setValues(appointment);
      }
      findAppointment();
    }
  }, [selectedAppointmentId])

  useEffect(() => {
    const fetchClassesAndStudents = async () => {
      try {
        if (formik.values && formik.values.school_id.length > 0) {
          const students = await getStudentsWithLessonPlan(formik.values.school_id);
          setStudents(students);
          const classTypes = await getClassTypesBySchoolId(formik.values.school_id);
          setClassTypes(classTypes);
        }
      } catch (error) { }
    }
    fetchClassesAndStudents();
  }, [formik.values.school_id])

  useEffect(() => {
    if (formik.values && formik.values.class_id) {
      const filteredStudents = students.filter((student) => student.class_id && student.class_id.includes(formik.values.class_id));
      setFilteredStudents(filteredStudents);
    }
    else setFilteredStudents([]);
  }, [formik.values.class_id, students])

  const deleteAppointment = async (appointmentId) => {
    const payload = {
      cancel_description: formik.values.cancel_description
    }
    try {
      if (await deleteAppointmentById(appointmentId, payload)) {
        const updatedAppointments = appointments.filter((appointment) => appointment._id !== selectedAppointmentId);
        setAppointments(updatedAppointments);
      }
    }
    catch (error) { }
  }

  const closeModal = () => {
    setIsAppointmentModalOpen(false);
    setStudentsToAdd([]);
    setStudentsToRemove([]);
    setSelectedAppointmentId('');
    formik.resetForm();
  }

  // const isValidForm = (values) =>
  // (values.name.length > 0
  //   && values.start_time.length > 0
  //   && values.end_time.length > 0
  //   && values.date.length > 0
  //   && values.address.length > 0
  //   && values.class_id.length > 0
  //   && values.school_id.length > 0
  //   && values.total_seats > 0
  //   && values.class_qty > 0
  //   && moment(values.start_time, 'HH:mm').diff(moment(values.end_time, 'HH:mm')) < 0
  // )

  return (
    <>
      <Calendar
        setIsAppointmentModalOpen={setIsAppointmentModalOpen}
        setSelectedAppointmentId={setSelectedAppointmentId}
        setIsEditMode={setIsEditMode}
        setSelectedDate={setSelectedDate}
        events={events}
      />
      {isAppointmentModalOpen &&
        <AppointmentModal
          isAppointmentModalOpen={isAppointmentModalOpen}
          setIsAppointmentModalOpen={setIsAppointmentModalOpen}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          formik={formik}
          schools={schools}
          students={filteredStudents}
          classTypes={classTypes}
          selectedAppointmentId={selectedAppointmentId}
          setSelectedAppointmentId={setSelectedAppointmentId}
          deleteAppointment={deleteAppointment}
          selectedDate={selectedDate}
          studentsToAdd={studentsToAdd}
          studentsToRemove={studentsToRemove}
          setStudentsToAdd={setStudentsToAdd}
          setStudentsToRemove={setStudentsToRemove}
          closeModal={closeModal}
          createAppointment={createAppointment}
          editAppointmentById={editAppointmentById}
          setAppointments={setAppointments}
          viewAllAppointments={viewAllAppointments}
        />
      }
    </>
  );
};

export default Appointment;