import React from 'react';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
    const navigate = useNavigate();

    const handleLoginClick = () => {
        navigate('/login')
    };

    return(
        <div>
            <h1>Welcome to Drive Pro!</h1>
            <button onClick={handleLoginClick}>Login</button>
        </div>
    )
}

export default LandingPage;
