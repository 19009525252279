//Libraries
import React, { useEffect, useState } from "react";
import { useFormik } from 'formik';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

//services
import { studentLogin } from "../../../services/studentService";
import { instructorLogin } from "../../../services/instructorService";
import { schoolLogin } from "../../../services/schoolService";

//styles
import './login.css'

import drivePro from '../../../assets/drivePro1.jpeg';
import { useTranslation } from 'react-i18next';

export default function Login() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [selectedUserType, setSelectedUserType] = useState("student");

    useEffect(() => {
        const option = localStorage.getItem("selectedUserType");
        if (option) {
            setSelectedUserType(option);
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            email: '', password: ''
        }, onSubmit: async values => {
            let loginSuccessful = false;
            switch (selectedUserType) {
                case 'student':
                    loginSuccessful = await studentLogin(values);
                    break;
                case 'instructor':
                    loginSuccessful = await instructorLogin(values);
                    break;
                case 'school':
                    loginSuccessful = await schoolLogin(values);
                    break;
                default:
                    break;
            }

            if (loginSuccessful) {
                navigate(`/${selectedUserType}/home`);
            }
        },
    });

    const handleUserTypeChange = (event) => {
        setSelectedUserType(event.target.value);
        localStorage.setItem("selectedUserType", event.target.value);
    };

    const navigateToSignUp = () => {
        navigate('/register')
    }

    const navigateToForgotPassword = () => {
        navigate('/recover-account')
    }

    return (<>
        <div className="grid lg:grid-cols-2 min-h-screen bg-white">

            {/* Left Container */}
            <div className="lg:flex hidden flex-col items-center justify-center">
                <div className="">
                    <div className="">
                        <img src={drivePro} alt="" className="xl:h-28 h-20 xl:w-48 w-36 object-contain" />
                        <h2 className="xl:text-6xl text-5xl text-blue-500">Drive <span
                            className="text-gray-500">Book</span></h2>
                    </div>
                    <p className="xl:text-2xl text-xl text-gray-500">You're only a few seconds away from
                        learning!</p>
                </div>
            </div>

            {/* Right Container */}
            <div className="flex flex-col items-center justify-center shadow-2xl bg-blue-500 py-6 md:px-8 px-4">
                <div className="bg-white shadow-2xl md:min-w-[400px] min-w-full py-6 md:px-8 px-6 rounded-2xl">
                    <h3 className="text-gray-500 text-3xl tracking-wide text-center">{t("login")}</h3>
                    <form onSubmit={formik.handleSubmit} className="space-y-6 mt-6">
                        <div className="space-y-5">
                            <div className="space-y-1">
                                <label className="form-label">
                                    {t("user_type")}
                                </label>
                                <select className="form-select w-full" value={selectedUserType}
                                    onChange={handleUserTypeChange}>
                                    <option value="student">{t('student')}</option>
                                    <option value="instructor">{t('instructor')}</option>
                                    <option value="school">{t('school')}</option>
                                </select>
                            </div>
                            <div className="space-y-1">
                                <label className="form-label">
                                    {t('email_address')}
                                </label>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    className="form-input w-full"
                                />
                            </div>
                            <div className="space-y-1">
                                <label className="form-label">
                                    {t('password')}
                                </label>
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    onChange={formik.handleChange}
                                    value={formik.values.password}
                                    className="form-input w-full"
                                />
                            </div>
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-2">
                                <input
                                    id="customCheckLogin"
                                    type="checkbox"
                                    className=""
                                />
                                <label htmlFor="customCheckLogin" className="form-label">
                                    {t('remember_me')}
                                </label>
                            </div>
                            <span className="text-blue-500 text-sm font-semibold hover:underline cursor-pointer"
                                onClick={navigateToForgotPassword}> {t('forgot_your_password')}</span>
                        </div>
                        <button
                            className="btn btn-primary w-full"
                            type="submit"
                        >
                            {t('sign_in')}
                        </button>
                        <div className="text-sm text-gray-500">
                            {t('dont_have_an_account_sign_up')}<span onClick={navigateToSignUp}
                                className="text-blue-500 font-semibold hover:underline cursor-pointer"> {t('sign_up')}</span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <ToastContainer />
    </>);
}
